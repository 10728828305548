import React from 'react'
import Header from '../02-Header/Header'
import Contactus from '../03-Contact-Us/Contactus'
import RateChart from '../04-RateChart/RateChart'
import Pointbar from '../05-Pointbar/Pointbar'
import MarketResult from '../06-Market-result/MarketResult'
import StarlineResult from '../07-Starline-result/StarlineResult'

const Home = () => {
  return (
    <div>
        <Header/>
        <Contactus/>
        <RateChart/>
        <Pointbar/>
        <MarketResult/>
        <StarlineResult/>
      
    </div>
  )
}

export default Home
