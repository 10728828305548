import React, { useEffect, useState } from 'react'
import './MarketPanelChart.css'
import { useLocation, useParams } from 'react-router-dom'
import axios from 'axios'
import { format } from 'date-fns'

const MarketPanelChart = () => {

    const location = useLocation()
    const { id } = useParams()
    const [panachartApiData, setPanachartApiData] = useState({});

    const getChart = async () => {
        await axios.get(`${process.env.REACT_APP_BASE_URL}/web/chart/${id}`)
            .then((response) => {
                setPanachartApiData(response?.data?.data)
            })
            .catch((err) => console.log(err.message))
    }
    useEffect(() => {
        getChart()
    }, [])

    let number = 123;

    const numberToDigit = (number) => {

        let digit = [];

        while (number > 0) {
            digit.push(number % 10);
            number = Math.floor(number / 10)
        }

        return digit.reverse();


    }

    const DateToLocalDateString = (date) => format(new Date(date), 'dd-MM-yyyy');

    function sumGetLastDigit(value) {

        if (value == null) {
            return null;
        } else {
            return (
                value
                    .toString()
                    .split('')
                    .map(Number)
                    .reduce(function (a, b) {
                        return a + b;
                    }, 0) % 10
            );
        }
    }

    const columns = React.useMemo(
        () => [
            {
                name: 'Monday',
                data: 'Monday',
            },
            {
                name: 'Tuesday',
                data: 'Tuesday',
            },
            {
                name: 'Wednesday',
                data: 'Wednesday',
            },
            {
                name: 'Thursday',
                data: 'Thursday',
            },
            {
                name: 'Friday',
                data: 'Friday',
            },
            {
                name: 'Saturday',
                data: 'Saturday',
            },
            {
                name: 'Sunday',
                data: 'Sunday',
            }
        ],
        [],
    );

    return (
        <div className={`market-panel-chart ${location.pathname === `/market-results/panel-chart/${id}` ? 'addmargin' : ''}`}>

            <div className=''>

                <div className='market-chart-content'>

                    <div className='market-chart-main-title'>

                        <h2> market <span>panel</span> chart </h2>
                        <div className='underline'><hr /></div>

                    </div>

                    <div className='market-chart'>


                        <div className='market-chart-title'><h3>{panachartApiData.marketName}</h3></div>

                        <div className='market-chart-table'>

                            <table className='table align-middle text-center'>

                                <thead className='table-head'>

                                    <tr>

                                        <th className='table-head-date'><h5>Date</h5></th>

                                        {columns.map((data) => (
                                            <th className='table-head-time' key={data.data}><h6>{data.name}</h6></th>
                                        ))
                                        }
                                    </tr>

                                </thead>

                                <tbody className='table-body'>

                                    {

                                        panachartApiData?.marketData?.map((data, index) =>

                                            <tr key={index}>

                                                <th className='table-date' >

                                                    <div className='table-body-date'>
                                                        <div className='table-from-date'><h6>{DateToLocalDateString(data.dateFrom)}</h6></div>
                                                        <div className='table-date-day'><h6>to</h6></div>
                                                        <div className='table-to-date'><h6>{DateToLocalDateString(data.dateTo)}</h6></div>
                                                    </div>

                                                </th>


                                                {
                                                    columns.map((column, index) => {


                                                        const aaa = data.days.find((e) => e.day === column.data)

                                                        return <td key={index}>

                                                            <div className='table-result'>
                                                                <div className='result-ank result-data' >

                                                                    <h6>
                                                                        {aaa?.open ? <div className='star'> {numberToDigit(aaa?.open)[0]} </div> : <div className='new-star'>*</div>}
                                                                        {aaa?.open ? <div className='star'> {numberToDigit(aaa?.open)[1]} </div> : <div className='new-star'>*</div>}
                                                                        {aaa?.open ? <div className='star'> {numberToDigit(aaa?.open)[2]} </div> : <div className='new-star'>*</div>}
                                                                    </h6>

                                                                </div>

                                                                <div className='main-result main-result-data'>

                                                                    <h6>{aaa?.open ? sumGetLastDigit(aaa?.open) : <div className={`star ${aaa?.open ? '' : ''}`}>*</div>}{aaa?.close ? sumGetLastDigit(aaa?.close) : <div className={`star ${aaa?.open ? 'ms-1' : ''}`}>*</div>}</h6></div>

                                                                <div className='result-ank result-data'>

                                                                    <h6>
                                                                        {aaa?.close ? <div className='star'> {numberToDigit(aaa?.close)[0]} </div> : <div className='new-star'>*</div>}
                                                                        {aaa?.close ? <div className='star'> {numberToDigit(aaa?.close)[1]} </div> : <div className='new-star'>*</div>}
                                                                        {aaa?.close ? <div className='star'> {numberToDigit(aaa?.close)[2]} </div> : <div className='new-star'>*</div>}
                                                                    </h6>

                                                                </div>

                                                            </div>

                                                        </td>




                                                    })}

                                            </tr>

                                        )

                                    }

                                </tbody>

                            </table>

                        </div>

                    </div>


                </div>

            </div>

        </div>
    )
}

export default MarketPanelChart
