import React from 'react'
import './Pointbar.css'
import deposit from '../../Images/deposite-icon.svg'
import download from '../../Images/mobile-icon.svg'
import result from '../../Images/fastresult-icon.svg'
import withdraw from '../../Images/withdrawal-icon.svg'

const Pointbar = () => {
  return (
    <div className='pointbar'>

        <div className=''>

            <div className='pointbar-content'>

                <div className='pointbar-box'>

                    <div className='pointbar-icon'><img src={deposit}/></div>
                    <div className='pointbar-text'><h3>₹200/-</h3><h5>Minimum Deposit</h5></div>

                </div>

                <div className='pointbar-box'>

                    <div className='pointbar-icon'><img src={download}/></div>
                    <div className='pointbar-text'><h3>100k+</h3><h5>Downloads</h5></div>

                </div>

                <div className='pointbar-box'>

                    <div className='pointbar-icon'><img src={result}/></div>
                    <div className='pointbar-text'><h3>Daily</h3><h5>Fast and Accurate Result</h5></div>

                </div>

                <div className='pointbar-box'>

                    <div className='pointbar-icon'><img src={withdraw}/></div>
                    <div className='pointbar-text'><h3>₹500/-</h3><h5>Minimum Withdrawal</h5></div>

                </div>

            </div>

        </div>
      
    </div>
  )
}

export default Pointbar 


    