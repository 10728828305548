import React, { useEffect, useState } from 'react'
import './RateChart.css'
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const RateChart = () => {

  const location = useLocation();

  const [marketRate, setMarketRate] = useState([]);
  const [starlineRate, setStarlineRate] = useState([]);

  const getMarketRate = async () => {
    await axios.get(`${process.env.REACT_APP_BASE_URL}/web/gameRate/false`)
      .then((r) => {
        setMarketRate(r.data.data)
      })
      .catch((err) => console.log(err.message));
  }

  const getStarlineRate = async () => {
    await axios.get(`${process.env.REACT_APP_BASE_URL}/web/gameRate/true`)
      .then((r) => {
        setStarlineRate(r.data.data)
      })
      .catch((err) => console.log(err.message));
  }

  useEffect(() => {

    getMarketRate()
    getStarlineRate()

  }, []);

  return (
    <div className={`rate-chart ${location.pathname === '/rate-chart' ? 'addmargin' : ''}`}>

      <div className=''>

        <div className='rate-chart-content'>

          <div className='rate-chart-heading'>

            <h2> online <span>spl</span> game rate </h2>
            <div className='underline'><hr /></div>

          </div>

          <div className='starline-rate'>

            <div className='starline-rate-heading'>
              <h3>starline rate</h3>
            </div>

            <div className='starline-rate-boxes'>

              {
                starlineRate.map((data) =>

                  <div className='rate-box-bg' key={data.id}>

                    <div className='rate-box-content'>

                      <div className='rate-box-data'>

                        <h4>{data.Name}</h4>
                        <h5>{data.BaseRate} KA {data.Rate}</h5>

                      </div>

                    </div>

                  </div>

                )
              }

              {/* <div className='rate-box-bg'>

                <div className='rate-box-content'>

                  <div className='rate-box-data'>

                    <h4>single panna</h4>
                    <h5>10 ka 1500</h5>

                  </div>

                </div>

              </div>
              <div className='rate-box-bg'>

                <div className='rate-box-content'>

                  <div className='rate-box-data'>

                    <h4>double panna</h4>
                    <h5>10 ka 3000</h5>

                  </div>

                </div>

              </div>
              <div className='rate-box-bg'>

                <div className='rate-box-content'>

                  <div className='rate-box-data'>

                    <h4>triple panna</h4>
                    <h5>10 ka 10000</h5>

                  </div>

                </div>

              </div> */}

            </div>

          </div>

          <div className='market-rate'>

            <div className='market-rate-heading'>
              <h3>Market rate</h3>
            </div>

            <div className='market-rate-boxes' >

              {
                marketRate.map((data) =>

                  <div className='rate-box-bg' key={data.id}>

                    <div className='rate-box-content'>

                      <div className='rate-box-data'>

                        <h4>{data.Name}</h4>
                        <h5>{data.BaseRate} KA {data.Rate}</h5>

                      </div>

                    </div>

                  </div>
                  
                )
              }



              {/* <div className='rate-box-bg'>

                <div className='rate-box-content'>

                  <div className='rate-box-data'>

                    <h4>jodi</h4>
                    <h5>10 ka 950</h5>

                  </div>

                </div>

              </div>
              <div className='rate-box-bg'>

                <div className='rate-box-content'>

                  <div className='rate-box-data'>

                    <h4>single panna</h4>
                    <h5>10 ka 1400</h5>

                  </div>

                </div>

              </div>
              <div className='rate-box-bg'>

                <div className='rate-box-content'>

                  <div className='rate-box-data'>

                    <h4>double panna</h4>
                    <h5>10 ka 2800</h5>

                  </div>

                </div>

              </div>
              <div className='rate-box-bg'>

                <div className='rate-box-content'>

                  <div className='rate-box-data'>

                    <h4>triple panna</h4>
                    <h5>10 ka 9000</h5>

                  </div>

                </div>

              </div>
              <div className='rate-box-bg'>

                <div className='rate-box-content'>

                  <div className='rate-box-data'>

                    <h4>Half Sanagm</h4>
                    <h5>10 ka 10000</h5>

                  </div>

                </div>

              </div>
              <div className='rate-box-bg'>

                <div className='rate-box-content'>

                  <div className='rate-box-data'>

                    <h4>full sangam</h4>
                    <h5>10 ka 100000</h5>

                  </div>

                </div>

              </div> */}

            </div>

          </div>

        </div>

      </div>

    </div>
  )
}

export default RateChart
