import React, { useEffect, useState } from 'react'
import './MarketResult.css'
import playbtn from '../../Images/play-btn.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'

const MarketResult = () => {

  const navigate = useNavigate()
  const location = useLocation()

  const [marketApiData, setMarketApiData] = useState([]);

  const getMarket = async () => {
    await axios.get(`${process.env.REACT_APP_BASE_URL}/web/getMarkets`)
      .then((r) => {
        setMarketApiData(r.data.data)
      })
      .catch((err) => console.log(err.message));
  }

  useEffect(() => {
    getMarket()
  }, []);

  function sumGetLastDigit(value) {

    if (value == null) {
      return null;
    } else {
      return (
        value
          .toString()
          .split('')
          .map(Number)
          .reduce(function (a, b) {
            return a + b;
          }, 0) % 10
      );
    }
  }

  const date = new Date();
  const dateForOpen = (time) => new Date(`${new Date().toDateString()} ${time}`)


  return (
    <div className={`market-result ${location.pathname === '/market-results' ? 'addmargin' : ''}`}>

      <div className='container'>

        <div className='market-result-content'>

          <div className='market-result-heading'>

            <h2> market <span>play</span> results </h2>
            <div className='underline'><hr /></div>

          </div>

          <div className='market-result-boxes'>

            {

              marketApiData.map((data) =>

                <div className='market-result-box' key={data.id}>

                  <div className='market-result-box-name'>

                    <h3>{data.Market ? data.Market : '-'}</h3>

                    <div className='play-btn'>

                      <div className={`play-btn-img ${date > dateForOpen(data.CloseTime) || data.IsActive === false ? 'disabled' : ''} `}><img src={playbtn} /></div>
                      <h6>play now</h6>

                    </div>

                  </div>

                  <div className='market-result-box-content'>

                    <div className='market-time'>

                      <div className='open-time'>

                        <h6>open time</h6>
                        <h6 className='time'>{data.OpenTime ? data.OpenTime : '-'} </h6>

                      </div>

                      <div className='close-time'>

                        <h6>close time</h6>
                        <h6 className='time'>{data.CloseTime ? data.CloseTime : '-'}</h6>

                      </div>


                    </div>

                    <div className='market-result-data'>

                      <h4 className={`${data.OpenResult ? 'mt-1' : 'mt-2'}`}>
                        {data.OpenResult ? data.OpenResult : <div className={`star ${data.OpenResult ? 'newstar' : ''}`}>***</div>}
                        <span className={`dash ${data.OpenResult ? 'newdash' : ''}`}><hr /></span>
                        {data?.OpenResult ? sumGetLastDigit(data?.OpenResult) : <div className={`star ${data.OpenResult ? 'newstar' : ''}`}>*</div>}
                        {data?.CloseResult ? sumGetLastDigit(data?.CloseResult) : <div className={`star ${data.OpenResult ? 'newstar' : ''}`}>*</div>}
                        <span className={`dash ${data.OpenResult ? 'newdash' : ''}`}><hr /></span>
                        {data.CloseResult ? data.CloseResult : <div className={`star ${data.OpenResult ? 'newstar' : ''}`}>***</div>}
                      </h4>

                    </div>

                    <div className={`${data.OpenResult ? 'chart-btn newchart-btn' : 'chart-btn'}`}>
                      <h5 onClick={() => navigate(`/market-results/jodi-chart/${data.MarketId}`)}>Jodi Chart</h5>
                      <span>|</span>
                      <h5 onClick={() => navigate(`/market-results/panel-chart/${data.MarketId}`)}>Panel Chart</h5>
                    </div>

                  </div>

                </div>

              )

            }

          </div>

        </div>


      </div>

    </div>
  )
}

export default MarketResult
