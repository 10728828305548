import React from 'react'
import './Navbar.css'
import logo from '../../Images/Spl-logo.png'
import { Link, useLocation, useParams } from 'react-router-dom'

const Navbar = () => {

    const CollapseHandling = () => {
        const navbarCollapse = document.getElementById('navbarSupportedContent');
        navbarCollapse.classList.remove('show');
    }

    const { pathname } = useLocation()

    return (
        <div className=''>

            <nav className="navbar navbar-expand-lg" id='nav'>

                <div className="container">

                    <Link to='/' className="navbar-brand" >

                        <div className='logo'>
                            <img src={logo} />
                        </div>

                    </Link>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" >
                        <span className="navbar-toggler-icon" />
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">

                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">

                            <li className="nav-item">
                                <Link to='/' className={` ${pathname === '/' ? "active" : ""} nav-link`} aria-current="page" href="#" onClick={CollapseHandling}>HOME</Link>
                            </li>

                            <li className="nav-item">
                                <Link to='/rate-chart' className={` ${pathname === '/rate-chart' ? "active" : ""} nav-link`} aria-current="page" href="#" onClick={CollapseHandling}>RATE CHARTS</Link>
                            </li>

                            <li className="nav-item">
                                <Link to='/market-results' className={` nav-link ${pathname.includes('/market-results')
                                    ? 'active ' : ''}`} aria-current="page" onClick={CollapseHandling}>MARKET RESULTS</Link>
                            </li>

                            <li className="nav-item">
                                <Link to='/starline-chart' className={` ${pathname === '/starline-chart' ? "active" : ""} nav-link`} aria-current="page" href="#" onClick={CollapseHandling}>STARLINE CHARTS</Link>
                            </li>

                        </ul>

                    </div>

                </div>

            </nav>

        </div>
    )
}

export default Navbar
