export const splData = [
  {
    MarketTime: "09:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2021-09-07",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2021-09-08",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2021-09-09",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2021-09-10",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2021-09-11",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-09-12",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2021-09-13",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-09-14",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "334",
    sumOpenNumber: "0",
    resultDate: "2021-09-15",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "790",
    sumOpenNumber: "6",
    resultDate: "2021-09-16",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2021-09-17",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "568",
    sumOpenNumber: "9",
    resultDate: "2021-09-18",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2021-09-19",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "118",
    sumOpenNumber: "0",
    resultDate: "2021-09-20",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2021-09-21",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-09-22",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2021-09-23",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2021-09-24",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2021-09-25",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "270",
    sumOpenNumber: "9",
    resultDate: "2021-09-26",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-09-27",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "236",
    sumOpenNumber: "1",
    resultDate: "2021-09-28",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2021-09-29",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-09-30",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2021-10-01",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2021-10-02",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "111",
    sumOpenNumber: "3",
    resultDate: "2021-10-03",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "580",
    sumOpenNumber: "3",
    resultDate: "2021-10-04",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2021-10-05",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "480",
    sumOpenNumber: "2",
    resultDate: "2021-10-06",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2021-10-07",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2021-10-08",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-10-09",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-10-10",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "570",
    sumOpenNumber: "2",
    resultDate: "2021-10-11",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "333",
    sumOpenNumber: "9",
    resultDate: "2021-10-12",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "279",
    sumOpenNumber: "8",
    resultDate: "2021-10-13",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "168",
    sumOpenNumber: "5",
    resultDate: "2021-10-14",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2021-10-15",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-10-16",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "889",
    sumOpenNumber: "5",
    resultDate: "2021-10-17",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-10-18",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-10-19",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2021-10-20",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2021-10-21",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-10-22",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "444",
    sumOpenNumber: "2",
    resultDate: "2021-10-23",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "112",
    sumOpenNumber: "4",
    resultDate: "2021-10-24",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "667",
    sumOpenNumber: "9",
    resultDate: "2021-10-25",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "229",
    sumOpenNumber: "3",
    resultDate: "2021-10-26",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2021-10-27",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2021-10-28",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2021-10-29",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "568",
    sumOpenNumber: "9",
    resultDate: "2021-10-30",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-10-31",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "599",
    sumOpenNumber: "3",
    resultDate: "2021-11-01",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-11-02",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "289",
    sumOpenNumber: "9",
    resultDate: "2021-11-03",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2021-11-04",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2021-11-05",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "236",
    sumOpenNumber: "1",
    resultDate: "2021-11-06",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-11-07",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "0",
    sumOpenNumber: "0",
    resultDate: "2021-11-08",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-11-09",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2021-11-10",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2021-11-11",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "888",
    sumOpenNumber: "4",
    resultDate: "2021-11-12",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2021-11-13",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "690",
    sumOpenNumber: "5",
    resultDate: "2021-11-14",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2021-11-15",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2021-11-16",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2021-11-17",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-11-18",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2021-11-19",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2021-11-20",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2021-11-21",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-11-22",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "126",
    sumOpenNumber: "9",
    resultDate: "2021-11-23",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2021-11-24",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2021-11-25",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2021-11-26",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "770",
    sumOpenNumber: "4",
    resultDate: "2021-11-27",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2021-11-28",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2021-11-29",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "333",
    sumOpenNumber: "9",
    resultDate: "2021-11-30",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "333",
    sumOpenNumber: "9",
    resultDate: "2021-12-01",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2021-12-02",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-12-03",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2021-12-04",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-12-05",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-12-06",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2021-12-07",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2021-12-08",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2021-12-09",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "333",
    sumOpenNumber: "9",
    resultDate: "2021-12-10",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2021-12-11",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "126",
    sumOpenNumber: "9",
    resultDate: "2021-12-12",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "689",
    sumOpenNumber: "3",
    resultDate: "2021-12-13",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2021-12-14",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "356",
    sumOpenNumber: "4",
    resultDate: "2021-12-15",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2021-12-16",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "557",
    sumOpenNumber: "7",
    resultDate: "2021-12-17",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-12-18",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "668",
    sumOpenNumber: "0",
    resultDate: "2021-12-19",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "699",
    sumOpenNumber: "4",
    resultDate: "2021-12-20",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "356",
    sumOpenNumber: "4",
    resultDate: "2021-12-21",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2021-12-23",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "356",
    sumOpenNumber: "4",
    resultDate: "2021-12-24",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "368",
    sumOpenNumber: "7",
    resultDate: "2021-12-25",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "379",
    sumOpenNumber: "9",
    resultDate: "2021-12-27",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "689",
    sumOpenNumber: "3",
    resultDate: "2021-12-28",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "388",
    sumOpenNumber: "9",
    resultDate: "2021-12-29",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-12-30",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "479",
    sumOpenNumber: "0",
    resultDate: "2021-12-31",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-01-01",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2022-01-02",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "568",
    sumOpenNumber: "9",
    resultDate: "2022-01-03",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2022-01-04",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "145",
    sumOpenNumber: "0",
    resultDate: "2022-01-05",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "679",
    sumOpenNumber: "2",
    resultDate: "2022-01-06",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "388",
    sumOpenNumber: "9",
    resultDate: "2022-01-07",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-01-08",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2022-01-09",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "157",
    sumOpenNumber: "3",
    resultDate: "2022-01-10",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-01-11",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "367",
    sumOpenNumber: "6",
    resultDate: "2022-01-12",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "177",
    sumOpenNumber: "5",
    resultDate: "2022-01-13",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "447",
    sumOpenNumber: "5",
    resultDate: "2022-01-14",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2022-01-15",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2022-01-16",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "227",
    sumOpenNumber: "1",
    resultDate: "2022-01-17",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2022-01-18",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "558",
    sumOpenNumber: "8",
    resultDate: "2022-01-19",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2022-01-20",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2022-01-21",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2022-01-22",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "558",
    sumOpenNumber: "8",
    resultDate: "2022-01-23",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "278",
    sumOpenNumber: "7",
    resultDate: "2022-01-24",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "335",
    sumOpenNumber: "1",
    resultDate: "2022-01-25",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "790",
    sumOpenNumber: "6",
    resultDate: "2022-01-26",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2022-01-27",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-01-28",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "233",
    sumOpenNumber: "8",
    resultDate: "2022-01-29",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "566",
    sumOpenNumber: "7",
    resultDate: "2022-01-30",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2022-01-31",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "589",
    sumOpenNumber: "2",
    resultDate: "2022-02-01",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "145",
    sumOpenNumber: "0",
    resultDate: "2022-02-02",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "667",
    sumOpenNumber: "9",
    resultDate: "2022-02-03",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-02-04",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2022-02-05",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-02-06",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2022-02-07",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "239",
    sumOpenNumber: "4",
    resultDate: "2022-02-08",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "790",
    sumOpenNumber: "6",
    resultDate: "2022-02-09",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2022-02-10",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2022-02-11",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-02-12",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2022-02-13",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2022-02-14",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "790",
    sumOpenNumber: "6",
    resultDate: "2022-02-16",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "278",
    sumOpenNumber: "7",
    resultDate: "2022-02-17",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-02-18",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "249",
    sumOpenNumber: "5",
    resultDate: "2022-02-19",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2022-02-20",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2022-02-21",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2022-02-22",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "480",
    sumOpenNumber: "2",
    resultDate: "2022-02-23",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "157",
    sumOpenNumber: "3",
    resultDate: "2022-02-24",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "259",
    sumOpenNumber: "6",
    resultDate: "2022-02-25",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-02-26",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2022-02-27",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "480",
    sumOpenNumber: "2",
    resultDate: "2022-02-28",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "366",
    sumOpenNumber: "5",
    resultDate: "2022-03-01",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-03-02",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "278",
    sumOpenNumber: "7",
    resultDate: "2022-03-03",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "690",
    sumOpenNumber: "5",
    resultDate: "2022-03-04",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2022-03-05",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "359",
    sumOpenNumber: "7",
    resultDate: "2022-03-06",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2022-03-07",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "590",
    sumOpenNumber: "4",
    resultDate: "2022-03-08",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "399",
    sumOpenNumber: "1",
    resultDate: "2022-03-09",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2022-03-10",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "589",
    sumOpenNumber: "2",
    resultDate: "2022-03-11",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2022-03-12",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "225",
    sumOpenNumber: "9",
    resultDate: "2022-03-13",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "689",
    sumOpenNumber: "3",
    resultDate: "2022-03-14",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-03-15",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "126",
    sumOpenNumber: "9",
    resultDate: "2022-03-16",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2022-03-17",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "270",
    sumOpenNumber: "9",
    resultDate: "2022-03-18",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2022-03-19",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-03-20",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "590",
    sumOpenNumber: "4",
    resultDate: "2022-03-21",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "127",
    sumOpenNumber: "0",
    resultDate: "2022-03-22",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "599",
    sumOpenNumber: "3",
    resultDate: "2022-03-23",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "699",
    sumOpenNumber: "4",
    resultDate: "2022-03-24",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2022-03-25",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "567",
    sumOpenNumber: "8",
    resultDate: "2022-03-26",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-03-27",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "112",
    sumOpenNumber: "4",
    resultDate: "2022-03-28",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-03-29",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "156",
    sumOpenNumber: "2",
    resultDate: "2022-03-30",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2022-03-31",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-04-01",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-04-02",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-04-03",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "279",
    sumOpenNumber: "8",
    resultDate: "2022-04-04",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-04-05",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2022-04-06",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-04-07",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "455",
    sumOpenNumber: "4",
    resultDate: "2022-04-08",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-04-09",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "369",
    sumOpenNumber: "8",
    resultDate: "2022-04-10",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2022-04-11",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-04-12",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-04-13",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-04-14",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2022-04-15",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2022-04-16",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2022-04-17",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-04-18",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "114",
    sumOpenNumber: "6",
    resultDate: "2022-04-19",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-04-20",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2022-04-21",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-04-22",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "229",
    sumOpenNumber: "3",
    resultDate: "2022-04-23",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2022-04-24",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "248",
    sumOpenNumber: "4",
    resultDate: "2022-04-25",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2022-04-26",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2022-04-27",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "589",
    sumOpenNumber: "2",
    resultDate: "2022-04-28",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2022-04-29",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "134",
    sumOpenNumber: "8",
    resultDate: "2022-04-30",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2022-05-01",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "333",
    sumOpenNumber: "9",
    resultDate: "2022-05-02",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-05-03",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-05-04",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "479",
    sumOpenNumber: "0",
    resultDate: "2022-05-05",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2022-05-06",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2022-05-07",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "177",
    sumOpenNumber: "5",
    resultDate: "2022-05-08",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "157",
    sumOpenNumber: "3",
    resultDate: "2022-05-09",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-05-10",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "236",
    sumOpenNumber: "1",
    resultDate: "2022-05-11",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "248",
    sumOpenNumber: "4",
    resultDate: "2022-05-12",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "356",
    sumOpenNumber: "4",
    resultDate: "2022-05-13",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "236",
    sumOpenNumber: "1",
    resultDate: "2022-05-14",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-05-15",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2022-05-16",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "580",
    sumOpenNumber: "3",
    resultDate: "2022-05-17",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "156",
    sumOpenNumber: "2",
    resultDate: "2022-05-18",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-05-19",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-05-20",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "790",
    sumOpenNumber: "6",
    resultDate: "2022-05-21",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "344",
    sumOpenNumber: "1",
    resultDate: "2022-05-22",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2022-05-23",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2022-05-24",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "378",
    sumOpenNumber: "8",
    resultDate: "2022-05-25",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-05-26",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-05-27",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "349",
    sumOpenNumber: "6",
    resultDate: "2022-05-28",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "567",
    sumOpenNumber: "8",
    resultDate: "2022-05-29",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2022-05-30",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "348",
    sumOpenNumber: "5",
    resultDate: "2022-05-31",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2022-06-01",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "990",
    sumOpenNumber: "8",
    resultDate: "2022-06-02",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "470",
    sumOpenNumber: "1",
    resultDate: "2022-06-03",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "377",
    sumOpenNumber: "7",
    resultDate: "2022-06-04",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2022-06-05",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2022-06-06",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "469",
    sumOpenNumber: "9",
    resultDate: "2022-06-07",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2022-06-08",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "349",
    sumOpenNumber: "6",
    resultDate: "2022-06-09",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2022-06-10",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "367",
    sumOpenNumber: "6",
    resultDate: "2022-06-11",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2022-06-12",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2022-06-13",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "128",
    sumOpenNumber: "1",
    resultDate: "2022-06-14",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "790",
    sumOpenNumber: "6",
    resultDate: "2022-06-15",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2022-06-16",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2022-06-17",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-06-18",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2022-06-19",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2022-06-20",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2022-06-21",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2022-06-22",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2022-06-23",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-06-24",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2022-06-25",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2022-06-26",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-06-27",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-06-28",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-06-29",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2022-06-30",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-07-01",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2022-07-03",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2022-07-04",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "579",
    sumOpenNumber: "1",
    resultDate: "2022-07-05",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2022-07-06",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "579",
    sumOpenNumber: "1",
    resultDate: "2022-07-07",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-07-08",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "378",
    sumOpenNumber: "8",
    resultDate: "2022-07-09",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2022-07-10",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "689",
    sumOpenNumber: "3",
    resultDate: "2022-07-11",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "278",
    sumOpenNumber: "7",
    resultDate: "2022-07-12",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2022-07-13",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2022-07-14",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2022-07-15",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2022-07-16",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-07-17",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2022-07-18",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2022-07-19",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "679",
    sumOpenNumber: "2",
    resultDate: "2022-07-20",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-07-21",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2022-07-22",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-07-23",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2022-07-24",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-07-25",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "990",
    sumOpenNumber: "8",
    resultDate: "2022-07-26",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2022-07-27",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "469",
    sumOpenNumber: "9",
    resultDate: "2022-07-28",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "239",
    sumOpenNumber: "4",
    resultDate: "2022-07-29",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-07-30",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2022-07-31",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "890",
    sumOpenNumber: "7",
    resultDate: "2022-08-01",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "580",
    sumOpenNumber: "3",
    resultDate: "2022-08-02",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2022-08-03",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2022-08-04",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "336",
    sumOpenNumber: "2",
    resultDate: "2022-08-05",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-08-06",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2022-08-07",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2022-08-08",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "137",
    sumOpenNumber: "1",
    resultDate: "2022-08-09",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2022-08-10",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2022-08-11",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-08-12",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2022-08-13",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2022-08-14",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2022-08-15",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2022-08-16",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-08-17",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-08-18",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "479",
    sumOpenNumber: "0",
    resultDate: "2022-08-20",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2022-08-21",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "567",
    sumOpenNumber: "8",
    resultDate: "2022-08-22",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-08-23",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-08-24",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2022-08-25",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2022-08-26",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "227",
    sumOpenNumber: "1",
    resultDate: "2022-08-27",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-08-28",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "890",
    sumOpenNumber: "7",
    resultDate: "2022-08-29",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2022-08-30",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-08-31",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2022-09-01",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2022-09-02",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2022-09-03",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "690",
    sumOpenNumber: "5",
    resultDate: "2022-09-04",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "377",
    sumOpenNumber: "7",
    resultDate: "2022-09-05",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2022-09-06",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "566",
    sumOpenNumber: "7",
    resultDate: "2022-09-07",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-09-08",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2022-09-09",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-09-10",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "337",
    sumOpenNumber: "3",
    resultDate: "2022-09-11",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2022-09-12",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "236",
    sumOpenNumber: "1",
    resultDate: "2022-09-13",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2022-09-14",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-09-15",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2022-09-16",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-09-17",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-09-18",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-09-20",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "990",
    sumOpenNumber: "8",
    resultDate: "2022-09-21",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2022-09-22",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "270",
    sumOpenNumber: "9",
    resultDate: "2022-09-23",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-09-24",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2022-09-25",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "567",
    sumOpenNumber: "8",
    resultDate: "2022-09-26",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "689",
    sumOpenNumber: "3",
    resultDate: "2022-09-27",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2022-09-28",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2022-09-29",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-09-30",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "145",
    sumOpenNumber: "0",
    resultDate: "2022-10-01",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2022-10-02",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-10-03",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2022-10-04",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-10-05",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-10-06",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "346",
    sumOpenNumber: "3",
    resultDate: "2022-10-07",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "379",
    sumOpenNumber: "9",
    resultDate: "2022-10-08",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "378",
    sumOpenNumber: "8",
    resultDate: "2022-10-09",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2022-10-10",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-10-11",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-10-12",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-10-13",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2022-10-14",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-10-15",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-10-16",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-10-17",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2022-10-18",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2022-10-19",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-10-20",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2022-10-21",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-10-22",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2022-10-23",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "899",
    sumOpenNumber: "6",
    resultDate: "2022-10-24",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2022-10-25",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2022-10-26",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "244",
    sumOpenNumber: "0",
    resultDate: "2022-10-27",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-10-28",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2022-10-29",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "228",
    sumOpenNumber: "2",
    resultDate: "2022-10-30",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2022-10-31",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-11-01",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-11-02",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2022-11-03",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-11-04",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2022-11-05",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "178",
    sumOpenNumber: "6",
    resultDate: "2022-11-06",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "145",
    sumOpenNumber: "0",
    resultDate: "2022-11-07",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "479",
    sumOpenNumber: "0",
    resultDate: "2022-11-08",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-11-09",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2022-11-10",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-11-11",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2022-11-12",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2022-11-13",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-11-14",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-11-15",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2022-11-16",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-11-17",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2022-11-18",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2022-11-19",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-11-20",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "890",
    sumOpenNumber: "7",
    resultDate: "2022-11-21",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2022-11-22",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2022-11-23",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "399",
    sumOpenNumber: "1",
    resultDate: "2022-11-24",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2022-11-25",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-11-26",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2022-11-27",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "580",
    sumOpenNumber: "3",
    resultDate: "2022-11-28",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-11-29",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "233",
    sumOpenNumber: "8",
    resultDate: "2022-11-30",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-12-01",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2022-12-02",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-12-04",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2022-12-05",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-12-06",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "344",
    sumOpenNumber: "1",
    resultDate: "2022-12-07",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-12-08",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "148",
    sumOpenNumber: "3",
    resultDate: "2022-12-09",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-12-10",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2022-12-11",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2022-12-12",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2022-12-13",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-12-14",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2022-12-15",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2022-12-16",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2022-12-17",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-12-18",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-12-19",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2022-12-20",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2022-12-21",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "188",
    sumOpenNumber: "7",
    resultDate: "2022-12-22",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-12-23",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "377",
    sumOpenNumber: "7",
    resultDate: "2022-12-24",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "559",
    sumOpenNumber: "9",
    resultDate: "2022-12-25",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-12-26",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2022-12-27",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "558",
    sumOpenNumber: "8",
    resultDate: "2022-12-28",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2022-12-29",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-12-30",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2022-12-31",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "270",
    sumOpenNumber: "9",
    resultDate: "2023-01-01",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2023-01-02",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2023-01-03",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-01-04",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-01-05",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-01-06",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-01-07",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "448",
    sumOpenNumber: "6",
    resultDate: "2023-01-08",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "335",
    sumOpenNumber: "1",
    resultDate: "2023-01-09",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2023-01-10",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "470",
    sumOpenNumber: "1",
    resultDate: "2023-01-11",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2023-01-12",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2023-01-13",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-01-14",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "334",
    sumOpenNumber: "0",
    resultDate: "2023-01-15",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "368",
    sumOpenNumber: "7",
    resultDate: "2023-01-16",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2023-01-17",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2023-01-18",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2023-01-19",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "679",
    sumOpenNumber: "2",
    resultDate: "2023-01-20",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "222",
    sumOpenNumber: "6",
    resultDate: "2023-01-21",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "699",
    sumOpenNumber: "4",
    resultDate: "2023-01-22",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "244",
    sumOpenNumber: "0",
    resultDate: "2023-01-23",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-01-24",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "777",
    sumOpenNumber: "1",
    resultDate: "2023-01-25",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "224",
    sumOpenNumber: "8",
    resultDate: "2023-01-26",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-01-27",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-01-28",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "299",
    sumOpenNumber: "0",
    resultDate: "2023-01-29",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2023-01-30",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2023-01-31",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-02-01",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2023-02-02",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "117",
    sumOpenNumber: "9",
    resultDate: "2023-02-03",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "168",
    sumOpenNumber: "5",
    resultDate: "2023-02-04",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-02-05",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "346",
    sumOpenNumber: "3",
    resultDate: "2023-02-06",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2023-02-07",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2023-02-08",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2023-02-09",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-02-10",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2023-02-11",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "447",
    sumOpenNumber: "5",
    resultDate: "2023-02-12",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-02-13",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-02-14",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-02-15",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "299",
    sumOpenNumber: "0",
    resultDate: "2023-02-16",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "388",
    sumOpenNumber: "9",
    resultDate: "2023-02-17",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "236",
    sumOpenNumber: "1",
    resultDate: "2023-02-18",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "567",
    sumOpenNumber: "8",
    resultDate: "2023-02-19",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2023-02-20",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2023-02-21",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-02-22",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2023-02-23",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2023-02-24",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2023-02-25",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "266",
    sumOpenNumber: "4",
    resultDate: "2023-02-26",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "999",
    sumOpenNumber: "7",
    resultDate: "2023-02-27",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "338",
    sumOpenNumber: "4",
    resultDate: "2023-02-28",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "233",
    sumOpenNumber: "8",
    resultDate: "2023-03-01",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2023-03-02",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "567",
    sumOpenNumber: "8",
    resultDate: "2023-03-03",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2023-03-04",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "268",
    sumOpenNumber: "6",
    resultDate: "2023-03-05",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2023-03-06",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2023-03-07",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-03-08",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "266",
    sumOpenNumber: "4",
    resultDate: "2023-03-09",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "244",
    sumOpenNumber: "0",
    resultDate: "2023-03-10",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2023-03-11",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "448",
    sumOpenNumber: "6",
    resultDate: "2023-03-12",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2023-03-16",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2023-03-17",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "577",
    sumOpenNumber: "9",
    resultDate: "2023-03-18",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-03-19",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "488",
    sumOpenNumber: "0",
    resultDate: "2023-03-20",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "299",
    sumOpenNumber: "0",
    resultDate: "2023-03-21",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2023-03-22",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "118",
    sumOpenNumber: "0",
    resultDate: "2023-03-23",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-03-24",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-03-25",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2023-03-26",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2023-03-27",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2023-03-28",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-03-29",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "337",
    sumOpenNumber: "3",
    resultDate: "2023-03-30",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2023-03-31",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "599",
    sumOpenNumber: "3",
    resultDate: "2023-04-01",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "339",
    sumOpenNumber: "5",
    resultDate: "2023-04-02",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2023-04-03",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2023-04-04",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2023-04-05",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-04-06",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "557",
    sumOpenNumber: "7",
    resultDate: "2023-04-07",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-04-08",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2023-04-09",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-04-10",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2023-04-11",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2023-04-12",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "117",
    sumOpenNumber: "9",
    resultDate: "2023-04-13",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "113",
    sumOpenNumber: "5",
    resultDate: "2023-04-14",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2023-04-15",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "457",
    sumOpenNumber: "6",
    resultDate: "2023-04-16",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2023-04-17",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "249",
    sumOpenNumber: "5",
    resultDate: "2023-04-18",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2023-04-19",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2023-04-20",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "222",
    sumOpenNumber: "6",
    resultDate: "2023-04-21",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2023-04-22",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2023-04-23",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "366",
    sumOpenNumber: "5",
    resultDate: "2023-04-24",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-04-25",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-04-26",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2023-04-27",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "118",
    sumOpenNumber: "0",
    resultDate: "2023-04-28",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2023-04-29",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "477",
    sumOpenNumber: "8",
    resultDate: "2023-04-30",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-05-01",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2023-05-02",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2023-05-03",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "777",
    sumOpenNumber: "1",
    resultDate: "2023-05-04",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2023-05-05",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2023-05-06",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-05-07",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2023-05-08",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "168",
    sumOpenNumber: "5",
    resultDate: "2023-05-09",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "299",
    sumOpenNumber: "0",
    resultDate: "2023-05-10",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "227",
    sumOpenNumber: "1",
    resultDate: "2023-05-11",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2023-05-12",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "455",
    sumOpenNumber: "4",
    resultDate: "2023-05-13",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-05-14",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2023-05-15",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-05-16",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2023-05-17",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2023-05-18",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-05-19",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-05-20",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "455",
    sumOpenNumber: "4",
    resultDate: "2023-05-21",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2023-05-22",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2023-05-23",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-05-24",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "299",
    sumOpenNumber: "0",
    resultDate: "2023-05-25",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "447",
    sumOpenNumber: "5",
    resultDate: "2023-05-26",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2023-05-27",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2023-05-28",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "166",
    sumOpenNumber: "3",
    resultDate: "2023-05-29",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2023-05-30",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "128",
    sumOpenNumber: "1",
    resultDate: "2023-05-31",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "690",
    sumOpenNumber: "5",
    resultDate: "2023-06-01",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2023-06-02",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "799",
    sumOpenNumber: "5",
    resultDate: "2023-06-03",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "166",
    sumOpenNumber: "3",
    resultDate: "2023-06-04",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2023-06-05",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-06-06",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "580",
    sumOpenNumber: "3",
    resultDate: "2023-06-07",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-06-08",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-06-09",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "366",
    sumOpenNumber: "5",
    resultDate: "2023-06-10",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2023-06-11",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2023-06-12",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2023-06-13",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "224",
    sumOpenNumber: "8",
    resultDate: "2023-06-14",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2023-06-15",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-06-16",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "270",
    sumOpenNumber: "9",
    resultDate: "2023-06-17",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-06-18",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "228",
    sumOpenNumber: "2",
    resultDate: "2023-06-19",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "266",
    sumOpenNumber: "4",
    resultDate: "2023-06-20",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "448",
    sumOpenNumber: "6",
    resultDate: "2023-06-21",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "599",
    sumOpenNumber: "3",
    resultDate: "2023-06-22",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2023-06-23",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "566",
    sumOpenNumber: "7",
    resultDate: "2023-06-24",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2023-06-25",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "666",
    sumOpenNumber: "8",
    resultDate: "2023-06-26",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "778",
    sumOpenNumber: "2",
    resultDate: "2023-06-27",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "588",
    sumOpenNumber: "1",
    resultDate: "2023-06-28",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-06-29",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "127",
    sumOpenNumber: "0",
    resultDate: "2023-06-30",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2023-07-01",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2023-07-02",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "449",
    sumOpenNumber: "7",
    resultDate: "2023-07-03",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2023-07-04",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-07-05",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2023-07-06",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "399",
    sumOpenNumber: "1",
    resultDate: "2023-07-07",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "334",
    sumOpenNumber: "0",
    resultDate: "2023-07-08",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-07-09",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "229",
    sumOpenNumber: "3",
    resultDate: "2023-07-10",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2023-07-11",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "777",
    sumOpenNumber: "1",
    resultDate: "2023-07-12",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "448",
    sumOpenNumber: "6",
    resultDate: "2023-07-13",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-07-14",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2023-07-15",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-07-16",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-07-17",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2023-07-18",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-07-19",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "177",
    sumOpenNumber: "5",
    resultDate: "2023-07-20",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2023-07-21",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-07-22",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2023-07-23",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-07-24",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "111",
    sumOpenNumber: "3",
    resultDate: "2023-07-25",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "449",
    sumOpenNumber: "7",
    resultDate: "2023-07-26",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2023-07-27",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2023-07-28",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "588",
    sumOpenNumber: "1",
    resultDate: "2023-07-29",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2023-07-30",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "680",
    sumOpenNumber: "4",
    resultDate: "2023-07-31",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-08-01",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2023-08-02",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2023-08-03",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-08-04",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "166",
    sumOpenNumber: "3",
    resultDate: "2023-08-05",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "137",
    sumOpenNumber: "1",
    resultDate: "2023-08-06",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "114",
    sumOpenNumber: "6",
    resultDate: "2023-08-07",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "224",
    sumOpenNumber: "8",
    resultDate: "2023-08-08",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "557",
    sumOpenNumber: "7",
    resultDate: "2023-08-09",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-08-10",
  },
  {
    MarketTime: "09:00:00 PM",
    openNumber: "679",
    sumOpenNumber: "2",
    resultDate: "2023-08-11",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2021-09-07",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2021-09-08",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-09-09",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2021-09-10",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "233",
    sumOpenNumber: "8",
    resultDate: "2021-09-11",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "399",
    sumOpenNumber: "1",
    resultDate: "2021-09-12",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2021-09-13",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "559",
    sumOpenNumber: "9",
    resultDate: "2021-09-14",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2021-09-15",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "570",
    sumOpenNumber: "2",
    resultDate: "2021-09-16",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "888",
    sumOpenNumber: "4",
    resultDate: "2021-09-17",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "455",
    sumOpenNumber: "4",
    resultDate: "2021-09-18",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "236",
    sumOpenNumber: "1",
    resultDate: "2021-09-19",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "168",
    sumOpenNumber: "5",
    resultDate: "2021-09-20",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "278",
    sumOpenNumber: "7",
    resultDate: "2021-09-21",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-09-22",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2021-09-23",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-09-24",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "478",
    sumOpenNumber: "9",
    resultDate: "2021-09-25",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "137",
    sumOpenNumber: "1",
    resultDate: "2021-09-26",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2021-09-27",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-09-28",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2021-09-29",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-09-30",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "368",
    sumOpenNumber: "7",
    resultDate: "2021-10-01",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-10-02",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "333",
    sumOpenNumber: "9",
    resultDate: "2021-10-03",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2021-10-04",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2021-10-05",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "113",
    sumOpenNumber: "5",
    resultDate: "2021-10-06",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "223",
    sumOpenNumber: "7",
    resultDate: "2021-10-07",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "289",
    sumOpenNumber: "9",
    resultDate: "2021-10-08",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-10-09",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2021-10-10",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "225",
    sumOpenNumber: "9",
    resultDate: "2021-10-11",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-10-12",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2021-10-13",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2021-10-14",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "199",
    sumOpenNumber: "9",
    resultDate: "2021-10-15",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2021-10-16",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "379",
    sumOpenNumber: "9",
    resultDate: "2021-10-17",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2021-10-18",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2021-10-19",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2021-10-20",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "457",
    sumOpenNumber: "6",
    resultDate: "2021-10-21",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2021-10-22",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-10-23",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "147",
    sumOpenNumber: "2",
    resultDate: "2021-10-24",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2021-10-25",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2021-10-26",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2021-10-27",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-10-28",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2021-10-29",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "236",
    sumOpenNumber: "1",
    resultDate: "2021-10-30",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2021-10-31",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2021-11-01",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2021-11-02",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "111",
    sumOpenNumber: "3",
    resultDate: "2021-11-03",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-11-04",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-11-05",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "356",
    sumOpenNumber: "4",
    resultDate: "2021-11-06",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-11-07",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2021-11-08",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2021-11-09",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-11-10",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "335",
    sumOpenNumber: "1",
    resultDate: "2021-11-11",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-11-12",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "999",
    sumOpenNumber: "7",
    resultDate: "2021-11-13",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "228",
    sumOpenNumber: "2",
    resultDate: "2021-11-14",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-11-15",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-11-16",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2021-11-17",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2021-11-18",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2021-11-19",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "490",
    sumOpenNumber: "3",
    resultDate: "2021-11-20",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2021-11-21",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2021-11-22",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-11-23",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "0",
    sumOpenNumber: "0",
    resultDate: "2021-11-24",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2021-11-25",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-11-26",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2021-11-27",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "113",
    sumOpenNumber: "5",
    resultDate: "2021-11-28",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2021-11-29",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2021-11-30",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2021-12-01",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "888",
    sumOpenNumber: "4",
    resultDate: "2021-12-02",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2021-12-03",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2021-12-04",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2021-12-05",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2021-12-06",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2021-12-07",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2021-12-08",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2021-12-09",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2021-12-10",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2021-12-11",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2021-12-12",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2021-12-13",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "356",
    sumOpenNumber: "4",
    resultDate: "2021-12-14",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2021-12-15",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2021-12-16",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2021-12-17",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "127",
    sumOpenNumber: "0",
    resultDate: "2021-12-18",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "499",
    sumOpenNumber: "2",
    resultDate: "2021-12-19",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "333",
    sumOpenNumber: "9",
    resultDate: "2021-12-20",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2021-12-21",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2021-12-22",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2021-12-23",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2021-12-24",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2021-12-25",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-12-26",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "156",
    sumOpenNumber: "2",
    resultDate: "2021-12-27",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "226",
    sumOpenNumber: "0",
    resultDate: "2021-12-28",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2021-12-29",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2021-12-30",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2021-12-31",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2022-01-01",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2022-01-02",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "679",
    sumOpenNumber: "2",
    resultDate: "2022-01-03",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "580",
    sumOpenNumber: "3",
    resultDate: "2022-01-04",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "134",
    sumOpenNumber: "8",
    resultDate: "2022-01-05",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2022-01-06",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-01-07",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2022-01-08",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2022-01-09",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "188",
    sumOpenNumber: "7",
    resultDate: "2022-01-10",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2022-01-11",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "568",
    sumOpenNumber: "9",
    resultDate: "2022-01-12",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "147",
    sumOpenNumber: "2",
    resultDate: "2022-01-13",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "469",
    sumOpenNumber: "9",
    resultDate: "2022-01-14",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "239",
    sumOpenNumber: "4",
    resultDate: "2022-01-15",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "128",
    sumOpenNumber: "1",
    resultDate: "2022-01-16",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2022-01-17",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "145",
    sumOpenNumber: "0",
    resultDate: "2022-01-18",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "222",
    sumOpenNumber: "6",
    resultDate: "2022-01-19",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-01-20",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "288",
    sumOpenNumber: "8",
    resultDate: "2022-01-21",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2022-01-22",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "346",
    sumOpenNumber: "3",
    resultDate: "2022-01-23",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2022-01-24",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2022-01-25",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "128",
    sumOpenNumber: "1",
    resultDate: "2022-01-26",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "557",
    sumOpenNumber: "7",
    resultDate: "2022-01-27",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-01-28",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "559",
    sumOpenNumber: "9",
    resultDate: "2022-01-29",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2022-01-30",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-01-31",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "111",
    sumOpenNumber: "3",
    resultDate: "2022-02-01",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "247",
    sumOpenNumber: "3",
    resultDate: "2022-02-02",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2022-02-03",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "270",
    sumOpenNumber: "9",
    resultDate: "2022-02-04",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2022-02-05",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "249",
    sumOpenNumber: "5",
    resultDate: "2022-02-06",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2022-02-07",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-02-08",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2022-02-09",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "244",
    sumOpenNumber: "0",
    resultDate: "2022-02-10",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "117",
    sumOpenNumber: "9",
    resultDate: "2022-02-11",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-02-12",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "134",
    sumOpenNumber: "8",
    resultDate: "2022-02-13",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "278",
    sumOpenNumber: "7",
    resultDate: "2022-02-14",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "270",
    sumOpenNumber: "9",
    resultDate: "2022-02-15",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2022-02-16",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-02-17",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-02-18",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-02-19",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-02-20",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2022-02-21",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-02-22",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-02-23",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2022-02-24",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "559",
    sumOpenNumber: "9",
    resultDate: "2022-02-25",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2022-02-26",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2022-02-27",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2022-02-28",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "228",
    sumOpenNumber: "2",
    resultDate: "2022-03-01",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "114",
    sumOpenNumber: "6",
    resultDate: "2022-03-02",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2022-03-03",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-03-04",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "199",
    sumOpenNumber: "9",
    resultDate: "2022-03-05",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2022-03-06",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "679",
    sumOpenNumber: "2",
    resultDate: "2022-03-07",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "134",
    sumOpenNumber: "8",
    resultDate: "2022-03-08",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "790",
    sumOpenNumber: "6",
    resultDate: "2022-03-09",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2022-03-10",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "457",
    sumOpenNumber: "6",
    resultDate: "2022-03-11",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2022-03-12",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2022-03-13",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2022-03-14",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2022-03-15",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2022-03-16",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2022-03-17",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "490",
    sumOpenNumber: "3",
    resultDate: "2022-03-18",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2022-03-19",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2022-03-20",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2022-03-21",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "688",
    sumOpenNumber: "2",
    resultDate: "2022-03-22",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "689",
    sumOpenNumber: "3",
    resultDate: "2022-03-23",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-03-24",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2022-03-25",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-03-26",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "157",
    sumOpenNumber: "3",
    resultDate: "2022-03-27",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2022-03-28",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "690",
    sumOpenNumber: "5",
    resultDate: "2022-03-29",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "113",
    sumOpenNumber: "5",
    resultDate: "2022-03-30",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-03-31",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2022-04-01",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "557",
    sumOpenNumber: "7",
    resultDate: "2022-04-02",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-04-03",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2022-04-04",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2022-04-05",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "145",
    sumOpenNumber: "0",
    resultDate: "2022-04-06",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2022-04-07",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-04-08",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "390",
    sumOpenNumber: "2",
    resultDate: "2022-04-09",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "278",
    sumOpenNumber: "7",
    resultDate: "2022-04-10",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-04-11",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "470",
    sumOpenNumber: "1",
    resultDate: "2022-04-12",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-04-13",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "799",
    sumOpenNumber: "5",
    resultDate: "2022-04-14",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2022-04-15",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "249",
    sumOpenNumber: "5",
    resultDate: "2022-04-16",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2022-04-17",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-04-18",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "356",
    sumOpenNumber: "4",
    resultDate: "2022-04-19",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2022-04-20",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "225",
    sumOpenNumber: "9",
    resultDate: "2022-04-21",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "244",
    sumOpenNumber: "0",
    resultDate: "2022-04-22",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-04-23",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "199",
    sumOpenNumber: "9",
    resultDate: "2022-04-24",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-04-25",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-04-26",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2022-04-27",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "269",
    sumOpenNumber: "7",
    resultDate: "2022-04-28",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2022-04-29",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-04-30",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2022-05-01",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "156",
    sumOpenNumber: "2",
    resultDate: "2022-05-02",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "489",
    sumOpenNumber: "1",
    resultDate: "2022-05-03",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "268",
    sumOpenNumber: "6",
    resultDate: "2022-05-04",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2022-05-05",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2022-05-06",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "490",
    sumOpenNumber: "3",
    resultDate: "2022-05-07",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "279",
    sumOpenNumber: "8",
    resultDate: "2022-05-08",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-05-09",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "369",
    sumOpenNumber: "8",
    resultDate: "2022-05-10",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "369",
    sumOpenNumber: "8",
    resultDate: "2022-05-11",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "390",
    sumOpenNumber: "2",
    resultDate: "2022-05-12",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "267",
    sumOpenNumber: "5",
    resultDate: "2022-05-13",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "128",
    sumOpenNumber: "1",
    resultDate: "2022-05-14",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2022-05-15",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-05-16",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "238",
    sumOpenNumber: "3",
    resultDate: "2022-05-17",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2022-05-18",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2022-05-19",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2022-05-20",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "799",
    sumOpenNumber: "5",
    resultDate: "2022-05-21",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "580",
    sumOpenNumber: "3",
    resultDate: "2022-05-22",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2022-05-23",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2022-05-24",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2022-05-25",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2022-05-26",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-05-27",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-05-28",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2022-05-29",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-05-30",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-05-31",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "349",
    sumOpenNumber: "6",
    resultDate: "2022-06-01",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "457",
    sumOpenNumber: "6",
    resultDate: "2022-06-02",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-06-03",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-06-04",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2022-06-05",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "449",
    sumOpenNumber: "7",
    resultDate: "2022-06-06",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "233",
    sumOpenNumber: "8",
    resultDate: "2022-06-07",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-06-08",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "689",
    sumOpenNumber: "3",
    resultDate: "2022-06-09",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "469",
    sumOpenNumber: "9",
    resultDate: "2022-06-10",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "680",
    sumOpenNumber: "4",
    resultDate: "2022-06-11",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2022-06-12",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "259",
    sumOpenNumber: "6",
    resultDate: "2022-06-13",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "138",
    sumOpenNumber: "2",
    resultDate: "2022-06-14",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "188",
    sumOpenNumber: "7",
    resultDate: "2022-06-15",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "369",
    sumOpenNumber: "8",
    resultDate: "2022-06-16",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "128",
    sumOpenNumber: "1",
    resultDate: "2022-06-17",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-06-18",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2022-06-19",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-06-20",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2022-06-21",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-06-22",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2022-06-23",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2022-06-24",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2022-06-25",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "288",
    sumOpenNumber: "8",
    resultDate: "2022-06-26",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2022-06-27",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "148",
    sumOpenNumber: "3",
    resultDate: "2022-06-28",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2022-06-29",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "338",
    sumOpenNumber: "4",
    resultDate: "2022-06-30",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-07-01",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2022-07-02",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "134",
    sumOpenNumber: "8",
    resultDate: "2022-07-03",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2022-07-04",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2022-07-05",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "390",
    sumOpenNumber: "2",
    resultDate: "2022-07-06",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2022-07-07",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-07-08",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-07-09",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-07-10",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2022-07-11",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "899",
    sumOpenNumber: "6",
    resultDate: "2022-07-12",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "470",
    sumOpenNumber: "1",
    resultDate: "2022-07-13",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "480",
    sumOpenNumber: "2",
    resultDate: "2022-07-14",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "277",
    sumOpenNumber: "6",
    resultDate: "2022-07-15",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "248",
    sumOpenNumber: "4",
    resultDate: "2022-07-16",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "558",
    sumOpenNumber: "8",
    resultDate: "2022-07-17",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2022-07-18",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2022-07-19",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2022-07-20",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "356",
    sumOpenNumber: "4",
    resultDate: "2022-07-21",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2022-07-22",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2022-07-23",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "128",
    sumOpenNumber: "1",
    resultDate: "2022-07-24",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2022-07-25",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2022-07-26",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "368",
    sumOpenNumber: "7",
    resultDate: "2022-07-27",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2022-07-28",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2022-07-29",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-07-30",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2022-07-31",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "338",
    sumOpenNumber: "4",
    resultDate: "2022-08-01",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "335",
    sumOpenNumber: "1",
    resultDate: "2022-08-02",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "289",
    sumOpenNumber: "9",
    resultDate: "2022-08-03",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2022-08-04",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2022-08-05",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "259",
    sumOpenNumber: "6",
    resultDate: "2022-08-06",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2022-08-07",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-08-08",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-08-09",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-08-10",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "249",
    sumOpenNumber: "5",
    resultDate: "2022-08-11",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2022-08-12",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2022-08-13",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2022-08-14",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2022-08-15",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2022-08-16",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "145",
    sumOpenNumber: "0",
    resultDate: "2022-08-17",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-08-18",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2022-08-19",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "899",
    sumOpenNumber: "6",
    resultDate: "2022-08-20",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-08-21",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-08-22",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "239",
    sumOpenNumber: "4",
    resultDate: "2022-08-23",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-08-24",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "570",
    sumOpenNumber: "2",
    resultDate: "2022-08-25",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2022-08-26",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "157",
    sumOpenNumber: "3",
    resultDate: "2022-08-27",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "157",
    sumOpenNumber: "3",
    resultDate: "2022-08-28",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "455",
    sumOpenNumber: "4",
    resultDate: "2022-08-29",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2022-08-30",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-08-31",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-09-01",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2022-09-02",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-09-03",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-09-04",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-09-05",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "367",
    sumOpenNumber: "6",
    resultDate: "2022-09-06",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-09-07",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "688",
    sumOpenNumber: "2",
    resultDate: "2022-09-08",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2022-09-09",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-09-10",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "157",
    sumOpenNumber: "3",
    resultDate: "2022-09-11",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2022-09-12",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2022-09-13",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "248",
    sumOpenNumber: "4",
    resultDate: "2022-09-14",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "367",
    sumOpenNumber: "6",
    resultDate: "2022-09-15",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-09-16",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-09-17",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "577",
    sumOpenNumber: "9",
    resultDate: "2022-09-18",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "146",
    sumOpenNumber: "1",
    resultDate: "2022-09-19",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "348",
    sumOpenNumber: "5",
    resultDate: "2022-09-20",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2022-09-21",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-09-22",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2022-09-23",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2022-09-24",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2022-09-25",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "146",
    sumOpenNumber: "1",
    resultDate: "2022-09-26",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "377",
    sumOpenNumber: "7",
    resultDate: "2022-09-27",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "157",
    sumOpenNumber: "3",
    resultDate: "2022-09-28",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2022-09-29",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2022-09-30",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2022-10-01",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-10-02",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "278",
    sumOpenNumber: "7",
    resultDate: "2022-10-03",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "378",
    sumOpenNumber: "8",
    resultDate: "2022-10-04",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "690",
    sumOpenNumber: "5",
    resultDate: "2022-10-05",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "346",
    sumOpenNumber: "3",
    resultDate: "2022-10-06",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "147",
    sumOpenNumber: "2",
    resultDate: "2022-10-07",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-10-08",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "156",
    sumOpenNumber: "2",
    resultDate: "2022-10-09",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-10-10",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-10-11",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2022-10-12",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2022-10-13",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-10-14",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-10-15",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-10-16",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2022-10-17",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2022-10-18",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "146",
    sumOpenNumber: "1",
    resultDate: "2022-10-19",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-10-20",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2022-10-21",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2022-10-22",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2022-10-23",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2022-10-24",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "136",
    sumOpenNumber: "0",
    resultDate: "2022-10-25",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2022-10-26",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2022-10-27",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "378",
    sumOpenNumber: "8",
    resultDate: "2022-10-28",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2022-10-29",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2022-10-30",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2022-10-31",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "469",
    sumOpenNumber: "9",
    resultDate: "2022-11-01",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-11-02",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "489",
    sumOpenNumber: "1",
    resultDate: "2022-11-03",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "244",
    sumOpenNumber: "0",
    resultDate: "2022-11-04",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-11-05",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-11-06",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2022-11-07",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2022-11-08",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "377",
    sumOpenNumber: "7",
    resultDate: "2022-11-09",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2022-11-10",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "289",
    sumOpenNumber: "9",
    resultDate: "2022-11-11",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "445",
    sumOpenNumber: "3",
    resultDate: "2022-11-12",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-11-13",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "247",
    sumOpenNumber: "3",
    resultDate: "2022-11-14",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-11-15",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "457",
    sumOpenNumber: "6",
    resultDate: "2022-11-16",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-11-17",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2022-11-18",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "579",
    sumOpenNumber: "1",
    resultDate: "2022-11-19",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2022-11-20",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "268",
    sumOpenNumber: "6",
    resultDate: "2022-11-21",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "990",
    sumOpenNumber: "8",
    resultDate: "2022-11-22",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "378",
    sumOpenNumber: "8",
    resultDate: "2022-11-23",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "249",
    sumOpenNumber: "5",
    resultDate: "2022-11-24",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-11-25",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2022-11-26",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2022-11-27",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "570",
    sumOpenNumber: "2",
    resultDate: "2022-11-28",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "568",
    sumOpenNumber: "9",
    resultDate: "2022-11-29",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-11-30",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "236",
    sumOpenNumber: "1",
    resultDate: "2022-12-01",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2022-12-02",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2022-12-03",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-12-04",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-12-05",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2022-12-06",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-12-07",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "267",
    sumOpenNumber: "5",
    resultDate: "2022-12-08",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "156",
    sumOpenNumber: "2",
    resultDate: "2022-12-09",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-12-10",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "480",
    sumOpenNumber: "2",
    resultDate: "2022-12-11",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "239",
    sumOpenNumber: "4",
    resultDate: "2022-12-12",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "138",
    sumOpenNumber: "2",
    resultDate: "2022-12-13",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "779",
    sumOpenNumber: "3",
    resultDate: "2022-12-14",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2022-12-15",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2022-12-16",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2022-12-17",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2022-12-18",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-12-19",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "390",
    sumOpenNumber: "2",
    resultDate: "2022-12-20",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-12-21",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2022-12-22",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "377",
    sumOpenNumber: "7",
    resultDate: "2022-12-23",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2022-12-24",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-12-25",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2022-12-26",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2022-12-27",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2022-12-28",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-12-29",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2022-12-30",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2022-12-31",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "680",
    sumOpenNumber: "4",
    resultDate: "2023-01-01",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "117",
    sumOpenNumber: "9",
    resultDate: "2023-01-02",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2023-01-03",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2023-01-04",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2023-01-05",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2023-01-06",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2023-01-07",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2023-01-08",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "225",
    sumOpenNumber: "9",
    resultDate: "2023-01-09",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2023-01-10",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "114",
    sumOpenNumber: "6",
    resultDate: "2023-01-11",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2023-01-12",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "288",
    sumOpenNumber: "8",
    resultDate: "2023-01-13",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2023-01-14",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-01-15",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "229",
    sumOpenNumber: "3",
    resultDate: "2023-01-16",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "666",
    sumOpenNumber: "8",
    resultDate: "2023-01-17",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2023-01-18",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "244",
    sumOpenNumber: "0",
    resultDate: "2023-01-19",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "567",
    sumOpenNumber: "8",
    resultDate: "2023-01-20",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "289",
    sumOpenNumber: "9",
    resultDate: "2023-01-21",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "478",
    sumOpenNumber: "9",
    resultDate: "2023-01-22",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "449",
    sumOpenNumber: "7",
    resultDate: "2023-01-23",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "377",
    sumOpenNumber: "7",
    resultDate: "2023-01-24",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "799",
    sumOpenNumber: "5",
    resultDate: "2023-01-25",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "557",
    sumOpenNumber: "7",
    resultDate: "2023-01-26",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "447",
    sumOpenNumber: "5",
    resultDate: "2023-01-27",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "499",
    sumOpenNumber: "2",
    resultDate: "2023-01-28",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2023-01-29",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-01-30",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "489",
    sumOpenNumber: "1",
    resultDate: "2023-01-31",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2023-02-01",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2023-02-02",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2023-02-03",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-02-04",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-02-05",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-02-06",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-02-07",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "166",
    sumOpenNumber: "3",
    resultDate: "2023-02-08",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2023-02-09",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2023-02-10",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "146",
    sumOpenNumber: "1",
    resultDate: "2023-02-11",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "444",
    sumOpenNumber: "2",
    resultDate: "2023-02-12",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "449",
    sumOpenNumber: "7",
    resultDate: "2023-02-13",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2023-02-14",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2023-02-15",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "227",
    sumOpenNumber: "1",
    resultDate: "2023-02-16",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2023-02-17",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2023-02-18",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "337",
    sumOpenNumber: "3",
    resultDate: "2023-02-19",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-02-20",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "580",
    sumOpenNumber: "3",
    resultDate: "2023-02-21",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2023-02-22",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-02-23",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-02-24",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2023-02-25",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-02-26",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "770",
    sumOpenNumber: "4",
    resultDate: "2023-02-27",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2023-02-28",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2023-03-01",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "559",
    sumOpenNumber: "9",
    resultDate: "2023-03-02",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "229",
    sumOpenNumber: "3",
    resultDate: "2023-03-03",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "288",
    sumOpenNumber: "8",
    resultDate: "2023-03-04",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "779",
    sumOpenNumber: "3",
    resultDate: "2023-03-05",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2023-03-06",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "339",
    sumOpenNumber: "5",
    resultDate: "2023-03-07",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2023-03-08",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2023-03-09",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2023-03-10",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "499",
    sumOpenNumber: "2",
    resultDate: "2023-03-11",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-03-12",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "444",
    sumOpenNumber: "2",
    resultDate: "2023-03-16",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "778",
    sumOpenNumber: "2",
    resultDate: "2023-03-17",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "790",
    sumOpenNumber: "6",
    resultDate: "2023-03-18",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-03-19",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-03-20",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "457",
    sumOpenNumber: "6",
    resultDate: "2023-03-21",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-03-22",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2023-03-23",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-03-24",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "557",
    sumOpenNumber: "7",
    resultDate: "2023-03-25",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "222",
    sumOpenNumber: "6",
    resultDate: "2023-03-26",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "266",
    sumOpenNumber: "4",
    resultDate: "2023-03-27",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "889",
    sumOpenNumber: "5",
    resultDate: "2023-03-28",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2023-03-29",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "570",
    sumOpenNumber: "2",
    resultDate: "2023-03-30",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-03-31",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-04-01",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "677",
    sumOpenNumber: "0",
    resultDate: "2023-04-02",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "470",
    sumOpenNumber: "1",
    resultDate: "2023-04-03",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2023-04-04",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "770",
    sumOpenNumber: "4",
    resultDate: "2023-04-05",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "114",
    sumOpenNumber: "6",
    resultDate: "2023-04-06",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2023-04-07",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-04-08",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "449",
    sumOpenNumber: "7",
    resultDate: "2023-04-09",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "478",
    sumOpenNumber: "9",
    resultDate: "2023-04-10",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-04-11",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "566",
    sumOpenNumber: "7",
    resultDate: "2023-04-12",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2023-04-13",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "299",
    sumOpenNumber: "0",
    resultDate: "2023-04-14",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "499",
    sumOpenNumber: "2",
    resultDate: "2023-04-15",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "134",
    sumOpenNumber: "8",
    resultDate: "2023-04-16",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "344",
    sumOpenNumber: "1",
    resultDate: "2023-04-17",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "112",
    sumOpenNumber: "4",
    resultDate: "2023-04-18",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "577",
    sumOpenNumber: "9",
    resultDate: "2023-04-19",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "899",
    sumOpenNumber: "6",
    resultDate: "2023-04-20",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "479",
    sumOpenNumber: "0",
    resultDate: "2023-04-21",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "449",
    sumOpenNumber: "7",
    resultDate: "2023-04-22",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2023-04-23",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2023-04-24",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "444",
    sumOpenNumber: "2",
    resultDate: "2023-04-25",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-04-26",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2023-04-27",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2023-04-28",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "344",
    sumOpenNumber: "1",
    resultDate: "2023-04-29",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "299",
    sumOpenNumber: "0",
    resultDate: "2023-04-30",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "679",
    sumOpenNumber: "2",
    resultDate: "2023-05-01",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "266",
    sumOpenNumber: "4",
    resultDate: "2023-05-02",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "117",
    sumOpenNumber: "9",
    resultDate: "2023-05-03",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-05-04",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "799",
    sumOpenNumber: "5",
    resultDate: "2023-05-05",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-05-06",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-05-07",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "668",
    sumOpenNumber: "0",
    resultDate: "2023-05-08",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "111",
    sumOpenNumber: "3",
    resultDate: "2023-05-09",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-05-10",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-05-11",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-05-12",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2023-05-13",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "228",
    sumOpenNumber: "2",
    resultDate: "2023-05-14",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-05-15",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "289",
    sumOpenNumber: "9",
    resultDate: "2023-05-16",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "277",
    sumOpenNumber: "6",
    resultDate: "2023-05-17",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2023-05-18",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "479",
    sumOpenNumber: "0",
    resultDate: "2023-05-19",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "335",
    sumOpenNumber: "1",
    resultDate: "2023-05-20",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "339",
    sumOpenNumber: "5",
    resultDate: "2023-05-21",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2023-05-22",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2023-05-23",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "477",
    sumOpenNumber: "8",
    resultDate: "2023-05-24",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "444",
    sumOpenNumber: "2",
    resultDate: "2023-05-25",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "999",
    sumOpenNumber: "7",
    resultDate: "2023-05-26",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "338",
    sumOpenNumber: "4",
    resultDate: "2023-05-27",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "233",
    sumOpenNumber: "8",
    resultDate: "2023-05-28",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "599",
    sumOpenNumber: "3",
    resultDate: "2023-05-29",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "227",
    sumOpenNumber: "1",
    resultDate: "2023-05-30",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "770",
    sumOpenNumber: "4",
    resultDate: "2023-05-31",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2023-06-01",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2023-06-02",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2023-06-03",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2023-06-04",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-06-05",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "447",
    sumOpenNumber: "5",
    resultDate: "2023-06-06",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "225",
    sumOpenNumber: "9",
    resultDate: "2023-06-07",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "577",
    sumOpenNumber: "9",
    resultDate: "2023-06-08",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "390",
    sumOpenNumber: "2",
    resultDate: "2023-06-09",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "470",
    sumOpenNumber: "1",
    resultDate: "2023-06-10",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "899",
    sumOpenNumber: "6",
    resultDate: "2023-06-11",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "444",
    sumOpenNumber: "2",
    resultDate: "2023-06-12",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "233",
    sumOpenNumber: "8",
    resultDate: "2023-06-13",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "339",
    sumOpenNumber: "5",
    resultDate: "2023-06-14",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2023-06-15",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2023-06-16",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "668",
    sumOpenNumber: "0",
    resultDate: "2023-06-17",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "166",
    sumOpenNumber: "3",
    resultDate: "2023-06-18",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2023-06-19",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2023-06-20",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "199",
    sumOpenNumber: "9",
    resultDate: "2023-06-21",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2023-06-22",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-06-23",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "369",
    sumOpenNumber: "8",
    resultDate: "2023-06-24",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "338",
    sumOpenNumber: "4",
    resultDate: "2023-06-25",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "479",
    sumOpenNumber: "0",
    resultDate: "2023-06-26",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "148",
    sumOpenNumber: "3",
    resultDate: "2023-06-27",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "344",
    sumOpenNumber: "1",
    resultDate: "2023-06-28",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "268",
    sumOpenNumber: "6",
    resultDate: "2023-06-29",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "366",
    sumOpenNumber: "5",
    resultDate: "2023-06-30",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2023-07-01",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2023-07-02",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "445",
    sumOpenNumber: "3",
    resultDate: "2023-07-03",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-07-04",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "580",
    sumOpenNumber: "3",
    resultDate: "2023-07-05",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "680",
    sumOpenNumber: "4",
    resultDate: "2023-07-06",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "390",
    sumOpenNumber: "2",
    resultDate: "2023-07-07",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "114",
    sumOpenNumber: "6",
    resultDate: "2023-07-08",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "267",
    sumOpenNumber: "5",
    resultDate: "2023-07-09",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "778",
    sumOpenNumber: "2",
    resultDate: "2023-07-10",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "116",
    sumOpenNumber: "8",
    resultDate: "2023-07-11",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2023-07-12",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "577",
    sumOpenNumber: "9",
    resultDate: "2023-07-13",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2023-07-14",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2023-07-15",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "457",
    sumOpenNumber: "6",
    resultDate: "2023-07-16",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "223",
    sumOpenNumber: "7",
    resultDate: "2023-07-17",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "477",
    sumOpenNumber: "8",
    resultDate: "2023-07-18",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2023-07-19",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "447",
    sumOpenNumber: "5",
    resultDate: "2023-07-20",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "499",
    sumOpenNumber: "2",
    resultDate: "2023-07-21",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "266",
    sumOpenNumber: "4",
    resultDate: "2023-07-22",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "668",
    sumOpenNumber: "0",
    resultDate: "2023-07-23",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "490",
    sumOpenNumber: "3",
    resultDate: "2023-07-24",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "113",
    sumOpenNumber: "5",
    resultDate: "2023-07-25",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2023-07-26",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2023-07-27",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "289",
    sumOpenNumber: "9",
    resultDate: "2023-07-28",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "359",
    sumOpenNumber: "7",
    resultDate: "2023-07-29",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-07-30",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "137",
    sumOpenNumber: "1",
    resultDate: "2023-07-31",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "558",
    sumOpenNumber: "8",
    resultDate: "2023-08-01",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "688",
    sumOpenNumber: "2",
    resultDate: "2023-08-02",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2023-08-03",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2023-08-04",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "167",
    sumOpenNumber: "4",
    resultDate: "2023-08-05",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2023-08-06",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2023-08-07",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "234",
    sumOpenNumber: "9",
    resultDate: "2023-08-08",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "448",
    sumOpenNumber: "0",
    resultDate: "2023-08-09",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "489",
    sumOpenNumber: "1",
    resultDate: "2023-08-10",
  },
  {
    MarketTime: "12:00:00 PM",
    openNumber: "567",
    sumOpenNumber: "8",
    resultDate: "2023-08-11",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2021-09-07",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "199",
    sumOpenNumber: "9",
    resultDate: "2021-09-08",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-09-09",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "267",
    sumOpenNumber: "5",
    resultDate: "2021-09-10",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "127",
    sumOpenNumber: "0",
    resultDate: "2021-09-11",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-09-12",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-09-13",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "236",
    sumOpenNumber: "1",
    resultDate: "2021-09-14",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2021-09-15",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-09-16",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2021-09-17",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2021-09-18",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-09-19",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "570",
    sumOpenNumber: "2",
    resultDate: "2021-09-20",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "333",
    sumOpenNumber: "9",
    resultDate: "2021-09-21",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-09-22",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "167",
    sumOpenNumber: "4",
    resultDate: "2021-09-23",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2021-09-24",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-09-25",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2021-09-26",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2021-09-27",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "478",
    sumOpenNumber: "9",
    resultDate: "2021-09-28",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-09-29",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2021-09-30",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-10-01",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-10-02",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "234",
    sumOpenNumber: "9",
    resultDate: "2021-10-03",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2021-10-04",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-10-05",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "226",
    sumOpenNumber: "0",
    resultDate: "2021-10-06",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2021-10-07",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "599",
    sumOpenNumber: "3",
    resultDate: "2021-10-08",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2021-10-09",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2021-10-10",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "333",
    sumOpenNumber: "9",
    resultDate: "2021-10-11",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2021-10-12",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2021-10-13",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "117",
    sumOpenNumber: "9",
    resultDate: "2021-10-14",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2021-10-15",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2021-10-16",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2021-10-17",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "148",
    sumOpenNumber: "3",
    resultDate: "2021-10-18",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "444",
    sumOpenNumber: "2",
    resultDate: "2021-10-19",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2021-10-20",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2021-10-21",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "335",
    sumOpenNumber: "1",
    resultDate: "2021-10-22",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "568",
    sumOpenNumber: "9",
    resultDate: "2021-10-23",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-10-24",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-10-25",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2021-10-26",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-10-27",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2021-10-28",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-10-29",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-10-30",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2021-10-31",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "118",
    sumOpenNumber: "0",
    resultDate: "2021-11-01",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2021-11-02",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-11-03",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-11-04",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2021-11-05",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2021-11-06",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-11-07",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2021-11-08",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2021-11-09",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2021-11-10",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-11-11",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-11-12",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-11-13",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-11-14",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-11-15",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-11-16",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2021-11-17",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-11-18",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2021-11-19",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2021-11-20",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "166",
    sumOpenNumber: "3",
    resultDate: "2021-11-21",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2021-11-22",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-11-23",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2021-11-24",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "229",
    sumOpenNumber: "3",
    resultDate: "2021-11-25",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2021-11-26",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2021-11-27",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-11-28",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2021-11-29",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-11-30",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-12-01",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "666",
    sumOpenNumber: "8",
    resultDate: "2021-12-02",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "166",
    sumOpenNumber: "3",
    resultDate: "2021-12-03",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-12-04",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2021-12-05",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2021-12-06",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2021-12-07",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-12-08",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-12-09",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2021-12-10",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "248",
    sumOpenNumber: "4",
    resultDate: "2021-12-11",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2021-12-12",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "388",
    sumOpenNumber: "9",
    resultDate: "2021-12-13",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2021-12-14",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "368",
    sumOpenNumber: "7",
    resultDate: "2021-12-15",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2021-12-16",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "229",
    sumOpenNumber: "3",
    resultDate: "2021-12-17",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2021-12-18",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "369",
    sumOpenNumber: "8",
    resultDate: "2021-12-19",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "138",
    sumOpenNumber: "2",
    resultDate: "2021-12-20",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "990",
    sumOpenNumber: "8",
    resultDate: "2021-12-21",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2021-12-22",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2021-12-23",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2021-12-24",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "346",
    sumOpenNumber: "3",
    resultDate: "2021-12-25",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2021-12-26",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2021-12-27",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "157",
    sumOpenNumber: "3",
    resultDate: "2021-12-28",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2021-12-29",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2021-12-30",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "558",
    sumOpenNumber: "8",
    resultDate: "2021-12-31",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "269",
    sumOpenNumber: "7",
    resultDate: "2022-01-01",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2022-01-02",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2022-01-03",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "490",
    sumOpenNumber: "3",
    resultDate: "2022-01-04",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2022-01-05",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "558",
    sumOpenNumber: "8",
    resultDate: "2022-01-06",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-01-07",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "335",
    sumOpenNumber: "1",
    resultDate: "2022-01-08",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2022-01-09",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-01-10",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2022-01-11",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2022-01-12",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "233",
    sumOpenNumber: "8",
    resultDate: "2022-01-13",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-01-14",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "566",
    sumOpenNumber: "7",
    resultDate: "2022-01-15",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "556",
    sumOpenNumber: "6",
    resultDate: "2022-01-16",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "188",
    sumOpenNumber: "7",
    resultDate: "2022-01-17",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-01-18",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2022-01-19",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2022-01-20",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "688",
    sumOpenNumber: "2",
    resultDate: "2022-01-21",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2022-01-22",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2022-01-23",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2022-01-24",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2022-01-25",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2022-01-26",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2022-01-27",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-01-28",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "278",
    sumOpenNumber: "7",
    resultDate: "2022-01-29",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-01-30",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-01-31",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-02-01",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-02-02",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "270",
    sumOpenNumber: "9",
    resultDate: "2022-02-03",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "348",
    sumOpenNumber: "5",
    resultDate: "2022-02-04",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "567",
    sumOpenNumber: "8",
    resultDate: "2022-02-05",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "227",
    sumOpenNumber: "1",
    resultDate: "2022-02-06",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2022-02-07",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2022-02-08",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "455",
    sumOpenNumber: "4",
    resultDate: "2022-02-09",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2022-02-10",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2022-02-11",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2022-02-12",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2022-02-13",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-02-14",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-02-15",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2022-02-16",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "555",
    sumOpenNumber: "5",
    resultDate: "2022-02-17",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2022-02-18",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "559",
    sumOpenNumber: "9",
    resultDate: "2022-02-19",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-02-20",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "567",
    sumOpenNumber: "8",
    resultDate: "2022-02-21",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2022-02-22",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "348",
    sumOpenNumber: "5",
    resultDate: "2022-02-23",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2022-02-24",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2022-02-25",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2022-02-26",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-02-27",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "388",
    sumOpenNumber: "9",
    resultDate: "2022-02-28",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "399",
    sumOpenNumber: "1",
    resultDate: "2022-03-01",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "188",
    sumOpenNumber: "7",
    resultDate: "2022-03-02",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "127",
    sumOpenNumber: "0",
    resultDate: "2022-03-03",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "689",
    sumOpenNumber: "3",
    resultDate: "2022-03-04",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "470",
    sumOpenNumber: "1",
    resultDate: "2022-03-05",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "117",
    sumOpenNumber: "9",
    resultDate: "2022-03-06",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2022-03-07",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-03-08",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2022-03-09",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "469",
    sumOpenNumber: "9",
    resultDate: "2022-03-10",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2022-03-11",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2022-03-12",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "335",
    sumOpenNumber: "1",
    resultDate: "2022-03-13",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "225",
    sumOpenNumber: "9",
    resultDate: "2022-03-14",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "115",
    sumOpenNumber: "7",
    resultDate: "2022-03-15",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "339",
    sumOpenNumber: "5",
    resultDate: "2022-03-16",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2022-03-17",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2022-03-18",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2022-03-19",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "778",
    sumOpenNumber: "2",
    resultDate: "2022-03-20",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-03-21",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2022-03-22",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "349",
    sumOpenNumber: "6",
    resultDate: "2022-03-23",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "279",
    sumOpenNumber: "8",
    resultDate: "2022-03-24",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "227",
    sumOpenNumber: "1",
    resultDate: "2022-03-25",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2022-03-26",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2022-03-27",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "990",
    sumOpenNumber: "8",
    resultDate: "2022-03-28",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2022-03-29",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-03-30",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2022-03-31",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "136",
    sumOpenNumber: "0",
    resultDate: "2022-04-01",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-04-02",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "890",
    sumOpenNumber: "7",
    resultDate: "2022-04-03",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-04-04",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2022-04-05",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "479",
    sumOpenNumber: "0",
    resultDate: "2022-04-06",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "346",
    sumOpenNumber: "3",
    resultDate: "2022-04-07",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2022-04-08",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "580",
    sumOpenNumber: "3",
    resultDate: "2022-04-09",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "570",
    sumOpenNumber: "2",
    resultDate: "2022-04-10",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "557",
    sumOpenNumber: "7",
    resultDate: "2022-04-11",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "346",
    sumOpenNumber: "3",
    resultDate: "2022-04-12",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "478",
    sumOpenNumber: "9",
    resultDate: "2022-04-13",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-04-14",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2022-04-15",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2022-04-16",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2022-04-17",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "399",
    sumOpenNumber: "1",
    resultDate: "2022-04-18",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2022-04-19",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "589",
    sumOpenNumber: "2",
    resultDate: "2022-04-20",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "790",
    sumOpenNumber: "6",
    resultDate: "2022-04-21",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2022-04-22",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "447",
    sumOpenNumber: "5",
    resultDate: "2022-04-23",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-04-24",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-04-25",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "388",
    sumOpenNumber: "9",
    resultDate: "2022-04-26",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "338",
    sumOpenNumber: "4",
    resultDate: "2022-04-27",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "112",
    sumOpenNumber: "4",
    resultDate: "2022-04-28",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "369",
    sumOpenNumber: "8",
    resultDate: "2022-04-29",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-04-30",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-05-01",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2022-05-02",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2022-05-03",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "128",
    sumOpenNumber: "1",
    resultDate: "2022-05-04",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2022-05-05",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2022-05-06",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "589",
    sumOpenNumber: "2",
    resultDate: "2022-05-07",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2022-05-08",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-05-09",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2022-05-10",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "289",
    sumOpenNumber: "9",
    resultDate: "2022-05-11",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "334",
    sumOpenNumber: "0",
    resultDate: "2022-05-12",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "239",
    sumOpenNumber: "4",
    resultDate: "2022-05-13",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2022-05-14",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2022-05-15",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-05-16",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2022-05-17",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "112",
    sumOpenNumber: "4",
    resultDate: "2022-05-18",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-05-19",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "679",
    sumOpenNumber: "2",
    resultDate: "2022-05-20",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "689",
    sumOpenNumber: "3",
    resultDate: "2022-05-21",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-05-22",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "368",
    sumOpenNumber: "7",
    resultDate: "2022-05-23",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-05-24",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "570",
    sumOpenNumber: "2",
    resultDate: "2022-05-25",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2022-05-26",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2022-05-27",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "580",
    sumOpenNumber: "3",
    resultDate: "2022-05-28",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-05-29",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-05-30",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "890",
    sumOpenNumber: "7",
    resultDate: "2022-05-31",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "268",
    sumOpenNumber: "6",
    resultDate: "2022-06-01",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "269",
    sumOpenNumber: "7",
    resultDate: "2022-06-02",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2022-06-03",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2022-06-04",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "278",
    sumOpenNumber: "7",
    resultDate: "2022-06-05",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2022-06-06",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "228",
    sumOpenNumber: "2",
    resultDate: "2022-06-07",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "890",
    sumOpenNumber: "7",
    resultDate: "2022-06-08",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-06-09",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-06-10",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "577",
    sumOpenNumber: "9",
    resultDate: "2022-06-11",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "579",
    sumOpenNumber: "1",
    resultDate: "2022-06-12",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "157",
    sumOpenNumber: "3",
    resultDate: "2022-06-13",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-06-14",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2022-06-15",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2022-06-16",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2022-06-17",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "267",
    sumOpenNumber: "5",
    resultDate: "2022-06-18",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-06-19",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2022-06-20",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-06-21",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "338",
    sumOpenNumber: "4",
    resultDate: "2022-06-22",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "138",
    sumOpenNumber: "2",
    resultDate: "2022-06-23",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2022-06-24",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2022-06-25",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-06-26",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2022-06-27",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "770",
    sumOpenNumber: "4",
    resultDate: "2022-06-28",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2022-06-29",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-06-30",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2022-07-01",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2022-07-02",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "114",
    sumOpenNumber: "6",
    resultDate: "2022-07-03",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "790",
    sumOpenNumber: "6",
    resultDate: "2022-07-04",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2022-07-05",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2022-07-06",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "690",
    sumOpenNumber: "5",
    resultDate: "2022-07-07",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "570",
    sumOpenNumber: "2",
    resultDate: "2022-07-08",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "239",
    sumOpenNumber: "4",
    resultDate: "2022-07-09",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "238",
    sumOpenNumber: "3",
    resultDate: "2022-07-10",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "126",
    sumOpenNumber: "9",
    resultDate: "2022-07-11",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2022-07-12",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "457",
    sumOpenNumber: "6",
    resultDate: "2022-07-13",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "289",
    sumOpenNumber: "9",
    resultDate: "2022-07-14",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-07-15",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2022-07-16",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "126",
    sumOpenNumber: "9",
    resultDate: "2022-07-17",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2022-07-18",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-07-19",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2022-07-20",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "588",
    sumOpenNumber: "1",
    resultDate: "2022-07-21",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-07-22",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2022-07-23",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "227",
    sumOpenNumber: "1",
    resultDate: "2022-07-24",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-07-25",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "244",
    sumOpenNumber: "0",
    resultDate: "2022-07-26",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-07-27",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "455",
    sumOpenNumber: "4",
    resultDate: "2022-07-28",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2022-07-29",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "356",
    sumOpenNumber: "4",
    resultDate: "2022-07-30",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "359",
    sumOpenNumber: "7",
    resultDate: "2022-07-31",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "156",
    sumOpenNumber: "2",
    resultDate: "2022-08-01",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "557",
    sumOpenNumber: "7",
    resultDate: "2022-08-02",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-08-03",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-08-04",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2022-08-05",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "234",
    sumOpenNumber: "9",
    resultDate: "2022-08-06",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2022-08-07",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-08-08",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "479",
    sumOpenNumber: "0",
    resultDate: "2022-08-09",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2022-08-10",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "367",
    sumOpenNumber: "6",
    resultDate: "2022-08-11",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2022-08-12",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "238",
    sumOpenNumber: "3",
    resultDate: "2022-08-13",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "269",
    sumOpenNumber: "7",
    resultDate: "2022-08-14",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2022-08-15",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-08-16",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2022-08-17",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-08-18",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "279",
    sumOpenNumber: "8",
    resultDate: "2022-08-19",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-08-20",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2022-08-21",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2022-08-22",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2022-08-23",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2022-08-24",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "338",
    sumOpenNumber: "4",
    resultDate: "2022-08-25",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "470",
    sumOpenNumber: "1",
    resultDate: "2022-08-26",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "188",
    sumOpenNumber: "7",
    resultDate: "2022-08-27",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2022-08-28",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2022-08-29",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "145",
    sumOpenNumber: "0",
    resultDate: "2022-08-30",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-08-31",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "556",
    sumOpenNumber: "6",
    resultDate: "2022-09-01",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "378",
    sumOpenNumber: "8",
    resultDate: "2022-09-02",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2022-09-03",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "556",
    sumOpenNumber: "6",
    resultDate: "2022-09-04",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2022-09-05",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2022-09-06",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2022-09-07",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-09-08",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-09-09",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-09-10",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2022-09-11",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2022-09-12",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2022-09-13",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2022-09-14",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2022-09-15",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-09-16",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-09-17",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-09-18",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-09-19",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2022-09-20",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2022-09-21",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-09-22",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-09-23",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-09-24",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2022-09-25",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "126",
    sumOpenNumber: "9",
    resultDate: "2022-09-26",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-09-27",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2022-09-28",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "346",
    sumOpenNumber: "3",
    resultDate: "2022-09-29",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-09-30",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "390",
    sumOpenNumber: "2",
    resultDate: "2022-10-01",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2022-10-02",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2022-10-03",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-10-04",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2022-10-05",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-10-06",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2022-10-07",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2022-10-08",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "238",
    sumOpenNumber: "3",
    resultDate: "2022-10-09",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "268",
    sumOpenNumber: "6",
    resultDate: "2022-10-10",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2022-10-11",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "167",
    sumOpenNumber: "4",
    resultDate: "2022-10-12",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "234",
    sumOpenNumber: "9",
    resultDate: "2022-10-13",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2022-10-14",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2022-10-15",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2022-10-16",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-10-17",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "470",
    sumOpenNumber: "1",
    resultDate: "2022-10-18",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "199",
    sumOpenNumber: "9",
    resultDate: "2022-10-19",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-10-20",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-10-21",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2022-10-22",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "157",
    sumOpenNumber: "3",
    resultDate: "2022-10-23",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-10-24",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2022-10-25",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-10-26",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "234",
    sumOpenNumber: "9",
    resultDate: "2022-10-27",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "117",
    sumOpenNumber: "9",
    resultDate: "2022-10-28",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "127",
    sumOpenNumber: "0",
    resultDate: "2022-10-29",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2022-10-30",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2022-10-31",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2022-11-01",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2022-11-02",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-11-03",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2022-11-04",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "137",
    sumOpenNumber: "1",
    resultDate: "2022-11-05",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "147",
    sumOpenNumber: "2",
    resultDate: "2022-11-06",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-11-07",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "349",
    sumOpenNumber: "6",
    resultDate: "2022-11-08",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "558",
    sumOpenNumber: "8",
    resultDate: "2022-11-09",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "580",
    sumOpenNumber: "3",
    resultDate: "2022-11-10",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2022-11-11",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-11-12",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2022-11-13",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-11-14",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-11-15",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "677",
    sumOpenNumber: "0",
    resultDate: "2022-11-16",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "127",
    sumOpenNumber: "0",
    resultDate: "2022-11-17",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2022-11-18",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "346",
    sumOpenNumber: "3",
    resultDate: "2022-11-19",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-11-20",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2022-11-21",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2022-11-22",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "137",
    sumOpenNumber: "1",
    resultDate: "2022-11-23",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-11-24",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2022-11-25",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-11-26",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "570",
    sumOpenNumber: "2",
    resultDate: "2022-11-27",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2022-11-28",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2022-11-29",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2022-11-30",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-12-01",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2022-12-02",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "148",
    sumOpenNumber: "3",
    resultDate: "2022-12-03",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2022-12-04",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2022-12-05",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "377",
    sumOpenNumber: "7",
    resultDate: "2022-12-06",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-12-07",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "148",
    sumOpenNumber: "3",
    resultDate: "2022-12-08",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "112",
    sumOpenNumber: "4",
    resultDate: "2022-12-09",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-12-10",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "690",
    sumOpenNumber: "5",
    resultDate: "2022-12-11",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "147",
    sumOpenNumber: "2",
    resultDate: "2022-12-12",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2022-12-13",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "356",
    sumOpenNumber: "4",
    resultDate: "2022-12-14",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "590",
    sumOpenNumber: "4",
    resultDate: "2022-12-15",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2022-12-16",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "590",
    sumOpenNumber: "4",
    resultDate: "2022-12-17",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-12-18",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2022-12-19",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "114",
    sumOpenNumber: "6",
    resultDate: "2022-12-20",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2022-12-21",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-12-22",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-12-23",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-12-24",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2022-12-25",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "799",
    sumOpenNumber: "5",
    resultDate: "2022-12-26",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2022-12-27",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-12-28",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2022-12-29",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "447",
    sumOpenNumber: "5",
    resultDate: "2022-12-30",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "599",
    sumOpenNumber: "3",
    resultDate: "2022-12-31",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2023-01-01",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2023-01-02",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "268",
    sumOpenNumber: "6",
    resultDate: "2023-01-03",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2023-01-04",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "233",
    sumOpenNumber: "8",
    resultDate: "2023-01-05",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "770",
    sumOpenNumber: "4",
    resultDate: "2023-01-06",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "690",
    sumOpenNumber: "5",
    resultDate: "2023-01-07",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2023-01-08",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "233",
    sumOpenNumber: "8",
    resultDate: "2023-01-09",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2023-01-10",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2023-01-11",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-01-12",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2023-01-13",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-01-14",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "448",
    sumOpenNumber: "6",
    resultDate: "2023-01-15",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "226",
    sumOpenNumber: "0",
    resultDate: "2023-01-16",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "368",
    sumOpenNumber: "7",
    resultDate: "2023-01-17",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "222",
    sumOpenNumber: "6",
    resultDate: "2023-01-18",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "188",
    sumOpenNumber: "7",
    resultDate: "2023-01-19",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "688",
    sumOpenNumber: "2",
    resultDate: "2023-01-20",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2023-01-21",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "469",
    sumOpenNumber: "9",
    resultDate: "2023-01-22",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2023-01-23",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2023-01-24",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "338",
    sumOpenNumber: "4",
    resultDate: "2023-01-25",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "588",
    sumOpenNumber: "1",
    resultDate: "2023-01-26",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-01-27",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2023-01-28",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "248",
    sumOpenNumber: "4",
    resultDate: "2023-01-29",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "557",
    sumOpenNumber: "7",
    resultDate: "2023-01-30",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "146",
    sumOpenNumber: "1",
    resultDate: "2023-01-31",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "566",
    sumOpenNumber: "7",
    resultDate: "2023-02-01",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2023-02-02",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "229",
    sumOpenNumber: "3",
    resultDate: "2023-02-03",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "289",
    sumOpenNumber: "9",
    resultDate: "2023-02-04",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-02-05",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2023-02-06",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-02-07",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "447",
    sumOpenNumber: "5",
    resultDate: "2023-02-08",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "227",
    sumOpenNumber: "1",
    resultDate: "2023-02-09",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2023-02-10",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "178",
    sumOpenNumber: "6",
    resultDate: "2023-02-11",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-02-12",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2023-02-13",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2023-02-14",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-02-15",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-02-16",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2023-02-17",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "990",
    sumOpenNumber: "8",
    resultDate: "2023-02-18",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "248",
    sumOpenNumber: "4",
    resultDate: "2023-02-19",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-02-20",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2023-02-21",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-02-22",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-02-23",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "557",
    sumOpenNumber: "7",
    resultDate: "2023-02-24",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-02-25",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "455",
    sumOpenNumber: "4",
    resultDate: "2023-02-26",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-02-27",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2023-02-28",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "268",
    sumOpenNumber: "6",
    resultDate: "2023-03-01",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "118",
    sumOpenNumber: "0",
    resultDate: "2023-03-02",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "117",
    sumOpenNumber: "9",
    resultDate: "2023-03-03",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2023-03-04",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "379",
    sumOpenNumber: "9",
    resultDate: "2023-03-05",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "688",
    sumOpenNumber: "2",
    resultDate: "2023-03-06",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-03-07",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-03-08",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "889",
    sumOpenNumber: "5",
    resultDate: "2023-03-09",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2023-03-10",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2023-03-11",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2023-03-12",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "999",
    sumOpenNumber: "7",
    resultDate: "2023-03-16",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-03-17",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "557",
    sumOpenNumber: "7",
    resultDate: "2023-03-18",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-03-19",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "229",
    sumOpenNumber: "3",
    resultDate: "2023-03-20",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "268",
    sumOpenNumber: "6",
    resultDate: "2023-03-21",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2023-03-22",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2023-03-23",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "477",
    sumOpenNumber: "8",
    resultDate: "2023-03-24",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-03-25",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "334",
    sumOpenNumber: "0",
    resultDate: "2023-03-26",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "388",
    sumOpenNumber: "9",
    resultDate: "2023-03-27",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2023-03-28",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2023-03-29",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2023-03-30",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2023-03-31",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-04-01",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "233",
    sumOpenNumber: "8",
    resultDate: "2023-04-02",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2023-04-03",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "999",
    sumOpenNumber: "7",
    resultDate: "2023-04-04",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "337",
    sumOpenNumber: "3",
    resultDate: "2023-04-05",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "445",
    sumOpenNumber: "3",
    resultDate: "2023-04-06",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "236",
    sumOpenNumber: "1",
    resultDate: "2023-04-07",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "226",
    sumOpenNumber: "0",
    resultDate: "2023-04-08",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "666",
    sumOpenNumber: "8",
    resultDate: "2023-04-09",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2023-04-10",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "233",
    sumOpenNumber: "8",
    resultDate: "2023-04-11",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-04-12",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "335",
    sumOpenNumber: "1",
    resultDate: "2023-04-13",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "222",
    sumOpenNumber: "6",
    resultDate: "2023-04-14",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-04-15",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2023-04-16",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "336",
    sumOpenNumber: "2",
    resultDate: "2023-04-17",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2023-04-18",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "335",
    sumOpenNumber: "1",
    resultDate: "2023-04-19",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "188",
    sumOpenNumber: "7",
    resultDate: "2023-04-20",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "457",
    sumOpenNumber: "6",
    resultDate: "2023-04-21",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2023-04-22",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "112",
    sumOpenNumber: "4",
    resultDate: "2023-04-23",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "334",
    sumOpenNumber: "0",
    resultDate: "2023-04-24",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2023-04-25",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "390",
    sumOpenNumber: "2",
    resultDate: "2023-04-26",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "799",
    sumOpenNumber: "5",
    resultDate: "2023-04-27",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2023-04-28",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2023-04-29",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "449",
    sumOpenNumber: "7",
    resultDate: "2023-04-30",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2023-05-01",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "199",
    sumOpenNumber: "9",
    resultDate: "2023-05-02",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-05-03",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-05-04",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2023-05-05",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2023-05-06",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-05-07",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-05-08",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "229",
    sumOpenNumber: "3",
    resultDate: "2023-05-09",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "668",
    sumOpenNumber: "0",
    resultDate: "2023-05-10",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "667",
    sumOpenNumber: "9",
    resultDate: "2023-05-11",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2023-05-12",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-05-13",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "223",
    sumOpenNumber: "7",
    resultDate: "2023-05-14",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "447",
    sumOpenNumber: "5",
    resultDate: "2023-05-15",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "590",
    sumOpenNumber: "4",
    resultDate: "2023-05-16",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-05-17",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2023-05-18",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "226",
    sumOpenNumber: "0",
    resultDate: "2023-05-19",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "269",
    sumOpenNumber: "7",
    resultDate: "2023-05-20",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "999",
    sumOpenNumber: "7",
    resultDate: "2023-05-21",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "111",
    sumOpenNumber: "3",
    resultDate: "2023-05-22",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-05-23",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "288",
    sumOpenNumber: "8",
    resultDate: "2023-05-24",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2023-05-25",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-05-26",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-05-27",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "489",
    sumOpenNumber: "1",
    resultDate: "2023-05-28",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "346",
    sumOpenNumber: "3",
    resultDate: "2023-05-29",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "568",
    sumOpenNumber: "9",
    resultDate: "2023-05-30",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2023-05-31",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-06-01",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2023-06-02",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "259",
    sumOpenNumber: "6",
    resultDate: "2023-06-03",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "777",
    sumOpenNumber: "1",
    resultDate: "2023-06-04",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "226",
    sumOpenNumber: "0",
    resultDate: "2023-06-05",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "228",
    sumOpenNumber: "2",
    resultDate: "2023-06-06",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "577",
    sumOpenNumber: "9",
    resultDate: "2023-06-07",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2023-06-08",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2023-06-09",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2023-06-10",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2023-06-11",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "234",
    sumOpenNumber: "9",
    resultDate: "2023-06-12",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-06-13",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-06-14",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-06-15",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "666",
    sumOpenNumber: "8",
    resultDate: "2023-06-16",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "118",
    sumOpenNumber: "0",
    resultDate: "2023-06-17",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "369",
    sumOpenNumber: "8",
    resultDate: "2023-06-18",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "344",
    sumOpenNumber: "1",
    resultDate: "2023-06-19",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2023-06-20",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "333",
    sumOpenNumber: "9",
    resultDate: "2023-06-21",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2023-06-22",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "188",
    sumOpenNumber: "7",
    resultDate: "2023-06-23",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "259",
    sumOpenNumber: "6",
    resultDate: "2023-06-24",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2023-06-25",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2023-06-26",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "266",
    sumOpenNumber: "4",
    resultDate: "2023-06-27",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-06-28",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "445",
    sumOpenNumber: "3",
    resultDate: "2023-06-29",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "567",
    sumOpenNumber: "8",
    resultDate: "2023-06-30",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "199",
    sumOpenNumber: "9",
    resultDate: "2023-07-01",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "222",
    sumOpenNumber: "6",
    resultDate: "2023-07-02",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "167",
    sumOpenNumber: "4",
    resultDate: "2023-07-03",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2023-07-04",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-07-05",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "226",
    sumOpenNumber: "0",
    resultDate: "2023-07-06",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "448",
    sumOpenNumber: "6",
    resultDate: "2023-07-07",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "377",
    sumOpenNumber: "7",
    resultDate: "2023-07-08",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "477",
    sumOpenNumber: "8",
    resultDate: "2023-07-09",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "588",
    sumOpenNumber: "1",
    resultDate: "2023-07-10",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2023-07-11",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-07-12",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2023-07-13",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-07-14",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "228",
    sumOpenNumber: "2",
    resultDate: "2023-07-15",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "225",
    sumOpenNumber: "9",
    resultDate: "2023-07-16",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-07-17",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-07-18",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-07-19",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2023-07-20",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2023-07-21",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2023-07-22",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "444",
    sumOpenNumber: "2",
    resultDate: "2023-07-23",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "118",
    sumOpenNumber: "0",
    resultDate: "2023-07-24",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "111",
    sumOpenNumber: "3",
    resultDate: "2023-07-25",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2023-07-26",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "227",
    sumOpenNumber: "1",
    resultDate: "2023-07-27",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2023-07-28",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "234",
    sumOpenNumber: "9",
    resultDate: "2023-07-29",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2023-07-30",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "279",
    sumOpenNumber: "8",
    resultDate: "2023-07-31",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "479",
    sumOpenNumber: "0",
    resultDate: "2023-08-01",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "223",
    sumOpenNumber: "7",
    resultDate: "2023-08-02",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2023-08-03",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "680",
    sumOpenNumber: "4",
    resultDate: "2023-08-04",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "689",
    sumOpenNumber: "3",
    resultDate: "2023-08-05",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "277",
    sumOpenNumber: "6",
    resultDate: "2023-08-06",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2023-08-07",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2023-08-08",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "225",
    sumOpenNumber: "9",
    resultDate: "2023-08-09",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "299",
    sumOpenNumber: "0",
    resultDate: "2023-08-10",
  },
  {
    MarketTime: "02:00:00 PM",
    openNumber: "890",
    sumOpenNumber: "7",
    resultDate: "2023-08-11",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2021-09-07",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-09-08",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2021-09-09",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2021-09-10",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2021-09-11",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2021-09-12",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "338",
    sumOpenNumber: "4",
    resultDate: "2021-09-13",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-09-14",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2021-09-15",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "478",
    sumOpenNumber: "9",
    resultDate: "2021-09-16",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "666",
    sumOpenNumber: "8",
    resultDate: "2021-09-17",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-09-18",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2021-09-19",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "469",
    sumOpenNumber: "9",
    resultDate: "2021-09-20",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2021-09-21",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "555",
    sumOpenNumber: "5",
    resultDate: "2021-09-22",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2021-09-23",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "990",
    sumOpenNumber: "8",
    resultDate: "2021-09-24",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2021-09-25",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-09-26",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "0",
    sumOpenNumber: "0",
    resultDate: "2021-09-27",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2021-09-28",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2021-09-29",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2021-09-30",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-10-01",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-10-02",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2021-10-03",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2021-10-04",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2021-10-05",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2021-10-06",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-10-07",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-10-08",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-10-09",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2021-10-10",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2021-10-11",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-10-12",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "888",
    sumOpenNumber: "4",
    resultDate: "2021-10-13",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2021-10-14",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2021-10-15",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2021-10-16",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2021-10-17",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-10-18",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2021-10-19",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "690",
    sumOpenNumber: "5",
    resultDate: "2021-10-20",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-10-21",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2021-10-22",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-10-23",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2021-10-24",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2021-10-25",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2021-10-26",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2021-10-27",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-10-28",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-10-29",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2021-10-30",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2021-10-31",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2021-11-01",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-11-02",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-11-03",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "390",
    sumOpenNumber: "2",
    resultDate: "2021-11-04",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-11-05",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-11-06",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2021-11-07",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "111",
    sumOpenNumber: "3",
    resultDate: "2021-11-08",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2021-11-09",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "222",
    sumOpenNumber: "6",
    resultDate: "2021-11-10",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2021-11-11",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "999",
    sumOpenNumber: "7",
    resultDate: "2021-11-12",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "177",
    sumOpenNumber: "5",
    resultDate: "2021-11-13",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-11-14",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-11-15",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "568",
    sumOpenNumber: "9",
    resultDate: "2021-11-16",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "113",
    sumOpenNumber: "5",
    resultDate: "2021-11-17",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2021-11-18",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-11-19",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-11-20",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2021-11-21",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-11-22",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2021-11-23",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2021-11-24",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "118",
    sumOpenNumber: "0",
    resultDate: "2021-11-25",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2021-11-26",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2021-11-27",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2021-11-28",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2021-11-29",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2021-11-30",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2021-12-01",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2021-12-02",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2021-12-03",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2021-12-04",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-12-05",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-12-06",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2021-12-07",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "244",
    sumOpenNumber: "0",
    resultDate: "2021-12-08",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-12-09",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2021-12-10",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "157",
    sumOpenNumber: "3",
    resultDate: "2021-12-11",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "378",
    sumOpenNumber: "8",
    resultDate: "2021-12-12",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "335",
    sumOpenNumber: "1",
    resultDate: "2021-12-13",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "490",
    sumOpenNumber: "3",
    resultDate: "2021-12-14",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2021-12-15",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2021-12-16",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2021-12-17",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "477",
    sumOpenNumber: "8",
    resultDate: "2021-12-18",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "599",
    sumOpenNumber: "3",
    resultDate: "2021-12-19",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2021-12-20",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2021-12-21",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2021-12-22",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-12-23",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2021-12-24",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "156",
    sumOpenNumber: "2",
    resultDate: "2021-12-25",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "367",
    sumOpenNumber: "6",
    resultDate: "2021-12-26",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "457",
    sumOpenNumber: "6",
    resultDate: "2021-12-27",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2021-12-28",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "479",
    sumOpenNumber: "0",
    resultDate: "2021-12-29",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2021-12-30",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "346",
    sumOpenNumber: "3",
    resultDate: "2021-12-31",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2022-01-01",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2022-01-02",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-01-03",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "447",
    sumOpenNumber: "5",
    resultDate: "2022-01-04",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2022-01-05",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-01-06",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "127",
    sumOpenNumber: "0",
    resultDate: "2022-01-07",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "790",
    sumOpenNumber: "6",
    resultDate: "2022-01-08",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "156",
    sumOpenNumber: "2",
    resultDate: "2022-01-09",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "378",
    sumOpenNumber: "8",
    resultDate: "2022-01-10",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "199",
    sumOpenNumber: "9",
    resultDate: "2022-01-11",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "127",
    sumOpenNumber: "0",
    resultDate: "2022-01-12",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "470",
    sumOpenNumber: "1",
    resultDate: "2022-01-13",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "890",
    sumOpenNumber: "7",
    resultDate: "2022-01-14",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2022-01-15",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "680",
    sumOpenNumber: "4",
    resultDate: "2022-01-16",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-01-17",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-01-18",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2022-01-19",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2022-01-20",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2022-01-21",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-01-22",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2022-01-23",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2022-01-24",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-01-25",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-01-26",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-01-27",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2022-01-28",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-01-29",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-01-30",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2022-01-31",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "227",
    sumOpenNumber: "1",
    resultDate: "2022-02-01",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "118",
    sumOpenNumber: "0",
    resultDate: "2022-02-02",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2022-02-03",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2022-02-04",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2022-02-05",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-02-06",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "690",
    sumOpenNumber: "5",
    resultDate: "2022-02-07",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2022-02-08",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "556",
    sumOpenNumber: "6",
    resultDate: "2022-02-09",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "116",
    sumOpenNumber: "8",
    resultDate: "2022-02-10",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2022-02-11",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "589",
    sumOpenNumber: "2",
    resultDate: "2022-02-12",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2022-02-13",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2022-02-14",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "679",
    sumOpenNumber: "2",
    resultDate: "2022-02-15",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "117",
    sumOpenNumber: "9",
    resultDate: "2022-02-16",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2022-02-17",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "278",
    sumOpenNumber: "7",
    resultDate: "2022-02-18",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "499",
    sumOpenNumber: "2",
    resultDate: "2022-02-19",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-02-20",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2022-02-21",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2022-02-22",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "367",
    sumOpenNumber: "6",
    resultDate: "2022-02-23",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2022-02-24",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "147",
    sumOpenNumber: "2",
    resultDate: "2022-02-25",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2022-02-26",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "555",
    sumOpenNumber: "5",
    resultDate: "2022-02-27",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "270",
    sumOpenNumber: "9",
    resultDate: "2022-02-28",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "689",
    sumOpenNumber: "3",
    resultDate: "2022-03-01",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "228",
    sumOpenNumber: "2",
    resultDate: "2022-03-02",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "236",
    sumOpenNumber: "1",
    resultDate: "2022-03-03",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "147",
    sumOpenNumber: "2",
    resultDate: "2022-03-04",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "178",
    sumOpenNumber: "6",
    resultDate: "2022-03-05",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-03-06",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "568",
    sumOpenNumber: "9",
    resultDate: "2022-03-07",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2022-03-08",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2022-03-09",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "890",
    sumOpenNumber: "7",
    resultDate: "2022-03-10",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "344",
    sumOpenNumber: "1",
    resultDate: "2022-03-11",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2022-03-12",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "249",
    sumOpenNumber: "5",
    resultDate: "2022-03-13",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "156",
    sumOpenNumber: "2",
    resultDate: "2022-03-14",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2022-03-15",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2022-03-16",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-03-17",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2022-03-18",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-03-19",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "567",
    sumOpenNumber: "8",
    resultDate: "2022-03-20",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2022-03-21",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "259",
    sumOpenNumber: "6",
    resultDate: "2022-03-22",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "259",
    sumOpenNumber: "6",
    resultDate: "2022-03-23",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2022-03-24",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "166",
    sumOpenNumber: "3",
    resultDate: "2022-03-25",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-03-26",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-03-27",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "259",
    sumOpenNumber: "6",
    resultDate: "2022-03-28",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-03-29",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2022-03-30",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-03-31",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-04-01",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2022-04-02",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2022-04-03",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2022-04-04",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2022-04-05",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-04-06",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "469",
    sumOpenNumber: "9",
    resultDate: "2022-04-07",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2022-04-08",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "568",
    sumOpenNumber: "9",
    resultDate: "2022-04-09",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-04-10",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2022-04-11",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-04-12",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "388",
    sumOpenNumber: "9",
    resultDate: "2022-04-13",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2022-04-15",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2022-04-16",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2022-04-17",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "388",
    sumOpenNumber: "9",
    resultDate: "2022-04-18",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-04-19",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2022-04-20",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "449",
    sumOpenNumber: "7",
    resultDate: "2022-04-21",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "126",
    sumOpenNumber: "9",
    resultDate: "2022-04-22",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "127",
    sumOpenNumber: "0",
    resultDate: "2022-04-23",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "259",
    sumOpenNumber: "6",
    resultDate: "2022-04-24",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-04-25",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2022-04-26",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2022-04-27",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-04-28",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2022-04-29",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-04-30",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2022-05-01",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2022-05-02",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2022-05-03",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-05-04",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2022-05-05",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "566",
    sumOpenNumber: "7",
    resultDate: "2022-05-06",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "558",
    sumOpenNumber: "8",
    resultDate: "2022-05-07",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2022-05-08",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "378",
    sumOpenNumber: "8",
    resultDate: "2022-05-09",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "889",
    sumOpenNumber: "5",
    resultDate: "2022-05-10",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "336",
    sumOpenNumber: "2",
    resultDate: "2022-05-11",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-05-12",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "566",
    sumOpenNumber: "7",
    resultDate: "2022-05-13",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2022-05-14",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-05-15",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-05-16",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-05-17",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "277",
    sumOpenNumber: "6",
    resultDate: "2022-05-18",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-05-19",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "369",
    sumOpenNumber: "8",
    resultDate: "2022-05-20",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "188",
    sumOpenNumber: "7",
    resultDate: "2022-05-21",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "199",
    sumOpenNumber: "9",
    resultDate: "2022-05-22",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2022-05-23",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2022-05-24",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2022-05-25",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-05-26",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-05-27",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2022-05-28",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "990",
    sumOpenNumber: "8",
    resultDate: "2022-05-29",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "790",
    sumOpenNumber: "6",
    resultDate: "2022-05-30",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2022-05-31",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-06-01",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "799",
    sumOpenNumber: "5",
    resultDate: "2022-06-02",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "799",
    sumOpenNumber: "5",
    resultDate: "2022-06-03",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "259",
    sumOpenNumber: "6",
    resultDate: "2022-06-04",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-06-05",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "689",
    sumOpenNumber: "3",
    resultDate: "2022-06-06",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-06-07",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2022-06-08",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2022-06-09",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2022-06-10",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-06-11",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2022-06-12",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "148",
    sumOpenNumber: "3",
    resultDate: "2022-06-13",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "388",
    sumOpenNumber: "9",
    resultDate: "2022-06-14",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "337",
    sumOpenNumber: "3",
    resultDate: "2022-06-15",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "489",
    sumOpenNumber: "1",
    resultDate: "2022-06-16",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "227",
    sumOpenNumber: "1",
    resultDate: "2022-06-17",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-06-18",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2022-06-19",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2022-06-20",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2022-06-21",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2022-06-22",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-06-23",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2022-06-24",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "556",
    sumOpenNumber: "6",
    resultDate: "2022-06-25",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-06-26",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-06-27",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-06-28",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2022-06-29",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2022-06-30",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2022-07-01",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "359",
    sumOpenNumber: "7",
    resultDate: "2022-07-02",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2022-07-03",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-07-04",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-07-05",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2022-07-06",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2022-07-07",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-07-08",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2022-07-09",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "590",
    sumOpenNumber: "4",
    resultDate: "2022-07-10",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2022-07-11",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2022-07-12",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-07-13",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2022-07-14",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2022-07-15",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2022-07-16",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-07-17",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2022-07-18",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "445",
    sumOpenNumber: "3",
    resultDate: "2022-07-19",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "147",
    sumOpenNumber: "2",
    resultDate: "2022-07-20",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "558",
    sumOpenNumber: "8",
    resultDate: "2022-07-21",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-07-22",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2022-07-23",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2022-07-24",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-07-25",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-07-26",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "589",
    sumOpenNumber: "2",
    resultDate: "2022-07-27",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2022-07-28",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-07-29",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2022-07-30",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "556",
    sumOpenNumber: "6",
    resultDate: "2022-07-31",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-08-01",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "579",
    sumOpenNumber: "1",
    resultDate: "2022-08-02",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2022-08-03",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2022-08-04",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "289",
    sumOpenNumber: "9",
    resultDate: "2022-08-05",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "270",
    sumOpenNumber: "9",
    resultDate: "2022-08-06",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "566",
    sumOpenNumber: "7",
    resultDate: "2022-08-07",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "137",
    sumOpenNumber: "1",
    resultDate: "2022-08-08",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2022-08-09",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-08-10",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-08-11",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-08-12",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2022-08-13",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2022-08-14",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-08-15",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-08-16",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-08-17",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2022-08-18",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-08-19",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "567",
    sumOpenNumber: "8",
    resultDate: "2022-08-20",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "223",
    sumOpenNumber: "7",
    resultDate: "2022-08-21",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "580",
    sumOpenNumber: "3",
    resultDate: "2022-08-22",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2022-08-23",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "239",
    sumOpenNumber: "4",
    resultDate: "2022-08-24",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2022-08-25",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-08-26",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2022-08-27",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2022-08-28",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-08-29",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "337",
    sumOpenNumber: "3",
    resultDate: "2022-08-30",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "469",
    sumOpenNumber: "9",
    resultDate: "2022-08-31",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-09-01",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "455",
    sumOpenNumber: "4",
    resultDate: "2022-09-02",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "469",
    sumOpenNumber: "9",
    resultDate: "2022-09-03",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2022-09-04",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "136",
    sumOpenNumber: "0",
    resultDate: "2022-09-05",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-09-06",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-09-07",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2022-09-08",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "279",
    sumOpenNumber: "8",
    resultDate: "2022-09-09",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "367",
    sumOpenNumber: "6",
    resultDate: "2022-09-10",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2022-09-11",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2022-09-12",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2022-09-13",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2022-09-14",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-09-15",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2022-09-16",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "299",
    sumOpenNumber: "0",
    resultDate: "2022-09-17",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "890",
    sumOpenNumber: "7",
    resultDate: "2022-09-18",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2022-09-19",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2022-09-20",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-09-21",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2022-09-22",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2022-09-23",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "368",
    sumOpenNumber: "7",
    resultDate: "2022-09-24",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-09-25",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "490",
    sumOpenNumber: "3",
    resultDate: "2022-09-26",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2022-09-27",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-09-28",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-09-29",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2022-09-30",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2022-10-01",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "570",
    sumOpenNumber: "2",
    resultDate: "2022-10-02",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "778",
    sumOpenNumber: "2",
    resultDate: "2022-10-03",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-10-04",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "556",
    sumOpenNumber: "6",
    resultDate: "2022-10-05",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "268",
    sumOpenNumber: "6",
    resultDate: "2022-10-06",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2022-10-07",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2022-10-08",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2022-10-09",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "229",
    sumOpenNumber: "3",
    resultDate: "2022-10-10",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2022-10-11",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-10-12",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-10-13",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-10-14",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "289",
    sumOpenNumber: "9",
    resultDate: "2022-10-15",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-10-16",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "224",
    sumOpenNumber: "8",
    resultDate: "2022-10-17",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-10-18",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "268",
    sumOpenNumber: "6",
    resultDate: "2022-10-19",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2022-10-20",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "134",
    sumOpenNumber: "8",
    resultDate: "2022-10-21",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "778",
    sumOpenNumber: "2",
    resultDate: "2022-10-22",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2022-10-23",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "146",
    sumOpenNumber: "1",
    resultDate: "2022-10-24",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "134",
    sumOpenNumber: "8",
    resultDate: "2022-10-25",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2022-10-26",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-10-27",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "146",
    sumOpenNumber: "1",
    resultDate: "2022-10-28",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "278",
    sumOpenNumber: "7",
    resultDate: "2022-10-29",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2022-10-30",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "369",
    sumOpenNumber: "8",
    resultDate: "2022-10-31",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2022-11-01",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-11-02",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-11-03",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2022-11-04",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "680",
    sumOpenNumber: "4",
    resultDate: "2022-11-05",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2022-11-06",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "580",
    sumOpenNumber: "3",
    resultDate: "2022-11-07",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "367",
    sumOpenNumber: "6",
    resultDate: "2022-11-08",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2022-11-09",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2022-11-10",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2022-11-11",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2022-11-12",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-11-13",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "234",
    sumOpenNumber: "9",
    resultDate: "2022-11-14",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2022-11-15",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "278",
    sumOpenNumber: "7",
    resultDate: "2022-11-16",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-11-17",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "457",
    sumOpenNumber: "6",
    resultDate: "2022-11-18",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2022-11-19",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2022-11-20",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-11-21",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2022-11-22",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-11-23",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2022-11-24",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-11-25",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "689",
    sumOpenNumber: "3",
    resultDate: "2022-11-26",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-11-27",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2022-11-28",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-11-29",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "489",
    sumOpenNumber: "1",
    resultDate: "2022-11-30",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-12-01",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "567",
    sumOpenNumber: "8",
    resultDate: "2022-12-02",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-12-03",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-12-04",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2022-12-05",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-12-06",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-12-07",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2022-12-08",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-12-09",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-12-10",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-12-11",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-12-12",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-12-13",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-12-14",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-12-15",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2022-12-16",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-12-17",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-12-18",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2022-12-19",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-12-20",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2022-12-21",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-12-22",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2022-12-23",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-12-24",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "270",
    sumOpenNumber: "9",
    resultDate: "2022-12-25",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-12-26",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "259",
    sumOpenNumber: "6",
    resultDate: "2022-12-27",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2022-12-28",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "299",
    sumOpenNumber: "0",
    resultDate: "2022-12-29",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2022-12-30",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-12-31",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-01-01",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-01-02",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "270",
    sumOpenNumber: "9",
    resultDate: "2023-01-03",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "377",
    sumOpenNumber: "7",
    resultDate: "2023-01-04",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-01-05",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2023-01-06",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2023-01-07",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "455",
    sumOpenNumber: "4",
    resultDate: "2023-01-08",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-01-09",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "233",
    sumOpenNumber: "8",
    resultDate: "2023-01-10",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-01-11",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-01-12",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2023-01-13",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "349",
    sumOpenNumber: "6",
    resultDate: "2023-01-14",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "117",
    sumOpenNumber: "9",
    resultDate: "2023-01-15",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2023-01-16",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "688",
    sumOpenNumber: "2",
    resultDate: "2023-01-17",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2023-01-18",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "236",
    sumOpenNumber: "1",
    resultDate: "2023-01-19",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "236",
    sumOpenNumber: "1",
    resultDate: "2023-01-20",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "777",
    sumOpenNumber: "1",
    resultDate: "2023-01-21",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2023-01-22",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "166",
    sumOpenNumber: "3",
    resultDate: "2023-01-23",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2023-01-24",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "166",
    sumOpenNumber: "3",
    resultDate: "2023-01-25",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "379",
    sumOpenNumber: "9",
    resultDate: "2023-01-26",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "269",
    sumOpenNumber: "7",
    resultDate: "2023-01-27",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "137",
    sumOpenNumber: "1",
    resultDate: "2023-01-28",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "889",
    sumOpenNumber: "5",
    resultDate: "2023-01-29",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-01-30",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "247",
    sumOpenNumber: "3",
    resultDate: "2023-01-31",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "499",
    sumOpenNumber: "2",
    resultDate: "2023-02-01",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "228",
    sumOpenNumber: "2",
    resultDate: "2023-02-02",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "990",
    sumOpenNumber: "8",
    resultDate: "2023-02-03",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2023-02-04",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-02-05",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "239",
    sumOpenNumber: "4",
    resultDate: "2023-02-06",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "188",
    sumOpenNumber: "7",
    resultDate: "2023-02-07",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-02-08",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-02-09",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "233",
    sumOpenNumber: "8",
    resultDate: "2023-02-10",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "225",
    sumOpenNumber: "9",
    resultDate: "2023-02-11",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2023-02-12",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "366",
    sumOpenNumber: "5",
    resultDate: "2023-02-13",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "229",
    sumOpenNumber: "3",
    resultDate: "2023-02-14",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2023-02-15",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2023-02-16",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "111",
    sumOpenNumber: "3",
    resultDate: "2023-02-17",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2023-02-18",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "799",
    sumOpenNumber: "5",
    resultDate: "2023-02-19",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "690",
    sumOpenNumber: "5",
    resultDate: "2023-02-20",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-02-21",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "668",
    sumOpenNumber: "0",
    resultDate: "2023-02-22",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2023-02-23",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "114",
    sumOpenNumber: "6",
    resultDate: "2023-02-24",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-02-25",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "570",
    sumOpenNumber: "2",
    resultDate: "2023-02-26",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2023-02-27",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-02-28",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2023-03-01",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "557",
    sumOpenNumber: "7",
    resultDate: "2023-03-02",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2023-03-03",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2023-03-04",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "147",
    sumOpenNumber: "2",
    resultDate: "2023-03-05",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "223",
    sumOpenNumber: "7",
    resultDate: "2023-03-06",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2023-03-07",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2023-03-08",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-03-10",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2023-03-11",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-03-12",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "488",
    sumOpenNumber: "0",
    resultDate: "2023-03-16",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "111",
    sumOpenNumber: "3",
    resultDate: "2023-03-17",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2023-03-18",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "777",
    sumOpenNumber: "1",
    resultDate: "2023-03-19",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "238",
    sumOpenNumber: "3",
    resultDate: "2023-03-20",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "778",
    sumOpenNumber: "2",
    resultDate: "2023-03-21",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "227",
    sumOpenNumber: "1",
    resultDate: "2023-03-22",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "369",
    sumOpenNumber: "8",
    resultDate: "2023-03-23",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2023-03-24",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "555",
    sumOpenNumber: "5",
    resultDate: "2023-03-25",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-03-26",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-03-27",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2023-03-28",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "223",
    sumOpenNumber: "7",
    resultDate: "2023-03-29",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "455",
    sumOpenNumber: "4",
    resultDate: "2023-03-30",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2023-03-31",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "226",
    sumOpenNumber: "0",
    resultDate: "2023-04-01",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "136",
    sumOpenNumber: "0",
    resultDate: "2023-04-02",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2023-04-03",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2023-04-04",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "333",
    sumOpenNumber: "9",
    resultDate: "2023-04-05",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2023-04-06",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "228",
    sumOpenNumber: "2",
    resultDate: "2023-04-07",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "244",
    sumOpenNumber: "0",
    resultDate: "2023-04-08",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "366",
    sumOpenNumber: "5",
    resultDate: "2023-04-09",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2023-04-10",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2023-04-11",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "379",
    sumOpenNumber: "9",
    resultDate: "2023-04-12",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-04-13",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-04-14",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2023-04-15",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-04-16",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "266",
    sumOpenNumber: "4",
    resultDate: "2023-04-17",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "336",
    sumOpenNumber: "2",
    resultDate: "2023-04-18",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "689",
    sumOpenNumber: "3",
    resultDate: "2023-04-19",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "559",
    sumOpenNumber: "9",
    resultDate: "2023-04-20",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-04-21",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "990",
    sumOpenNumber: "8",
    resultDate: "2023-04-22",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "889",
    sumOpenNumber: "5",
    resultDate: "2023-04-23",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2023-04-24",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "688",
    sumOpenNumber: "2",
    resultDate: "2023-04-25",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2023-04-26",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2023-04-27",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-04-28",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "233",
    sumOpenNumber: "8",
    resultDate: "2023-04-29",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "559",
    sumOpenNumber: "9",
    resultDate: "2023-04-30",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-05-01",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "588",
    sumOpenNumber: "1",
    resultDate: "2023-05-02",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2023-05-03",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "199",
    sumOpenNumber: "9",
    resultDate: "2023-05-04",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "339",
    sumOpenNumber: "5",
    resultDate: "2023-05-05",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "477",
    sumOpenNumber: "8",
    resultDate: "2023-05-06",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "224",
    sumOpenNumber: "8",
    resultDate: "2023-05-07",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2023-05-08",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2023-05-09",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2023-05-10",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2023-05-11",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2023-05-12",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "488",
    sumOpenNumber: "0",
    resultDate: "2023-05-13",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2023-05-14",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-05-15",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "338",
    sumOpenNumber: "4",
    resultDate: "2023-05-16",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "447",
    sumOpenNumber: "5",
    resultDate: "2023-05-17",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-05-18",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-05-19",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2023-05-20",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-05-21",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2023-05-22",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "335",
    sumOpenNumber: "1",
    resultDate: "2023-05-23",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-05-24",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "588",
    sumOpenNumber: "1",
    resultDate: "2023-05-25",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2023-05-26",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2023-05-27",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2023-05-28",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2023-05-29",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-05-30",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2023-05-31",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "336",
    sumOpenNumber: "2",
    resultDate: "2023-06-01",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-06-02",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2023-06-03",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-06-04",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-06-05",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-06-06",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-06-07",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2023-06-08",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "577",
    sumOpenNumber: "9",
    resultDate: "2023-06-09",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2023-06-10",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2023-06-11",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "557",
    sumOpenNumber: "7",
    resultDate: "2023-06-12",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-06-13",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2023-06-14",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2023-06-15",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2023-06-16",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "770",
    sumOpenNumber: "4",
    resultDate: "2023-06-17",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2023-06-18",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-06-19",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2023-06-20",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "449",
    sumOpenNumber: "7",
    resultDate: "2023-06-21",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-06-22",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "113",
    sumOpenNumber: "5",
    resultDate: "2023-06-23",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2023-06-24",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-06-25",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-06-26",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-06-27",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2023-06-28",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "688",
    sumOpenNumber: "2",
    resultDate: "2023-06-29",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-06-30",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2023-07-01",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2023-07-02",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2023-07-03",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-07-04",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-07-05",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2023-07-06",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2023-07-07",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "228",
    sumOpenNumber: "2",
    resultDate: "2023-07-08",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2023-07-09",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "457",
    sumOpenNumber: "6",
    resultDate: "2023-07-10",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-07-11",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-07-12",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "449",
    sumOpenNumber: "7",
    resultDate: "2023-07-13",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "679",
    sumOpenNumber: "2",
    resultDate: "2023-07-14",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "111",
    sumOpenNumber: "3",
    resultDate: "2023-07-15",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2023-07-16",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "990",
    sumOpenNumber: "8",
    resultDate: "2023-07-17",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "388",
    sumOpenNumber: "9",
    resultDate: "2023-07-18",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-07-19",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2023-07-20",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-07-21",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2023-07-22",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2023-07-23",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-07-24",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-07-25",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-07-26",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "339",
    sumOpenNumber: "5",
    resultDate: "2023-07-27",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "229",
    sumOpenNumber: "3",
    resultDate: "2023-07-28",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2023-07-29",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2023-07-30",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2023-07-31",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "114",
    sumOpenNumber: "6",
    resultDate: "2023-08-01",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2023-08-02",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "156",
    sumOpenNumber: "2",
    resultDate: "2023-08-03",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "146",
    sumOpenNumber: "1",
    resultDate: "2023-08-04",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "266",
    sumOpenNumber: "4",
    resultDate: "2023-08-05",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "249",
    sumOpenNumber: "5",
    resultDate: "2023-08-06",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "166",
    sumOpenNumber: "3",
    resultDate: "2023-08-07",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2023-08-08",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2023-08-09",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "225",
    sumOpenNumber: "9",
    resultDate: "2023-08-10",
  },
  {
    MarketTime: "08:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2023-08-11",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "117",
    sumOpenNumber: "9",
    resultDate: "2021-09-07",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2021-09-08",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-09-09",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "233",
    sumOpenNumber: "8",
    resultDate: "2021-09-10",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2021-09-11",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "556",
    sumOpenNumber: "6",
    resultDate: "2021-09-12",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "888",
    sumOpenNumber: "4",
    resultDate: "2021-09-13",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-09-14",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2021-09-15",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2021-09-16",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2021-09-17",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2021-09-18",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2021-09-19",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2021-09-20",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-09-21",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-09-22",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "118",
    sumOpenNumber: "0",
    resultDate: "2021-09-23",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "225",
    sumOpenNumber: "9",
    resultDate: "2021-09-24",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2021-09-25",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2021-09-26",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-09-27",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2021-09-28",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-09-29",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2021-09-30",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2021-10-01",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2021-10-02",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2021-10-03",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2021-10-04",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2021-10-05",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "117",
    sumOpenNumber: "9",
    resultDate: "2021-10-06",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-10-07",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2021-10-08",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-10-09",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2021-10-10",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2021-10-11",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2021-10-12",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2021-10-13",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-10-14",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "247",
    sumOpenNumber: "3",
    resultDate: "2021-10-15",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "999",
    sumOpenNumber: "7",
    resultDate: "2021-10-16",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "680",
    sumOpenNumber: "4",
    resultDate: "2021-10-17",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "368",
    sumOpenNumber: "7",
    resultDate: "2021-10-18",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2021-10-19",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "888",
    sumOpenNumber: "4",
    resultDate: "2021-10-20",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "168",
    sumOpenNumber: "5",
    resultDate: "2021-10-21",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-10-22",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2021-10-23",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-10-24",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2021-10-25",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "244",
    sumOpenNumber: "0",
    resultDate: "2021-10-26",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2021-10-27",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2021-10-28",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2021-10-29",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-10-30",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2021-10-31",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "147",
    sumOpenNumber: "2",
    resultDate: "2021-11-01",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2021-11-02",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2021-11-03",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-11-04",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2021-11-05",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2021-11-06",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2021-11-07",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "348",
    sumOpenNumber: "5",
    resultDate: "2021-11-08",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2021-11-09",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2021-11-10",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-11-11",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2021-11-12",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-11-13",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-11-14",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-11-15",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2021-11-16",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2021-11-17",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2021-11-18",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2021-11-19",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "118",
    sumOpenNumber: "0",
    resultDate: "2021-11-20",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-11-21",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2021-11-22",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-11-23",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2021-11-24",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "457",
    sumOpenNumber: "6",
    resultDate: "2021-11-25",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-11-26",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2021-11-27",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-11-28",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2021-11-29",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-11-30",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2021-12-01",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2021-12-02",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-12-03",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2021-12-04",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "448",
    sumOpenNumber: "6",
    resultDate: "2021-12-05",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-12-06",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-12-07",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2021-12-08",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-12-09",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2021-12-10",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "128",
    sumOpenNumber: "1",
    resultDate: "2021-12-11",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-12-12",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2021-12-13",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "136",
    sumOpenNumber: "0",
    resultDate: "2021-12-14",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2021-12-15",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "478",
    sumOpenNumber: "9",
    resultDate: "2021-12-16",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2021-12-17",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "277",
    sumOpenNumber: "6",
    resultDate: "2021-12-18",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "388",
    sumOpenNumber: "9",
    resultDate: "2021-12-19",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "148",
    sumOpenNumber: "3",
    resultDate: "2021-12-20",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "128",
    sumOpenNumber: "1",
    resultDate: "2021-12-21",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2021-12-22",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2021-12-23",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2021-12-24",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2021-12-25",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "178",
    sumOpenNumber: "6",
    resultDate: "2021-12-26",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "278",
    sumOpenNumber: "7",
    resultDate: "2021-12-27",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2021-12-28",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-12-29",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2021-12-30",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2021-12-31",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "270",
    sumOpenNumber: "9",
    resultDate: "2022-01-01",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "248",
    sumOpenNumber: "4",
    resultDate: "2022-01-02",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2022-01-03",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "138",
    sumOpenNumber: "2",
    resultDate: "2022-01-04",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2022-01-05",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2022-01-06",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2022-01-07",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "270",
    sumOpenNumber: "9",
    resultDate: "2022-01-08",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "559",
    sumOpenNumber: "9",
    resultDate: "2022-01-09",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "390",
    sumOpenNumber: "2",
    resultDate: "2022-01-10",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "556",
    sumOpenNumber: "6",
    resultDate: "2022-01-11",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2022-01-12",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "259",
    sumOpenNumber: "6",
    resultDate: "2022-01-13",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "378",
    sumOpenNumber: "8",
    resultDate: "2022-01-14",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-01-15",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "348",
    sumOpenNumber: "5",
    resultDate: "2022-01-16",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "188",
    sumOpenNumber: "7",
    resultDate: "2022-01-17",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "378",
    sumOpenNumber: "8",
    resultDate: "2022-01-18",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "566",
    sumOpenNumber: "7",
    resultDate: "2022-01-19",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2022-01-20",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-01-21",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2022-01-22",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "790",
    sumOpenNumber: "6",
    resultDate: "2022-01-23",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-01-24",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-01-25",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "890",
    sumOpenNumber: "7",
    resultDate: "2022-01-26",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2022-01-27",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "229",
    sumOpenNumber: "3",
    resultDate: "2022-01-28",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2022-01-29",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2022-01-30",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-01-31",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-02-01",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "445",
    sumOpenNumber: "3",
    resultDate: "2022-02-02",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2022-02-03",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-02-04",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "679",
    sumOpenNumber: "2",
    resultDate: "2022-02-05",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "225",
    sumOpenNumber: "9",
    resultDate: "2022-02-06",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "890",
    sumOpenNumber: "7",
    resultDate: "2022-02-07",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "168",
    sumOpenNumber: "5",
    resultDate: "2022-02-08",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "570",
    sumOpenNumber: "2",
    resultDate: "2022-02-09",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "469",
    sumOpenNumber: "9",
    resultDate: "2022-02-10",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "579",
    sumOpenNumber: "1",
    resultDate: "2022-02-11",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "588",
    sumOpenNumber: "1",
    resultDate: "2022-02-12",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2022-02-13",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2022-02-14",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-02-15",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2022-02-16",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "339",
    sumOpenNumber: "5",
    resultDate: "2022-02-17",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-02-18",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2022-02-19",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "157",
    sumOpenNumber: "3",
    resultDate: "2022-02-20",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "680",
    sumOpenNumber: "4",
    resultDate: "2022-02-21",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2022-02-22",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "567",
    sumOpenNumber: "8",
    resultDate: "2022-02-23",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2022-02-24",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-02-25",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-02-26",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-02-27",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "349",
    sumOpenNumber: "6",
    resultDate: "2022-02-28",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "690",
    sumOpenNumber: "5",
    resultDate: "2022-03-01",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "247",
    sumOpenNumber: "3",
    resultDate: "2022-03-02",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2022-03-03",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "234",
    sumOpenNumber: "9",
    resultDate: "2022-03-04",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "555",
    sumOpenNumber: "5",
    resultDate: "2022-03-05",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "790",
    sumOpenNumber: "6",
    resultDate: "2022-03-06",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "233",
    sumOpenNumber: "8",
    resultDate: "2022-03-07",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-03-08",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "690",
    sumOpenNumber: "5",
    resultDate: "2022-03-09",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "590",
    sumOpenNumber: "4",
    resultDate: "2022-03-10",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "228",
    sumOpenNumber: "2",
    resultDate: "2022-03-11",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "128",
    sumOpenNumber: "1",
    resultDate: "2022-03-12",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-03-13",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2022-03-14",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "455",
    sumOpenNumber: "4",
    resultDate: "2022-03-15",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2022-03-16",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "249",
    sumOpenNumber: "5",
    resultDate: "2022-03-17",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "378",
    sumOpenNumber: "8",
    resultDate: "2022-03-18",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2022-03-19",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "590",
    sumOpenNumber: "4",
    resultDate: "2022-03-20",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2022-03-21",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-03-22",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-03-23",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "388",
    sumOpenNumber: "9",
    resultDate: "2022-03-24",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "269",
    sumOpenNumber: "7",
    resultDate: "2022-03-25",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2022-03-26",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2022-03-27",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-03-28",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2022-03-29",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2022-03-30",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2022-03-31",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "479",
    sumOpenNumber: "0",
    resultDate: "2022-04-01",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2022-04-02",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2022-04-03",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "689",
    sumOpenNumber: "3",
    resultDate: "2022-04-04",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-04-05",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2022-04-06",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2022-04-07",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2022-04-08",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2022-04-09",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-04-10",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-04-11",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "378",
    sumOpenNumber: "8",
    resultDate: "2022-04-12",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "448",
    sumOpenNumber: "6",
    resultDate: "2022-04-13",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-04-14",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2022-04-15",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-04-16",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "334",
    sumOpenNumber: "0",
    resultDate: "2022-04-17",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2022-04-18",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2022-04-19",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2022-04-20",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "348",
    sumOpenNumber: "5",
    resultDate: "2022-04-21",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-04-22",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "268",
    sumOpenNumber: "6",
    resultDate: "2022-04-23",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "344",
    sumOpenNumber: "1",
    resultDate: "2022-04-24",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-04-25",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "336",
    sumOpenNumber: "2",
    resultDate: "2022-04-26",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-04-27",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-04-28",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2022-04-29",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2022-04-30",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2022-05-01",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-05-02",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2022-05-03",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-05-04",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2022-05-05",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2022-05-06",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2022-05-07",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "388",
    sumOpenNumber: "9",
    resultDate: "2022-05-08",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2022-05-09",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "114",
    sumOpenNumber: "6",
    resultDate: "2022-05-10",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "799",
    sumOpenNumber: "5",
    resultDate: "2022-05-11",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "379",
    sumOpenNumber: "9",
    resultDate: "2022-05-12",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "157",
    sumOpenNumber: "3",
    resultDate: "2022-05-13",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-05-14",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-05-15",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2022-05-16",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "378",
    sumOpenNumber: "8",
    resultDate: "2022-05-17",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2022-05-18",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "337",
    sumOpenNumber: "3",
    resultDate: "2022-05-19",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-05-20",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2022-05-21",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "570",
    sumOpenNumber: "2",
    resultDate: "2022-05-22",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2022-05-23",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "558",
    sumOpenNumber: "8",
    resultDate: "2022-05-24",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-05-25",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "225",
    sumOpenNumber: "9",
    resultDate: "2022-05-26",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2022-05-27",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2022-05-28",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2022-05-29",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "379",
    sumOpenNumber: "9",
    resultDate: "2022-05-30",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2022-05-31",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "888",
    sumOpenNumber: "4",
    resultDate: "2022-06-01",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-06-02",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "270",
    sumOpenNumber: "9",
    resultDate: "2022-06-03",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "667",
    sumOpenNumber: "9",
    resultDate: "2022-06-04",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "225",
    sumOpenNumber: "9",
    resultDate: "2022-06-05",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2022-06-06",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "167",
    sumOpenNumber: "4",
    resultDate: "2022-06-07",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "469",
    sumOpenNumber: "9",
    resultDate: "2022-06-08",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2022-06-09",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "359",
    sumOpenNumber: "7",
    resultDate: "2022-06-10",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2022-06-11",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2022-06-12",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "145",
    sumOpenNumber: "0",
    resultDate: "2022-06-13",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "580",
    sumOpenNumber: "3",
    resultDate: "2022-06-14",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "156",
    sumOpenNumber: "2",
    resultDate: "2022-06-15",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2022-06-16",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-06-17",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "147",
    sumOpenNumber: "2",
    resultDate: "2022-06-18",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "112",
    sumOpenNumber: "4",
    resultDate: "2022-06-19",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2022-06-20",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2022-06-21",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "118",
    sumOpenNumber: "0",
    resultDate: "2022-06-22",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-06-23",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2022-06-24",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "126",
    sumOpenNumber: "9",
    resultDate: "2022-06-25",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2022-06-26",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "338",
    sumOpenNumber: "4",
    resultDate: "2022-06-27",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "378",
    sumOpenNumber: "8",
    resultDate: "2022-06-28",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "349",
    sumOpenNumber: "6",
    resultDate: "2022-06-29",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "334",
    sumOpenNumber: "0",
    resultDate: "2022-06-30",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-07-01",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-07-02",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "126",
    sumOpenNumber: "9",
    resultDate: "2022-07-03",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-07-04",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "349",
    sumOpenNumber: "6",
    resultDate: "2022-07-05",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-07-06",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2022-07-07",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2022-07-08",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "277",
    sumOpenNumber: "6",
    resultDate: "2022-07-09",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2022-07-10",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2022-07-11",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2022-07-12",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2022-07-13",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "556",
    sumOpenNumber: "6",
    resultDate: "2022-07-14",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-07-15",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-07-16",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2022-07-17",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "117",
    sumOpenNumber: "9",
    resultDate: "2022-07-18",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "225",
    sumOpenNumber: "9",
    resultDate: "2022-07-19",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2022-07-20",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "556",
    sumOpenNumber: "6",
    resultDate: "2022-07-21",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "157",
    sumOpenNumber: "3",
    resultDate: "2022-07-22",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "590",
    sumOpenNumber: "4",
    resultDate: "2022-07-23",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "147",
    sumOpenNumber: "2",
    resultDate: "2022-07-24",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-07-25",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "335",
    sumOpenNumber: "1",
    resultDate: "2022-07-26",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2022-07-27",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2022-07-28",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "248",
    sumOpenNumber: "4",
    resultDate: "2022-07-29",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "790",
    sumOpenNumber: "6",
    resultDate: "2022-07-30",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "238",
    sumOpenNumber: "3",
    resultDate: "2022-07-31",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2022-08-01",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2022-08-02",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2022-08-03",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2022-08-04",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "227",
    sumOpenNumber: "1",
    resultDate: "2022-08-05",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2022-08-06",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "145",
    sumOpenNumber: "0",
    resultDate: "2022-08-07",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-08-08",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "568",
    sumOpenNumber: "9",
    resultDate: "2022-08-09",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "558",
    sumOpenNumber: "8",
    resultDate: "2022-08-10",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2022-08-11",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-08-12",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2022-08-13",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-08-14",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2022-08-15",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2022-08-16",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "469",
    sumOpenNumber: "9",
    resultDate: "2022-08-17",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2022-08-18",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-08-19",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "228",
    sumOpenNumber: "2",
    resultDate: "2022-08-20",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2022-08-21",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2022-08-22",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-08-23",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2022-08-24",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2022-08-25",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2022-08-26",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2022-08-27",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2022-08-28",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2022-08-29",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "137",
    sumOpenNumber: "1",
    resultDate: "2022-08-30",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2022-08-31",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2022-09-01",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "990",
    sumOpenNumber: "8",
    resultDate: "2022-09-02",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "770",
    sumOpenNumber: "4",
    resultDate: "2022-09-03",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-09-04",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "344",
    sumOpenNumber: "1",
    resultDate: "2022-09-05",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "356",
    sumOpenNumber: "4",
    resultDate: "2022-09-06",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-09-07",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2022-09-08",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-09-09",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2022-09-10",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-09-11",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-09-12",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "157",
    sumOpenNumber: "3",
    resultDate: "2022-09-13",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "568",
    sumOpenNumber: "9",
    resultDate: "2022-09-14",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2022-09-15",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "490",
    sumOpenNumber: "3",
    resultDate: "2022-09-16",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-09-17",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2022-09-18",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-09-19",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2022-09-20",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2022-09-21",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "177",
    sumOpenNumber: "5",
    resultDate: "2022-09-22",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-09-23",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "134",
    sumOpenNumber: "8",
    resultDate: "2022-09-24",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2022-09-25",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "469",
    sumOpenNumber: "9",
    resultDate: "2022-09-26",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "128",
    sumOpenNumber: "1",
    resultDate: "2022-09-27",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-09-28",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2022-09-29",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "567",
    sumOpenNumber: "8",
    resultDate: "2022-09-30",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-10-01",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-10-02",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-10-03",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-10-04",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2022-10-05",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-10-06",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-10-07",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "589",
    sumOpenNumber: "2",
    resultDate: "2022-10-08",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2022-10-09",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2022-10-10",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-10-11",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2022-10-12",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "247",
    sumOpenNumber: "3",
    resultDate: "2022-10-13",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2022-10-14",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-10-15",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-10-16",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "136",
    sumOpenNumber: "0",
    resultDate: "2022-10-17",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "334",
    sumOpenNumber: "0",
    resultDate: "2022-10-18",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2022-10-19",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2022-10-20",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-10-21",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "126",
    sumOpenNumber: "9",
    resultDate: "2022-10-22",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-10-23",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2022-10-24",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2022-10-25",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2022-10-26",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "688",
    sumOpenNumber: "2",
    resultDate: "2022-10-27",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2022-10-28",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "589",
    sumOpenNumber: "2",
    resultDate: "2022-10-29",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-10-30",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-10-31",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2022-11-01",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "680",
    sumOpenNumber: "4",
    resultDate: "2022-11-02",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "244",
    sumOpenNumber: "0",
    resultDate: "2022-11-03",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2022-11-04",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-11-05",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2022-11-06",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2022-11-07",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "579",
    sumOpenNumber: "1",
    resultDate: "2022-11-08",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "137",
    sumOpenNumber: "1",
    resultDate: "2022-11-09",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2022-11-10",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "557",
    sumOpenNumber: "7",
    resultDate: "2022-11-11",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-11-12",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-11-13",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-11-14",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2022-11-15",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "248",
    sumOpenNumber: "4",
    resultDate: "2022-11-16",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-11-17",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-11-18",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-11-19",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2022-11-20",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-11-21",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-11-22",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-11-23",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2022-11-24",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "990",
    sumOpenNumber: "8",
    resultDate: "2022-11-25",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2022-11-26",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2022-11-27",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-11-28",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-11-29",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2022-11-30",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2022-12-01",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-12-02",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2022-12-03",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2022-12-04",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2022-12-05",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-12-06",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2022-12-07",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "449",
    sumOpenNumber: "7",
    resultDate: "2022-12-08",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "447",
    sumOpenNumber: "5",
    resultDate: "2022-12-09",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2022-12-10",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "117",
    sumOpenNumber: "9",
    resultDate: "2022-12-11",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "114",
    sumOpenNumber: "6",
    resultDate: "2022-12-12",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "490",
    sumOpenNumber: "3",
    resultDate: "2022-12-13",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "889",
    sumOpenNumber: "5",
    resultDate: "2022-12-14",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "448",
    sumOpenNumber: "6",
    resultDate: "2022-12-15",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-12-16",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "112",
    sumOpenNumber: "4",
    resultDate: "2022-12-17",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2022-12-18",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-12-19",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2022-12-20",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "128",
    sumOpenNumber: "1",
    resultDate: "2022-12-21",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "567",
    sumOpenNumber: "8",
    resultDate: "2022-12-22",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2022-12-23",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "568",
    sumOpenNumber: "9",
    resultDate: "2022-12-24",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "167",
    sumOpenNumber: "4",
    resultDate: "2022-12-25",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "567",
    sumOpenNumber: "8",
    resultDate: "2022-12-26",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-12-27",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "388",
    sumOpenNumber: "9",
    resultDate: "2022-12-28",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "558",
    sumOpenNumber: "8",
    resultDate: "2022-12-29",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "266",
    sumOpenNumber: "4",
    resultDate: "2022-12-30",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "570",
    sumOpenNumber: "2",
    resultDate: "2022-12-31",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-01-01",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "490",
    sumOpenNumber: "3",
    resultDate: "2023-01-02",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2023-01-03",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2023-01-04",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2023-01-05",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "288",
    sumOpenNumber: "8",
    resultDate: "2023-01-06",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "399",
    sumOpenNumber: "1",
    resultDate: "2023-01-07",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "570",
    sumOpenNumber: "2",
    resultDate: "2023-01-08",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-01-09",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-01-10",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-01-11",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-01-12",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "445",
    sumOpenNumber: "3",
    resultDate: "2023-01-13",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2023-01-14",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "145",
    sumOpenNumber: "0",
    resultDate: "2023-01-15",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2023-01-16",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "234",
    sumOpenNumber: "9",
    resultDate: "2023-01-17",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2023-01-18",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-01-19",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "990",
    sumOpenNumber: "8",
    resultDate: "2023-01-20",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2023-01-21",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "128",
    sumOpenNumber: "1",
    resultDate: "2023-01-22",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "339",
    sumOpenNumber: "5",
    resultDate: "2023-01-23",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-01-24",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2023-01-25",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "244",
    sumOpenNumber: "0",
    resultDate: "2023-01-26",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-01-27",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2023-01-28",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2023-01-29",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "779",
    sumOpenNumber: "3",
    resultDate: "2023-01-30",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "444",
    sumOpenNumber: "2",
    resultDate: "2023-01-31",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "667",
    sumOpenNumber: "9",
    resultDate: "2023-02-01",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-02-02",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "288",
    sumOpenNumber: "8",
    resultDate: "2023-02-03",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "589",
    sumOpenNumber: "2",
    resultDate: "2023-02-04",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "770",
    sumOpenNumber: "4",
    resultDate: "2023-02-05",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "244",
    sumOpenNumber: "0",
    resultDate: "2023-02-06",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "448",
    sumOpenNumber: "6",
    resultDate: "2023-02-07",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "558",
    sumOpenNumber: "8",
    resultDate: "2023-02-08",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2023-02-09",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "138",
    sumOpenNumber: "2",
    resultDate: "2023-02-10",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-02-11",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "480",
    sumOpenNumber: "2",
    resultDate: "2023-02-12",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "559",
    sumOpenNumber: "9",
    resultDate: "2023-02-13",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "999",
    sumOpenNumber: "7",
    resultDate: "2023-02-14",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2023-02-15",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "366",
    sumOpenNumber: "5",
    resultDate: "2023-02-16",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2023-02-17",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-02-18",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "356",
    sumOpenNumber: "4",
    resultDate: "2023-02-19",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "145",
    sumOpenNumber: "0",
    resultDate: "2023-02-20",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-02-21",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "577",
    sumOpenNumber: "9",
    resultDate: "2023-02-22",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "666",
    sumOpenNumber: "8",
    resultDate: "2023-02-23",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-02-24",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "188",
    sumOpenNumber: "7",
    resultDate: "2023-02-25",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "449",
    sumOpenNumber: "7",
    resultDate: "2023-02-26",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-02-27",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "470",
    sumOpenNumber: "1",
    resultDate: "2023-02-28",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2023-03-01",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "377",
    sumOpenNumber: "7",
    resultDate: "2023-03-02",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2023-03-03",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "557",
    sumOpenNumber: "7",
    resultDate: "2023-03-04",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-03-05",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "677",
    sumOpenNumber: "0",
    resultDate: "2023-03-06",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-03-07",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-03-08",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2023-03-09",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-03-10",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "344",
    sumOpenNumber: "1",
    resultDate: "2023-03-11",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-03-12",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "229",
    sumOpenNumber: "3",
    resultDate: "2023-03-16",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "335",
    sumOpenNumber: "1",
    resultDate: "2023-03-17",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "346",
    sumOpenNumber: "3",
    resultDate: "2023-03-18",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-03-19",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "188",
    sumOpenNumber: "7",
    resultDate: "2023-03-20",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-03-21",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "469",
    sumOpenNumber: "9",
    resultDate: "2023-03-22",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "228",
    sumOpenNumber: "2",
    resultDate: "2023-03-23",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "444",
    sumOpenNumber: "2",
    resultDate: "2023-03-24",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "338",
    sumOpenNumber: "4",
    resultDate: "2023-03-25",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2023-03-26",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2023-03-27",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "239",
    sumOpenNumber: "4",
    resultDate: "2023-03-28",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "113",
    sumOpenNumber: "5",
    resultDate: "2023-03-29",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "233",
    sumOpenNumber: "8",
    resultDate: "2023-03-30",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-03-31",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "233",
    sumOpenNumber: "8",
    resultDate: "2023-04-01",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2023-04-02",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "188",
    sumOpenNumber: "7",
    resultDate: "2023-04-03",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-04-04",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "224",
    sumOpenNumber: "8",
    resultDate: "2023-04-05",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "248",
    sumOpenNumber: "4",
    resultDate: "2023-04-06",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2023-04-07",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2023-04-08",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "199",
    sumOpenNumber: "9",
    resultDate: "2023-04-09",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "369",
    sumOpenNumber: "8",
    resultDate: "2023-04-10",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "599",
    sumOpenNumber: "3",
    resultDate: "2023-04-11",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "227",
    sumOpenNumber: "1",
    resultDate: "2023-04-12",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-04-13",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2023-04-14",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2023-04-15",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "779",
    sumOpenNumber: "3",
    resultDate: "2023-04-16",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2023-04-17",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2023-04-18",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-04-19",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "247",
    sumOpenNumber: "3",
    resultDate: "2023-04-20",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-04-21",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-04-22",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-04-23",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "116",
    sumOpenNumber: "8",
    resultDate: "2023-04-24",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-04-25",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-04-26",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "113",
    sumOpenNumber: "5",
    resultDate: "2023-04-27",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2023-04-28",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2023-04-29",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "999",
    sumOpenNumber: "7",
    resultDate: "2023-04-30",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "444",
    sumOpenNumber: "2",
    resultDate: "2023-05-01",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2023-05-02",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2023-05-03",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2023-05-04",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "388",
    sumOpenNumber: "9",
    resultDate: "2023-05-05",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "227",
    sumOpenNumber: "1",
    resultDate: "2023-05-06",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "337",
    sumOpenNumber: "3",
    resultDate: "2023-05-07",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "888",
    sumOpenNumber: "4",
    resultDate: "2023-05-08",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "224",
    sumOpenNumber: "8",
    resultDate: "2023-05-09",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "366",
    sumOpenNumber: "5",
    resultDate: "2023-05-10",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "116",
    sumOpenNumber: "8",
    resultDate: "2023-05-11",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "188",
    sumOpenNumber: "7",
    resultDate: "2023-05-12",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-05-13",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-05-14",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "266",
    sumOpenNumber: "4",
    resultDate: "2023-05-15",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2023-05-16",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2023-05-17",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-05-18",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-05-19",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2023-05-20",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2023-05-21",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-05-22",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "269",
    sumOpenNumber: "7",
    resultDate: "2023-05-23",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "690",
    sumOpenNumber: "5",
    resultDate: "2023-05-24",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-05-25",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "588",
    sumOpenNumber: "1",
    resultDate: "2023-05-26",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-05-27",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "118",
    sumOpenNumber: "0",
    resultDate: "2023-05-28",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2023-05-29",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-05-30",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-05-31",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-06-01",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2023-06-02",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "999",
    sumOpenNumber: "7",
    resultDate: "2023-06-03",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "177",
    sumOpenNumber: "5",
    resultDate: "2023-06-04",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "117",
    sumOpenNumber: "9",
    resultDate: "2023-06-05",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "199",
    sumOpenNumber: "9",
    resultDate: "2023-06-06",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-06-07",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "344",
    sumOpenNumber: "1",
    resultDate: "2023-06-08",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2023-06-09",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-06-10",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "557",
    sumOpenNumber: "7",
    resultDate: "2023-06-11",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-06-12",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "489",
    sumOpenNumber: "1",
    resultDate: "2023-06-13",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2023-06-14",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "444",
    sumOpenNumber: "2",
    resultDate: "2023-06-15",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "488",
    sumOpenNumber: "0",
    resultDate: "2023-06-16",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "113",
    sumOpenNumber: "5",
    resultDate: "2023-06-17",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "480",
    sumOpenNumber: "2",
    resultDate: "2023-06-18",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "289",
    sumOpenNumber: "9",
    resultDate: "2023-06-19",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-06-20",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "699",
    sumOpenNumber: "4",
    resultDate: "2023-06-21",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "127",
    sumOpenNumber: "0",
    resultDate: "2023-06-22",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2023-06-23",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "777",
    sumOpenNumber: "1",
    resultDate: "2023-06-24",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "117",
    sumOpenNumber: "9",
    resultDate: "2023-06-25",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "116",
    sumOpenNumber: "8",
    resultDate: "2023-06-26",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2023-06-27",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2023-06-28",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "336",
    sumOpenNumber: "2",
    resultDate: "2023-06-29",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "555",
    sumOpenNumber: "5",
    resultDate: "2023-06-30",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-07-01",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "118",
    sumOpenNumber: "0",
    resultDate: "2023-07-02",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2023-07-03",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "699",
    sumOpenNumber: "4",
    resultDate: "2023-07-04",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "559",
    sumOpenNumber: "9",
    resultDate: "2023-07-05",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-07-06",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "247",
    sumOpenNumber: "3",
    resultDate: "2023-07-07",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "268",
    sumOpenNumber: "6",
    resultDate: "2023-07-08",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "488",
    sumOpenNumber: "0",
    resultDate: "2023-07-09",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "390",
    sumOpenNumber: "2",
    resultDate: "2023-07-10",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2023-07-11",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "667",
    sumOpenNumber: "9",
    resultDate: "2023-07-12",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2023-07-13",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "266",
    sumOpenNumber: "4",
    resultDate: "2023-07-14",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-07-15",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "337",
    sumOpenNumber: "3",
    resultDate: "2023-07-16",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "259",
    sumOpenNumber: "6",
    resultDate: "2023-07-17",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2023-07-18",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "117",
    sumOpenNumber: "9",
    resultDate: "2023-07-19",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-07-20",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2023-07-21",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2023-07-22",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2023-07-23",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2023-07-24",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2023-07-25",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2023-07-26",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2023-07-27",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "147",
    sumOpenNumber: "2",
    resultDate: "2023-07-28",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2023-07-29",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2023-07-30",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2023-07-31",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2023-08-01",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "238",
    sumOpenNumber: "3",
    resultDate: "2023-08-02",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "455",
    sumOpenNumber: "4",
    resultDate: "2023-08-03",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "366",
    sumOpenNumber: "5",
    resultDate: "2023-08-04",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "499",
    sumOpenNumber: "2",
    resultDate: "2023-08-05",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2023-08-06",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "188",
    sumOpenNumber: "7",
    resultDate: "2023-08-07",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "227",
    sumOpenNumber: "1",
    resultDate: "2023-08-08",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "127",
    sumOpenNumber: "0",
    resultDate: "2023-08-09",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2023-08-10",
  },
  {
    MarketTime: "03:00:00 PM",
    openNumber: "199",
    sumOpenNumber: "9",
    resultDate: "2023-08-11",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-09-07",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2021-09-08",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2021-09-09",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "559",
    sumOpenNumber: "9",
    resultDate: "2021-09-10",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "777",
    sumOpenNumber: "1",
    resultDate: "2021-09-11",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2021-09-12",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "168",
    sumOpenNumber: "5",
    resultDate: "2021-09-13",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2021-09-14",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "136",
    sumOpenNumber: "0",
    resultDate: "2021-09-15",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2021-09-16",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-09-17",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "178",
    sumOpenNumber: "6",
    resultDate: "2021-09-18",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2021-09-19",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "156",
    sumOpenNumber: "2",
    resultDate: "2021-09-20",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2021-09-21",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2021-09-22",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-09-23",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2021-09-24",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2021-09-25",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "234",
    sumOpenNumber: "9",
    resultDate: "2021-09-26",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-09-27",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2021-09-28",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-09-29",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2021-09-30",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2021-10-01",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2021-10-02",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2021-10-03",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-10-04",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-10-05",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-10-06",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2021-10-07",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "679",
    sumOpenNumber: "2",
    resultDate: "2021-10-08",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2021-10-09",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-10-10",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2021-10-11",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-10-12",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2021-10-13",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-10-14",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-10-15",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-10-16",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2021-10-17",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2021-10-18",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "0",
    sumOpenNumber: "0",
    resultDate: "2021-10-19",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-10-20",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-10-21",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2021-10-22",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-10-23",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-10-24",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2021-10-25",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2021-10-26",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "777",
    sumOpenNumber: "1",
    resultDate: "2021-10-27",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "579",
    sumOpenNumber: "1",
    resultDate: "2021-10-28",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "567",
    sumOpenNumber: "8",
    resultDate: "2021-10-29",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2021-10-30",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2021-10-31",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-11-01",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "269",
    sumOpenNumber: "7",
    resultDate: "2021-11-02",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-11-03",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-11-04",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "490",
    sumOpenNumber: "3",
    resultDate: "2021-11-05",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2021-11-06",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "457",
    sumOpenNumber: "6",
    resultDate: "2021-11-07",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "889",
    sumOpenNumber: "5",
    resultDate: "2021-11-08",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-11-09",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2021-11-10",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2021-11-11",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2021-11-12",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-11-13",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2021-11-14",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2021-11-15",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2021-11-16",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2021-11-17",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-11-18",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2021-11-19",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2021-11-20",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "480",
    sumOpenNumber: "2",
    resultDate: "2021-11-21",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-11-22",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "267",
    sumOpenNumber: "5",
    resultDate: "2021-11-23",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-11-24",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2021-11-25",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2021-11-26",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-11-27",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "449",
    sumOpenNumber: "7",
    resultDate: "2021-11-28",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2021-11-29",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2021-11-30",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2021-12-01",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2021-12-02",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-12-03",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2021-12-04",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2021-12-05",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-12-06",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "480",
    sumOpenNumber: "2",
    resultDate: "2021-12-07",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-12-08",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "666",
    sumOpenNumber: "8",
    resultDate: "2021-12-09",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2021-12-10",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2021-12-11",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2021-12-12",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "579",
    sumOpenNumber: "1",
    resultDate: "2021-12-13",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "289",
    sumOpenNumber: "9",
    resultDate: "2021-12-14",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "990",
    sumOpenNumber: "8",
    resultDate: "2021-12-15",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "188",
    sumOpenNumber: "7",
    resultDate: "2021-12-16",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2021-12-17",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2021-12-18",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "249",
    sumOpenNumber: "5",
    resultDate: "2021-12-19",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2021-12-20",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "390",
    sumOpenNumber: "2",
    resultDate: "2021-12-21",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "114",
    sumOpenNumber: "6",
    resultDate: "2021-12-22",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "228",
    sumOpenNumber: "2",
    resultDate: "2021-12-23",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "457",
    sumOpenNumber: "6",
    resultDate: "2021-12-24",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2021-12-25",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "579",
    sumOpenNumber: "1",
    resultDate: "2021-12-26",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "157",
    sumOpenNumber: "3",
    resultDate: "2021-12-27",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "238",
    sumOpenNumber: "3",
    resultDate: "2021-12-28",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2021-12-29",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2021-12-30",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "457",
    sumOpenNumber: "6",
    resultDate: "2021-12-31",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-01-01",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2022-01-02",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "236",
    sumOpenNumber: "1",
    resultDate: "2022-01-03",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "366",
    sumOpenNumber: "5",
    resultDate: "2022-01-04",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-01-05",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "148",
    sumOpenNumber: "3",
    resultDate: "2022-01-06",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "477",
    sumOpenNumber: "8",
    resultDate: "2022-01-07",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "239",
    sumOpenNumber: "4",
    resultDate: "2022-01-08",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-01-09",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "279",
    sumOpenNumber: "8",
    resultDate: "2022-01-10",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "680",
    sumOpenNumber: "4",
    resultDate: "2022-01-11",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2022-01-12",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-01-13",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "249",
    sumOpenNumber: "5",
    resultDate: "2022-01-14",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-01-15",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2022-01-16",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "267",
    sumOpenNumber: "5",
    resultDate: "2022-01-17",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-01-18",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2022-01-19",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-01-20",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "227",
    sumOpenNumber: "1",
    resultDate: "2022-01-21",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "790",
    sumOpenNumber: "6",
    resultDate: "2022-01-22",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "379",
    sumOpenNumber: "9",
    resultDate: "2022-01-23",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2022-01-24",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "359",
    sumOpenNumber: "7",
    resultDate: "2022-01-25",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-01-26",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "479",
    sumOpenNumber: "0",
    resultDate: "2022-01-27",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2022-01-28",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "377",
    sumOpenNumber: "7",
    resultDate: "2022-01-29",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-01-30",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "267",
    sumOpenNumber: "5",
    resultDate: "2022-01-31",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2022-02-01",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2022-02-02",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "113",
    sumOpenNumber: "5",
    resultDate: "2022-02-03",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2022-02-04",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "790",
    sumOpenNumber: "6",
    resultDate: "2022-02-05",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2022-02-06",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "470",
    sumOpenNumber: "1",
    resultDate: "2022-02-07",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2022-02-08",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "367",
    sumOpenNumber: "6",
    resultDate: "2022-02-09",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2022-02-10",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "449",
    sumOpenNumber: "7",
    resultDate: "2022-02-11",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2022-02-12",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "117",
    sumOpenNumber: "9",
    resultDate: "2022-02-13",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2022-02-14",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "378",
    sumOpenNumber: "8",
    resultDate: "2022-02-15",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2022-02-16",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2022-02-17",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2022-02-18",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2022-02-19",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "299",
    sumOpenNumber: "0",
    resultDate: "2022-02-20",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "689",
    sumOpenNumber: "3",
    resultDate: "2022-02-21",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-02-22",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2022-02-23",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "689",
    sumOpenNumber: "3",
    resultDate: "2022-02-24",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-02-26",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2022-02-27",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2022-02-28",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-03-01",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "178",
    sumOpenNumber: "6",
    resultDate: "2022-03-02",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2022-03-03",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "790",
    sumOpenNumber: "6",
    resultDate: "2022-03-04",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2022-03-05",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2022-03-06",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2022-03-07",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2022-03-08",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "366",
    sumOpenNumber: "5",
    resultDate: "2022-03-09",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "790",
    sumOpenNumber: "6",
    resultDate: "2022-03-10",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "128",
    sumOpenNumber: "1",
    resultDate: "2022-03-11",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "348",
    sumOpenNumber: "5",
    resultDate: "2022-03-12",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2022-03-13",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "289",
    sumOpenNumber: "9",
    resultDate: "2022-03-14",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "115",
    sumOpenNumber: "7",
    resultDate: "2022-03-15",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2022-03-16",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-03-17",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2022-03-18",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-03-19",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2022-03-20",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-03-21",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2022-03-22",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2022-03-23",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "489",
    sumOpenNumber: "1",
    resultDate: "2022-03-24",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-03-25",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "127",
    sumOpenNumber: "0",
    resultDate: "2022-03-26",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "225",
    sumOpenNumber: "9",
    resultDate: "2022-03-27",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "117",
    sumOpenNumber: "9",
    resultDate: "2022-03-28",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "378",
    sumOpenNumber: "8",
    resultDate: "2022-03-29",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2022-03-30",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "490",
    sumOpenNumber: "3",
    resultDate: "2022-03-31",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-04-01",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2022-04-02",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "148",
    sumOpenNumber: "3",
    resultDate: "2022-04-03",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-04-04",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2022-04-05",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-04-06",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-04-07",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2022-04-08",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "477",
    sumOpenNumber: "8",
    resultDate: "2022-04-09",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "239",
    sumOpenNumber: "4",
    resultDate: "2022-04-10",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-04-11",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "115",
    sumOpenNumber: "7",
    resultDate: "2022-04-12",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2022-04-14",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2022-04-15",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-04-16",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2022-04-17",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-04-18",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-04-19",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "590",
    sumOpenNumber: "4",
    resultDate: "2022-04-20",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2022-04-21",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2022-04-22",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2022-04-23",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2022-04-24",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2022-04-25",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2022-04-26",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-04-27",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-04-28",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-04-29",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-04-30",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "899",
    sumOpenNumber: "6",
    resultDate: "2022-05-01",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "388",
    sumOpenNumber: "9",
    resultDate: "2022-05-02",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2022-05-03",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "688",
    sumOpenNumber: "2",
    resultDate: "2022-05-04",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2022-05-05",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2022-05-06",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "455",
    sumOpenNumber: "4",
    resultDate: "2022-05-07",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-05-08",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "567",
    sumOpenNumber: "8",
    resultDate: "2022-05-09",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-05-10",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "238",
    sumOpenNumber: "3",
    resultDate: "2022-05-11",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2022-05-12",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2022-05-13",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "134",
    sumOpenNumber: "8",
    resultDate: "2022-05-14",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2022-05-15",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-05-16",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "259",
    sumOpenNumber: "6",
    resultDate: "2022-05-17",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "279",
    sumOpenNumber: "8",
    resultDate: "2022-05-18",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "799",
    sumOpenNumber: "5",
    resultDate: "2022-05-19",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-05-20",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "990",
    sumOpenNumber: "8",
    resultDate: "2022-05-21",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-05-22",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "479",
    sumOpenNumber: "0",
    resultDate: "2022-05-23",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2022-05-24",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-05-25",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2022-05-26",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2022-05-27",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-05-28",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-05-29",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2022-05-30",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-05-31",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2022-06-01",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "337",
    sumOpenNumber: "3",
    resultDate: "2022-06-02",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "589",
    sumOpenNumber: "2",
    resultDate: "2022-06-03",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "799",
    sumOpenNumber: "5",
    resultDate: "2022-06-04",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2022-06-05",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-06-06",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2022-06-07",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "378",
    sumOpenNumber: "8",
    resultDate: "2022-06-08",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "489",
    sumOpenNumber: "1",
    resultDate: "2022-06-09",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "157",
    sumOpenNumber: "3",
    resultDate: "2022-06-10",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2022-06-11",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-06-12",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2022-06-13",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2022-06-14",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "227",
    sumOpenNumber: "1",
    resultDate: "2022-06-15",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "117",
    sumOpenNumber: "9",
    resultDate: "2022-06-16",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2022-06-17",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-06-18",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2022-06-19",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-06-20",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-06-21",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "156",
    sumOpenNumber: "2",
    resultDate: "2022-06-22",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "346",
    sumOpenNumber: "3",
    resultDate: "2022-06-23",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2022-06-24",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2022-06-25",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2022-06-26",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-06-27",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "147",
    sumOpenNumber: "2",
    resultDate: "2022-06-28",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2022-06-29",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "470",
    sumOpenNumber: "1",
    resultDate: "2022-06-30",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2022-07-01",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2022-07-02",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-07-03",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-07-04",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-07-05",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-07-06",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "356",
    sumOpenNumber: "4",
    resultDate: "2022-07-07",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "567",
    sumOpenNumber: "8",
    resultDate: "2022-07-08",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2022-07-09",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-07-10",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2022-07-11",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2022-07-12",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2022-07-13",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2022-07-14",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2022-07-15",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "770",
    sumOpenNumber: "4",
    resultDate: "2022-07-16",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-07-17",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2022-07-18",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "556",
    sumOpenNumber: "6",
    resultDate: "2022-07-19",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2022-07-20",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2022-07-21",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2022-07-22",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-07-23",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "223",
    sumOpenNumber: "7",
    resultDate: "2022-07-24",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "334",
    sumOpenNumber: "0",
    resultDate: "2022-07-25",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-07-26",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2022-07-27",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-07-28",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "334",
    sumOpenNumber: "0",
    resultDate: "2022-07-29",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-07-30",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2022-07-31",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2022-08-01",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "239",
    sumOpenNumber: "4",
    resultDate: "2022-08-02",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "278",
    sumOpenNumber: "7",
    resultDate: "2022-08-03",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2022-08-04",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "367",
    sumOpenNumber: "6",
    resultDate: "2022-08-05",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "228",
    sumOpenNumber: "2",
    resultDate: "2022-08-06",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "156",
    sumOpenNumber: "2",
    resultDate: "2022-08-07",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2022-08-08",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2022-08-09",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-08-10",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-08-11",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "890",
    sumOpenNumber: "7",
    resultDate: "2022-08-12",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-08-13",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "580",
    sumOpenNumber: "3",
    resultDate: "2022-08-14",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-08-15",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "337",
    sumOpenNumber: "3",
    resultDate: "2022-08-16",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "377",
    sumOpenNumber: "7",
    resultDate: "2022-08-17",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-08-18",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "289",
    sumOpenNumber: "9",
    resultDate: "2022-08-19",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-08-20",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2022-08-21",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-08-22",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2022-08-23",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-08-24",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "128",
    sumOpenNumber: "1",
    resultDate: "2022-08-25",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-08-26",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2022-08-27",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "134",
    sumOpenNumber: "8",
    resultDate: "2022-08-28",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2022-08-29",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-08-30",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "270",
    sumOpenNumber: "9",
    resultDate: "2022-08-31",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "449",
    sumOpenNumber: "7",
    resultDate: "2022-09-01",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "580",
    sumOpenNumber: "3",
    resultDate: "2022-09-02",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "236",
    sumOpenNumber: "1",
    resultDate: "2022-09-03",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "346",
    sumOpenNumber: "3",
    resultDate: "2022-09-04",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2022-09-05",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "689",
    sumOpenNumber: "3",
    resultDate: "2022-09-06",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-09-07",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2022-09-08",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-09-09",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-09-10",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-09-11",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-09-12",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "990",
    sumOpenNumber: "8",
    resultDate: "2022-09-13",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "229",
    sumOpenNumber: "3",
    resultDate: "2022-09-14",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "689",
    sumOpenNumber: "3",
    resultDate: "2022-09-15",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "790",
    sumOpenNumber: "6",
    resultDate: "2022-09-16",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2022-09-17",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-09-18",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "990",
    sumOpenNumber: "8",
    resultDate: "2022-09-19",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "890",
    sumOpenNumber: "7",
    resultDate: "2022-09-20",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-09-21",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2022-09-22",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2022-09-23",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2022-09-24",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "148",
    sumOpenNumber: "3",
    resultDate: "2022-09-25",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-09-26",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "567",
    sumOpenNumber: "8",
    resultDate: "2022-09-27",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-09-28",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "138",
    sumOpenNumber: "2",
    resultDate: "2022-09-29",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-09-30",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-10-01",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-10-02",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "148",
    sumOpenNumber: "3",
    resultDate: "2022-10-03",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "680",
    sumOpenNumber: "4",
    resultDate: "2022-10-04",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-10-05",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-10-06",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2022-10-07",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2022-10-08",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-10-09",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2022-10-10",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2022-10-11",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-10-12",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-10-13",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2022-10-14",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-10-15",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2022-10-16",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "270",
    sumOpenNumber: "9",
    resultDate: "2022-10-17",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-10-18",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "244",
    sumOpenNumber: "0",
    resultDate: "2022-10-19",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "270",
    sumOpenNumber: "9",
    resultDate: "2022-10-20",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "278",
    sumOpenNumber: "7",
    resultDate: "2022-10-21",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2022-10-22",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "248",
    sumOpenNumber: "4",
    resultDate: "2022-10-23",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2022-10-24",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-10-25",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2022-10-26",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2022-10-27",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "478",
    sumOpenNumber: "9",
    resultDate: "2022-10-28",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-10-29",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-10-30",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2022-10-31",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2022-11-01",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-11-02",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2022-11-03",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "558",
    sumOpenNumber: "8",
    resultDate: "2022-11-04",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2022-11-05",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-11-06",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2022-11-07",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2022-11-08",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-11-09",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-11-10",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "136",
    sumOpenNumber: "0",
    resultDate: "2022-11-11",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "689",
    sumOpenNumber: "3",
    resultDate: "2022-11-12",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2022-11-13",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-11-14",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "234",
    sumOpenNumber: "9",
    resultDate: "2022-11-15",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2022-11-16",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2022-11-17",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "145",
    sumOpenNumber: "0",
    resultDate: "2022-11-18",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "234",
    sumOpenNumber: "9",
    resultDate: "2022-11-19",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-11-20",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2022-11-21",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2022-11-22",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "580",
    sumOpenNumber: "3",
    resultDate: "2022-11-23",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-11-24",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2022-11-25",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "770",
    sumOpenNumber: "4",
    resultDate: "2022-11-26",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-11-27",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-11-28",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-11-29",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2022-11-30",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2022-12-01",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-12-02",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "568",
    sumOpenNumber: "9",
    resultDate: "2022-12-03",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2022-12-04",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "239",
    sumOpenNumber: "4",
    resultDate: "2022-12-05",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "366",
    sumOpenNumber: "5",
    resultDate: "2022-12-06",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2022-12-07",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "299",
    sumOpenNumber: "0",
    resultDate: "2022-12-08",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "469",
    sumOpenNumber: "9",
    resultDate: "2022-12-09",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-12-10",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2022-12-11",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "137",
    sumOpenNumber: "1",
    resultDate: "2022-12-12",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2022-12-13",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-12-14",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-12-15",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2022-12-16",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2022-12-17",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2022-12-18",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "568",
    sumOpenNumber: "9",
    resultDate: "2022-12-19",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2022-12-20",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-12-21",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-12-22",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2022-12-23",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2022-12-24",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2022-12-25",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-12-26",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "455",
    sumOpenNumber: "4",
    resultDate: "2022-12-27",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "377",
    sumOpenNumber: "7",
    resultDate: "2022-12-28",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2022-12-29",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "699",
    sumOpenNumber: "4",
    resultDate: "2022-12-30",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2022-12-31",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2023-01-01",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2023-01-02",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2023-01-03",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2023-01-04",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "399",
    sumOpenNumber: "1",
    resultDate: "2023-01-05",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-01-06",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2023-01-07",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-01-08",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-01-09",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "377",
    sumOpenNumber: "7",
    resultDate: "2023-01-10",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "688",
    sumOpenNumber: "2",
    resultDate: "2023-01-11",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "188",
    sumOpenNumber: "7",
    resultDate: "2023-01-12",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2023-01-13",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "136",
    sumOpenNumber: "0",
    resultDate: "2023-01-14",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2023-01-15",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-01-16",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "247",
    sumOpenNumber: "3",
    resultDate: "2023-01-17",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "188",
    sumOpenNumber: "7",
    resultDate: "2023-01-18",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "267",
    sumOpenNumber: "5",
    resultDate: "2023-01-19",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "348",
    sumOpenNumber: "5",
    resultDate: "2023-01-20",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2023-01-21",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "333",
    sumOpenNumber: "9",
    resultDate: "2023-01-22",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-01-23",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "588",
    sumOpenNumber: "1",
    resultDate: "2023-01-24",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2023-01-25",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2023-01-26",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2023-01-27",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2023-01-28",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "223",
    sumOpenNumber: "7",
    resultDate: "2023-01-29",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2023-01-30",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "378",
    sumOpenNumber: "8",
    resultDate: "2023-01-31",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-02-01",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "115",
    sumOpenNumber: "7",
    resultDate: "2023-02-02",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2023-02-03",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-02-04",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2023-02-05",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "556",
    sumOpenNumber: "6",
    resultDate: "2023-02-06",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2023-02-07",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "244",
    sumOpenNumber: "0",
    resultDate: "2023-02-08",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "479",
    sumOpenNumber: "0",
    resultDate: "2023-02-09",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "156",
    sumOpenNumber: "2",
    resultDate: "2023-02-10",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-02-11",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-02-12",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-02-13",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2023-02-14",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-02-15",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2023-02-16",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "344",
    sumOpenNumber: "1",
    resultDate: "2023-02-17",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "115",
    sumOpenNumber: "7",
    resultDate: "2023-02-18",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-02-19",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "567",
    sumOpenNumber: "8",
    resultDate: "2023-02-20",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2023-02-21",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-02-22",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2023-02-23",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "779",
    sumOpenNumber: "3",
    resultDate: "2023-02-24",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "445",
    sumOpenNumber: "3",
    resultDate: "2023-02-25",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-02-26",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "444",
    sumOpenNumber: "2",
    resultDate: "2023-02-27",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "366",
    sumOpenNumber: "5",
    resultDate: "2023-02-28",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2023-03-01",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "267",
    sumOpenNumber: "5",
    resultDate: "2023-03-02",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2023-03-03",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "112",
    sumOpenNumber: "4",
    resultDate: "2023-03-04",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2023-03-05",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2023-03-06",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2023-03-07",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-03-08",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2023-03-10",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2023-03-11",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "479",
    sumOpenNumber: "0",
    resultDate: "2023-03-12",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-03-16",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "699",
    sumOpenNumber: "4",
    resultDate: "2023-03-17",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "337",
    sumOpenNumber: "3",
    resultDate: "2023-03-18",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2023-03-19",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "339",
    sumOpenNumber: "5",
    resultDate: "2023-03-20",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2023-03-21",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "779",
    sumOpenNumber: "3",
    resultDate: "2023-03-22",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "348",
    sumOpenNumber: "5",
    resultDate: "2023-03-23",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2023-03-24",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-03-25",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "118",
    sumOpenNumber: "0",
    resultDate: "2023-03-26",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-03-27",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2023-03-28",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "556",
    sumOpenNumber: "6",
    resultDate: "2023-03-29",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-03-30",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2023-03-31",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-04-01",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2023-04-02",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "225",
    sumOpenNumber: "9",
    resultDate: "2023-04-03",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-04-04",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-04-05",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-04-06",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2023-04-07",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "444",
    sumOpenNumber: "2",
    resultDate: "2023-04-08",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "337",
    sumOpenNumber: "3",
    resultDate: "2023-04-09",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "225",
    sumOpenNumber: "9",
    resultDate: "2023-04-10",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2023-04-11",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2023-04-12",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "116",
    sumOpenNumber: "8",
    resultDate: "2023-04-13",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "148",
    sumOpenNumber: "3",
    resultDate: "2023-04-14",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2023-04-15",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-04-16",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2023-04-17",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2023-04-18",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2023-04-19",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "333",
    sumOpenNumber: "9",
    resultDate: "2023-04-20",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2023-04-21",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-04-22",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-04-23",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2023-04-24",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "267",
    sumOpenNumber: "5",
    resultDate: "2023-04-25",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2023-04-26",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-04-27",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-04-28",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "244",
    sumOpenNumber: "0",
    resultDate: "2023-04-29",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "899",
    sumOpenNumber: "6",
    resultDate: "2023-04-30",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "566",
    sumOpenNumber: "7",
    resultDate: "2023-05-01",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-05-02",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-05-03",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "799",
    sumOpenNumber: "5",
    resultDate: "2023-05-04",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "479",
    sumOpenNumber: "0",
    resultDate: "2023-05-05",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2023-05-06",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "555",
    sumOpenNumber: "5",
    resultDate: "2023-05-07",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-05-08",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2023-05-09",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2023-05-10",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2023-05-11",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "222",
    sumOpenNumber: "6",
    resultDate: "2023-05-12",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-05-13",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-05-14",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "223",
    sumOpenNumber: "7",
    resultDate: "2023-05-15",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2023-05-16",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2023-05-17",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-05-18",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "299",
    sumOpenNumber: "0",
    resultDate: "2023-05-19",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-05-20",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2023-05-21",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-05-22",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-05-23",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2023-05-24",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "188",
    sumOpenNumber: "7",
    resultDate: "2023-05-25",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-05-26",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2023-05-27",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2023-05-28",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-05-29",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "470",
    sumOpenNumber: "1",
    resultDate: "2023-05-30",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "335",
    sumOpenNumber: "1",
    resultDate: "2023-05-31",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "447",
    sumOpenNumber: "5",
    resultDate: "2023-06-01",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2023-06-02",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2023-06-03",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "111",
    sumOpenNumber: "3",
    resultDate: "2023-06-04",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-06-05",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2023-06-06",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2023-06-07",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "899",
    sumOpenNumber: "6",
    resultDate: "2023-06-08",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "0",
    sumOpenNumber: "0",
    resultDate: "2023-06-09",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "233",
    sumOpenNumber: "8",
    resultDate: "2023-06-10",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-06-11",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-06-12",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "559",
    sumOpenNumber: "9",
    resultDate: "2023-06-13",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "488",
    sumOpenNumber: "0",
    resultDate: "2023-06-14",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "559",
    sumOpenNumber: "9",
    resultDate: "2023-06-15",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "599",
    sumOpenNumber: "3",
    resultDate: "2023-06-16",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "680",
    sumOpenNumber: "4",
    resultDate: "2023-06-17",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2023-06-18",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2023-06-19",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "0",
    sumOpenNumber: "0",
    resultDate: "2023-06-20",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-06-21",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "344",
    sumOpenNumber: "1",
    resultDate: "2023-06-22",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2023-06-23",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "667",
    sumOpenNumber: "9",
    resultDate: "2023-06-24",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2023-06-25",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "449",
    sumOpenNumber: "7",
    resultDate: "2023-06-26",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "267",
    sumOpenNumber: "5",
    resultDate: "2023-06-27",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2023-06-28",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-06-29",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-06-30",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2023-07-01",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "226",
    sumOpenNumber: "0",
    resultDate: "2023-07-02",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2023-07-03",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-07-04",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2023-07-05",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-07-06",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-07-07",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "223",
    sumOpenNumber: "7",
    resultDate: "2023-07-08",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "166",
    sumOpenNumber: "3",
    resultDate: "2023-07-09",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "333",
    sumOpenNumber: "9",
    resultDate: "2023-07-10",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2023-07-11",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-07-12",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2023-07-13",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-07-14",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-07-15",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "557",
    sumOpenNumber: "7",
    resultDate: "2023-07-16",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2023-07-17",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "888",
    sumOpenNumber: "4",
    resultDate: "2023-07-18",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2023-07-19",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "113",
    sumOpenNumber: "5",
    resultDate: "2023-07-20",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2023-07-21",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2023-07-22",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "111",
    sumOpenNumber: "3",
    resultDate: "2023-07-23",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2023-07-24",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-07-25",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-07-26",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2023-07-27",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2023-07-28",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "299",
    sumOpenNumber: "0",
    resultDate: "2023-07-29",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "199",
    sumOpenNumber: "9",
    resultDate: "2023-07-30",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2023-07-31",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2023-08-01",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "229",
    sumOpenNumber: "3",
    resultDate: "2023-08-02",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-08-03",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "990",
    sumOpenNumber: "8",
    resultDate: "2023-08-04",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "178",
    sumOpenNumber: "6",
    resultDate: "2023-08-05",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2023-08-06",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "248",
    sumOpenNumber: "4",
    resultDate: "2023-08-07",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "377",
    sumOpenNumber: "7",
    resultDate: "2023-08-08",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2023-08-09",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2023-08-10",
  },
  {
    MarketTime: "07:00:00 PM",
    openNumber: "145",
    sumOpenNumber: "0",
    resultDate: "2023-08-11",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2021-09-07",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-09-08",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-09-09",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-09-10",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2021-09-11",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "339",
    sumOpenNumber: "5",
    resultDate: "2021-09-12",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "399",
    sumOpenNumber: "1",
    resultDate: "2021-09-13",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "477",
    sumOpenNumber: "8",
    resultDate: "2021-09-14",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2021-09-15",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "444",
    sumOpenNumber: "2",
    resultDate: "2021-09-16",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "338",
    sumOpenNumber: "4",
    resultDate: "2021-09-17",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2021-09-18",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "777",
    sumOpenNumber: "1",
    resultDate: "2021-09-19",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2021-09-20",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-09-21",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2021-09-22",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "888",
    sumOpenNumber: "4",
    resultDate: "2021-09-23",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2021-09-24",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "888",
    sumOpenNumber: "4",
    resultDate: "2021-09-25",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "479",
    sumOpenNumber: "0",
    resultDate: "2021-09-26",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2021-09-27",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2021-09-28",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2021-09-29",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-09-30",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-10-01",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "226",
    sumOpenNumber: "0",
    resultDate: "2021-10-02",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2021-10-03",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "227",
    sumOpenNumber: "1",
    resultDate: "2021-10-04",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2021-10-05",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2021-10-06",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-10-07",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2021-10-08",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "333",
    sumOpenNumber: "9",
    resultDate: "2021-10-09",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "580",
    sumOpenNumber: "3",
    resultDate: "2021-10-10",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "777",
    sumOpenNumber: "1",
    resultDate: "2021-10-11",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2021-10-12",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2021-10-13",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2021-10-14",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "168",
    sumOpenNumber: "5",
    resultDate: "2021-10-15",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2021-10-16",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "999",
    sumOpenNumber: "7",
    resultDate: "2021-10-17",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-10-18",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2021-10-19",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "444",
    sumOpenNumber: "2",
    resultDate: "2021-10-20",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2021-10-21",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-10-22",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "488",
    sumOpenNumber: "0",
    resultDate: "2021-10-23",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2021-10-24",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2021-10-25",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "222",
    sumOpenNumber: "6",
    resultDate: "2021-10-26",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-10-27",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2021-10-28",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "499",
    sumOpenNumber: "2",
    resultDate: "2021-10-29",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "238",
    sumOpenNumber: "3",
    resultDate: "2021-10-30",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2021-10-31",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2021-11-01",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-11-02",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2021-11-03",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2021-11-04",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "249",
    sumOpenNumber: "5",
    resultDate: "2021-11-05",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2021-11-06",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2021-11-07",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2021-11-08",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "339",
    sumOpenNumber: "5",
    resultDate: "2021-11-09",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "990",
    sumOpenNumber: "8",
    resultDate: "2021-11-10",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "558",
    sumOpenNumber: "8",
    resultDate: "2021-11-11",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "117",
    sumOpenNumber: "9",
    resultDate: "2021-11-12",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "111",
    sumOpenNumber: "3",
    resultDate: "2021-11-13",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "770",
    sumOpenNumber: "4",
    resultDate: "2021-11-14",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "444",
    sumOpenNumber: "2",
    resultDate: "2021-11-15",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "166",
    sumOpenNumber: "3",
    resultDate: "2021-11-16",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "113",
    sumOpenNumber: "5",
    resultDate: "2021-11-17",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "888",
    sumOpenNumber: "4",
    resultDate: "2021-11-18",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-11-19",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-11-20",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "790",
    sumOpenNumber: "6",
    resultDate: "2021-11-21",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "567",
    sumOpenNumber: "8",
    resultDate: "2021-11-22",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-11-23",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-11-24",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "126",
    sumOpenNumber: "9",
    resultDate: "2021-11-25",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "118",
    sumOpenNumber: "0",
    resultDate: "2021-11-26",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2021-11-27",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "166",
    sumOpenNumber: "3",
    resultDate: "2021-11-28",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-11-29",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2021-11-30",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "679",
    sumOpenNumber: "2",
    resultDate: "2021-12-01",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "399",
    sumOpenNumber: "1",
    resultDate: "2021-12-02",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "0",
    sumOpenNumber: "0",
    resultDate: "2021-12-03",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2021-12-04",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2021-12-05",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2021-12-06",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "114",
    sumOpenNumber: "6",
    resultDate: "2021-12-07",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2021-12-08",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "113",
    sumOpenNumber: "5",
    resultDate: "2021-12-09",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-12-10",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2021-12-11",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "568",
    sumOpenNumber: "9",
    resultDate: "2021-12-12",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2021-12-13",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "338",
    sumOpenNumber: "4",
    resultDate: "2021-12-14",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2021-12-15",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "157",
    sumOpenNumber: "3",
    resultDate: "2021-12-16",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "225",
    sumOpenNumber: "9",
    resultDate: "2021-12-17",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "388",
    sumOpenNumber: "9",
    resultDate: "2021-12-18",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "790",
    sumOpenNumber: "6",
    resultDate: "2021-12-19",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "278",
    sumOpenNumber: "7",
    resultDate: "2021-12-20",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "690",
    sumOpenNumber: "5",
    resultDate: "2021-12-21",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2021-12-22",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "557",
    sumOpenNumber: "7",
    resultDate: "2021-12-23",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2021-12-24",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "559",
    sumOpenNumber: "9",
    resultDate: "2021-12-25",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "126",
    sumOpenNumber: "9",
    resultDate: "2021-12-26",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "224",
    sumOpenNumber: "8",
    resultDate: "2021-12-27",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2021-12-28",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2021-12-29",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "570",
    sumOpenNumber: "2",
    resultDate: "2021-12-30",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2021-12-31",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2022-01-01",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "239",
    sumOpenNumber: "4",
    resultDate: "2022-01-02",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "480",
    sumOpenNumber: "2",
    resultDate: "2022-01-03",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-01-04",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "559",
    sumOpenNumber: "9",
    resultDate: "2022-01-05",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "115",
    sumOpenNumber: "7",
    resultDate: "2022-01-06",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-01-07",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "690",
    sumOpenNumber: "5",
    resultDate: "2022-01-08",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "278",
    sumOpenNumber: "7",
    resultDate: "2022-01-09",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2022-01-10",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2022-01-11",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-01-12",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-01-13",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2022-01-14",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "336",
    sumOpenNumber: "2",
    resultDate: "2022-01-15",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2022-01-16",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "238",
    sumOpenNumber: "3",
    resultDate: "2022-01-17",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2022-01-18",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "270",
    sumOpenNumber: "9",
    resultDate: "2022-01-19",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2022-01-20",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "359",
    sumOpenNumber: "7",
    resultDate: "2022-01-21",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2022-01-22",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2022-01-23",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "117",
    sumOpenNumber: "9",
    resultDate: "2022-01-24",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "113",
    sumOpenNumber: "5",
    resultDate: "2022-01-25",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2022-01-26",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "359",
    sumOpenNumber: "7",
    resultDate: "2022-01-27",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-01-28",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "127",
    sumOpenNumber: "0",
    resultDate: "2022-01-29",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2022-01-30",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "134",
    sumOpenNumber: "8",
    resultDate: "2022-01-31",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "115",
    sumOpenNumber: "7",
    resultDate: "2022-02-01",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "688",
    sumOpenNumber: "2",
    resultDate: "2022-02-02",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "147",
    sumOpenNumber: "2",
    resultDate: "2022-02-03",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "889",
    sumOpenNumber: "5",
    resultDate: "2022-02-04",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "455",
    sumOpenNumber: "4",
    resultDate: "2022-02-05",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-02-06",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "469",
    sumOpenNumber: "9",
    resultDate: "2022-02-07",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-02-08",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2022-02-09",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2022-02-10",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "259",
    sumOpenNumber: "6",
    resultDate: "2022-02-11",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2022-02-12",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "238",
    sumOpenNumber: "3",
    resultDate: "2022-02-13",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "388",
    sumOpenNumber: "9",
    resultDate: "2022-02-14",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2022-02-15",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "570",
    sumOpenNumber: "2",
    resultDate: "2022-02-16",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "138",
    sumOpenNumber: "2",
    resultDate: "2022-02-17",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "259",
    sumOpenNumber: "6",
    resultDate: "2022-02-18",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "146",
    sumOpenNumber: "1",
    resultDate: "2022-02-19",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "557",
    sumOpenNumber: "7",
    resultDate: "2022-02-20",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "359",
    sumOpenNumber: "7",
    resultDate: "2022-02-21",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2022-02-22",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2022-02-23",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-02-24",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "770",
    sumOpenNumber: "4",
    resultDate: "2022-02-25",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2022-02-26",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2022-02-27",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-02-28",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "356",
    sumOpenNumber: "4",
    resultDate: "2022-03-01",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-03-02",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-03-03",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2022-03-04",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-03-05",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-03-06",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2022-03-07",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2022-03-08",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2022-03-09",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2022-03-10",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "489",
    sumOpenNumber: "1",
    resultDate: "2022-03-11",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "339",
    sumOpenNumber: "5",
    resultDate: "2022-03-12",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-03-13",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-03-14",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2022-03-15",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "378",
    sumOpenNumber: "8",
    resultDate: "2022-03-16",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "234",
    sumOpenNumber: "9",
    resultDate: "2022-03-17",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "238",
    sumOpenNumber: "3",
    resultDate: "2022-03-18",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "388",
    sumOpenNumber: "9",
    resultDate: "2022-03-19",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "222",
    sumOpenNumber: "6",
    resultDate: "2022-03-20",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-03-21",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "368",
    sumOpenNumber: "7",
    resultDate: "2022-03-22",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "449",
    sumOpenNumber: "7",
    resultDate: "2022-03-23",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "136",
    sumOpenNumber: "0",
    resultDate: "2022-03-24",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-03-25",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-03-26",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2022-03-27",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2022-03-28",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-03-29",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "270",
    sumOpenNumber: "9",
    resultDate: "2022-03-30",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "115",
    sumOpenNumber: "7",
    resultDate: "2022-03-31",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2022-04-01",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2022-04-02",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2022-04-03",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-04-04",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-04-05",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2022-04-06",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2022-04-07",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-04-08",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-04-09",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "378",
    sumOpenNumber: "8",
    resultDate: "2022-04-10",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-04-11",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "289",
    sumOpenNumber: "9",
    resultDate: "2022-04-12",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2022-04-13",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "156",
    sumOpenNumber: "2",
    resultDate: "2022-04-14",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2022-04-15",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "138",
    sumOpenNumber: "2",
    resultDate: "2022-04-16",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "145",
    sumOpenNumber: "0",
    resultDate: "2022-04-17",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-04-18",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2022-04-19",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2022-04-20",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2022-04-21",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2022-04-22",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-04-23",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-04-24",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2022-04-25",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2022-04-26",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "455",
    sumOpenNumber: "4",
    resultDate: "2022-04-27",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2022-04-28",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2022-04-29",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2022-04-30",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-05-01",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-05-02",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-05-03",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "379",
    sumOpenNumber: "9",
    resultDate: "2022-05-04",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "477",
    sumOpenNumber: "8",
    resultDate: "2022-05-05",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "369",
    sumOpenNumber: "8",
    resultDate: "2022-05-06",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2022-05-07",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2022-05-08",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2022-05-09",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-05-10",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "148",
    sumOpenNumber: "3",
    resultDate: "2022-05-11",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-05-12",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "667",
    sumOpenNumber: "9",
    resultDate: "2022-05-13",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "279",
    sumOpenNumber: "8",
    resultDate: "2022-05-14",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "378",
    sumOpenNumber: "8",
    resultDate: "2022-05-15",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2022-05-16",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-05-17",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2022-05-18",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2022-05-19",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "570",
    sumOpenNumber: "2",
    resultDate: "2022-05-20",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "699",
    sumOpenNumber: "4",
    resultDate: "2022-05-21",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "279",
    sumOpenNumber: "8",
    resultDate: "2022-05-22",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "379",
    sumOpenNumber: "9",
    resultDate: "2022-05-23",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2022-05-24",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2022-05-25",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "359",
    sumOpenNumber: "7",
    resultDate: "2022-05-26",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-05-27",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "489",
    sumOpenNumber: "1",
    resultDate: "2022-05-28",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "449",
    sumOpenNumber: "7",
    resultDate: "2022-05-29",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-05-30",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2022-05-31",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "339",
    sumOpenNumber: "5",
    resultDate: "2022-06-01",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2022-06-02",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2022-06-03",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-06-04",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "580",
    sumOpenNumber: "3",
    resultDate: "2022-06-05",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-06-06",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2022-06-07",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "679",
    sumOpenNumber: "2",
    resultDate: "2022-06-08",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "270",
    sumOpenNumber: "9",
    resultDate: "2022-06-09",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "268",
    sumOpenNumber: "6",
    resultDate: "2022-06-10",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "115",
    sumOpenNumber: "7",
    resultDate: "2022-06-11",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2022-06-12",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2022-06-13",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2022-06-14",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2022-06-15",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "359",
    sumOpenNumber: "7",
    resultDate: "2022-06-16",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-06-17",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2022-06-18",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "288",
    sumOpenNumber: "8",
    resultDate: "2022-06-19",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "239",
    sumOpenNumber: "4",
    resultDate: "2022-06-20",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "346",
    sumOpenNumber: "3",
    resultDate: "2022-06-21",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2022-06-22",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "356",
    sumOpenNumber: "4",
    resultDate: "2022-06-23",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2022-06-24",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-06-25",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "356",
    sumOpenNumber: "4",
    resultDate: "2022-06-26",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2022-06-27",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "890",
    sumOpenNumber: "7",
    resultDate: "2022-06-28",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "580",
    sumOpenNumber: "3",
    resultDate: "2022-06-29",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "289",
    sumOpenNumber: "9",
    resultDate: "2022-06-30",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "289",
    sumOpenNumber: "9",
    resultDate: "2022-07-01",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-07-02",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "590",
    sumOpenNumber: "4",
    resultDate: "2022-07-03",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-07-04",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2022-07-05",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "238",
    sumOpenNumber: "3",
    resultDate: "2022-07-06",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-07-07",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "239",
    sumOpenNumber: "4",
    resultDate: "2022-07-08",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "388",
    sumOpenNumber: "9",
    resultDate: "2022-07-09",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-07-10",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "138",
    sumOpenNumber: "2",
    resultDate: "2022-07-11",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-07-12",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2022-07-13",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "238",
    sumOpenNumber: "3",
    resultDate: "2022-07-14",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "589",
    sumOpenNumber: "2",
    resultDate: "2022-07-15",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "335",
    sumOpenNumber: "1",
    resultDate: "2022-07-16",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-07-17",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "267",
    sumOpenNumber: "5",
    resultDate: "2022-07-18",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-07-19",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2022-07-20",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "379",
    sumOpenNumber: "9",
    resultDate: "2022-07-21",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "278",
    sumOpenNumber: "7",
    resultDate: "2022-07-22",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "111",
    sumOpenNumber: "3",
    resultDate: "2022-07-23",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-07-24",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "390",
    sumOpenNumber: "2",
    resultDate: "2022-07-25",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2022-07-26",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-07-27",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-07-28",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2022-07-29",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "229",
    sumOpenNumber: "3",
    resultDate: "2022-07-30",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2022-07-31",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2022-08-01",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2022-08-02",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2022-08-03",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "469",
    sumOpenNumber: "9",
    resultDate: "2022-08-04",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "480",
    sumOpenNumber: "2",
    resultDate: "2022-08-05",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-08-06",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "679",
    sumOpenNumber: "2",
    resultDate: "2022-08-07",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "279",
    sumOpenNumber: "8",
    resultDate: "2022-08-08",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "455",
    sumOpenNumber: "4",
    resultDate: "2022-08-09",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "390",
    sumOpenNumber: "2",
    resultDate: "2022-08-10",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "244",
    sumOpenNumber: "0",
    resultDate: "2022-08-11",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2022-08-12",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-08-13",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "147",
    sumOpenNumber: "2",
    resultDate: "2022-08-14",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2022-08-15",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-08-16",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "567",
    sumOpenNumber: "8",
    resultDate: "2022-08-17",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "127",
    sumOpenNumber: "0",
    resultDate: "2022-08-18",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2022-08-19",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "178",
    sumOpenNumber: "6",
    resultDate: "2022-08-20",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "477",
    sumOpenNumber: "8",
    resultDate: "2022-08-21",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2022-08-22",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "346",
    sumOpenNumber: "3",
    resultDate: "2022-08-23",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-08-24",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "134",
    sumOpenNumber: "8",
    resultDate: "2022-08-25",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "199",
    sumOpenNumber: "9",
    resultDate: "2022-08-26",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "114",
    sumOpenNumber: "6",
    resultDate: "2022-08-27",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2022-08-28",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-08-29",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-08-30",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-08-31",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "156",
    sumOpenNumber: "2",
    resultDate: "2022-09-01",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2022-09-02",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-09-03",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "378",
    sumOpenNumber: "8",
    resultDate: "2022-09-04",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "356",
    sumOpenNumber: "4",
    resultDate: "2022-09-05",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-09-06",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "579",
    sumOpenNumber: "1",
    resultDate: "2022-09-07",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "249",
    sumOpenNumber: "5",
    resultDate: "2022-09-08",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-09-09",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2022-09-10",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2022-09-11",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2022-09-12",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "156",
    sumOpenNumber: "2",
    resultDate: "2022-09-13",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2022-09-14",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "136",
    sumOpenNumber: "0",
    resultDate: "2022-09-15",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2022-09-16",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "368",
    sumOpenNumber: "7",
    resultDate: "2022-09-17",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2022-09-18",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-09-19",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2022-09-20",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-09-21",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-09-22",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2022-09-23",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-09-24",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2022-09-25",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "348",
    sumOpenNumber: "5",
    resultDate: "2022-09-26",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "457",
    sumOpenNumber: "6",
    resultDate: "2022-09-27",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2022-09-28",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2022-09-29",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2022-09-30",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2022-10-01",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "270",
    sumOpenNumber: "9",
    resultDate: "2022-10-02",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "134",
    sumOpenNumber: "8",
    resultDate: "2022-10-03",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2022-10-04",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2022-10-05",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "267",
    sumOpenNumber: "5",
    resultDate: "2022-10-06",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "368",
    sumOpenNumber: "7",
    resultDate: "2022-10-07",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "279",
    sumOpenNumber: "8",
    resultDate: "2022-10-08",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2022-10-09",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2022-10-10",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "679",
    sumOpenNumber: "2",
    resultDate: "2022-10-11",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "278",
    sumOpenNumber: "7",
    resultDate: "2022-10-12",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2022-10-13",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2022-10-14",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "157",
    sumOpenNumber: "3",
    resultDate: "2022-10-15",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-10-16",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "238",
    sumOpenNumber: "3",
    resultDate: "2022-10-17",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-10-18",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-10-19",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2022-10-20",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "690",
    sumOpenNumber: "5",
    resultDate: "2022-10-21",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "568",
    sumOpenNumber: "9",
    resultDate: "2022-10-22",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2022-10-23",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2022-10-24",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2022-10-25",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2022-10-26",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2022-10-27",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "247",
    sumOpenNumber: "3",
    resultDate: "2022-10-28",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2022-10-29",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "368",
    sumOpenNumber: "7",
    resultDate: "2022-10-30",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2022-10-31",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-11-01",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-11-02",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-11-03",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "148",
    sumOpenNumber: "3",
    resultDate: "2022-11-04",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "579",
    sumOpenNumber: "1",
    resultDate: "2022-11-05",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "480",
    sumOpenNumber: "2",
    resultDate: "2022-11-06",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-11-07",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2022-11-08",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "236",
    sumOpenNumber: "1",
    resultDate: "2022-11-09",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2022-11-10",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2022-11-11",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "368",
    sumOpenNumber: "7",
    resultDate: "2022-11-12",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "489",
    sumOpenNumber: "1",
    resultDate: "2022-11-13",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "689",
    sumOpenNumber: "3",
    resultDate: "2022-11-14",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "137",
    sumOpenNumber: "1",
    resultDate: "2022-11-15",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2022-11-16",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-11-17",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2022-11-18",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-11-19",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2022-11-20",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-11-21",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-11-22",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2022-11-23",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "369",
    sumOpenNumber: "8",
    resultDate: "2022-11-24",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-11-25",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "478",
    sumOpenNumber: "9",
    resultDate: "2022-11-26",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-11-27",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-11-28",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-11-29",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "137",
    sumOpenNumber: "1",
    resultDate: "2022-11-30",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2022-12-01",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "128",
    sumOpenNumber: "1",
    resultDate: "2022-12-02",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2022-12-03",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "239",
    sumOpenNumber: "4",
    resultDate: "2022-12-04",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2022-12-05",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "188",
    sumOpenNumber: "7",
    resultDate: "2022-12-06",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2022-12-07",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "146",
    sumOpenNumber: "1",
    resultDate: "2022-12-08",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2022-12-09",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2022-12-10",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "337",
    sumOpenNumber: "3",
    resultDate: "2022-12-11",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2022-12-12",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-12-13",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "890",
    sumOpenNumber: "7",
    resultDate: "2022-12-14",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2022-12-15",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-12-16",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "378",
    sumOpenNumber: "8",
    resultDate: "2022-12-17",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2022-12-18",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2022-12-19",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-12-20",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-12-21",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2022-12-22",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2022-12-23",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "589",
    sumOpenNumber: "2",
    resultDate: "2022-12-24",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-12-25",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2022-12-26",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-12-27",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2022-12-28",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2022-12-29",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2022-12-30",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "268",
    sumOpenNumber: "6",
    resultDate: "2022-12-31",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "470",
    sumOpenNumber: "1",
    resultDate: "2023-01-01",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "127",
    sumOpenNumber: "0",
    resultDate: "2023-01-02",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2023-01-03",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "127",
    sumOpenNumber: "0",
    resultDate: "2023-01-04",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "299",
    sumOpenNumber: "0",
    resultDate: "2023-01-05",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2023-01-06",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "488",
    sumOpenNumber: "0",
    resultDate: "2023-01-07",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "677",
    sumOpenNumber: "0",
    resultDate: "2023-01-08",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2023-01-09",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-01-10",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-01-11",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-01-12",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2023-01-13",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "590",
    sumOpenNumber: "4",
    resultDate: "2023-01-14",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2023-01-15",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "299",
    sumOpenNumber: "0",
    resultDate: "2023-01-16",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "778",
    sumOpenNumber: "2",
    resultDate: "2023-01-17",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2023-01-18",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "337",
    sumOpenNumber: "3",
    resultDate: "2023-01-19",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "557",
    sumOpenNumber: "7",
    resultDate: "2023-01-20",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "778",
    sumOpenNumber: "2",
    resultDate: "2023-01-21",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2023-01-22",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2023-01-23",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2023-01-24",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2023-01-25",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2023-01-26",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-01-27",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "680",
    sumOpenNumber: "4",
    resultDate: "2023-01-28",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2023-01-29",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-01-30",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2023-01-31",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "339",
    sumOpenNumber: "5",
    resultDate: "2023-02-01",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2023-02-02",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "112",
    sumOpenNumber: "4",
    resultDate: "2023-02-03",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-02-04",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-02-05",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2023-02-06",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-02-07",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "177",
    sumOpenNumber: "5",
    resultDate: "2023-02-08",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "289",
    sumOpenNumber: "9",
    resultDate: "2023-02-09",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "299",
    sumOpenNumber: "0",
    resultDate: "2023-02-10",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2023-02-11",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-02-12",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "227",
    sumOpenNumber: "1",
    resultDate: "2023-02-13",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "249",
    sumOpenNumber: "5",
    resultDate: "2023-02-14",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "599",
    sumOpenNumber: "3",
    resultDate: "2023-02-15",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-02-16",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "570",
    sumOpenNumber: "2",
    resultDate: "2023-02-17",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-02-18",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-02-19",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2023-02-20",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "279",
    sumOpenNumber: "8",
    resultDate: "2023-02-21",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2023-02-22",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "335",
    sumOpenNumber: "1",
    resultDate: "2023-02-23",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-02-24",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2023-02-25",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-02-26",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "117",
    sumOpenNumber: "9",
    resultDate: "2023-02-27",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "890",
    sumOpenNumber: "7",
    resultDate: "2023-02-28",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2023-03-01",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "299",
    sumOpenNumber: "0",
    resultDate: "2023-03-02",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "556",
    sumOpenNumber: "6",
    resultDate: "2023-03-03",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "999",
    sumOpenNumber: "7",
    resultDate: "2023-03-04",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "566",
    sumOpenNumber: "7",
    resultDate: "2023-03-05",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-03-06",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-03-07",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "138",
    sumOpenNumber: "2",
    resultDate: "2023-03-08",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-03-09",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-03-10",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "366",
    sumOpenNumber: "5",
    resultDate: "2023-03-11",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "227",
    sumOpenNumber: "1",
    resultDate: "2023-03-12",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-03-16",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-03-17",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2023-03-18",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-03-19",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "244",
    sumOpenNumber: "0",
    resultDate: "2023-03-20",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2023-03-21",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "999",
    sumOpenNumber: "7",
    resultDate: "2023-03-22",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "770",
    sumOpenNumber: "4",
    resultDate: "2023-03-23",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2023-03-24",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2023-03-25",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2023-03-26",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2023-03-27",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2023-03-28",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-03-29",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2023-03-30",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "490",
    sumOpenNumber: "3",
    resultDate: "2023-03-31",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "227",
    sumOpenNumber: "1",
    resultDate: "2023-04-01",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-04-02",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "445",
    sumOpenNumber: "3",
    resultDate: "2023-04-03",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-04-04",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-04-05",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "334",
    sumOpenNumber: "0",
    resultDate: "2023-04-06",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2023-04-07",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-04-08",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2023-04-09",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-04-10",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2023-04-11",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2023-04-12",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2023-04-13",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-04-14",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-04-15",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-04-16",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2023-04-17",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-04-18",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-04-19",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2023-04-20",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "899",
    sumOpenNumber: "6",
    resultDate: "2023-04-21",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "116",
    sumOpenNumber: "8",
    resultDate: "2023-04-22",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2023-04-23",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "339",
    sumOpenNumber: "5",
    resultDate: "2023-04-24",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-04-25",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2023-04-26",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-04-27",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2023-04-28",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "188",
    sumOpenNumber: "7",
    resultDate: "2023-04-29",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2023-04-30",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "990",
    sumOpenNumber: "8",
    resultDate: "2023-05-01",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-05-02",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2023-05-03",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2023-05-04",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "188",
    sumOpenNumber: "7",
    resultDate: "2023-05-05",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "225",
    sumOpenNumber: "9",
    resultDate: "2023-05-06",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-05-07",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2023-05-08",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2023-05-09",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "288",
    sumOpenNumber: "8",
    resultDate: "2023-05-10",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "118",
    sumOpenNumber: "0",
    resultDate: "2023-05-11",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2023-05-12",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-05-13",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2023-05-14",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "449",
    sumOpenNumber: "7",
    resultDate: "2023-05-15",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2023-05-16",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2023-05-17",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2023-05-18",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "567",
    sumOpenNumber: "8",
    resultDate: "2023-05-19",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "177",
    sumOpenNumber: "5",
    resultDate: "2023-05-20",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-05-21",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2023-05-22",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-05-23",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2023-05-24",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-05-25",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "668",
    sumOpenNumber: "0",
    resultDate: "2023-05-26",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "799",
    sumOpenNumber: "5",
    resultDate: "2023-05-27",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "288",
    sumOpenNumber: "8",
    resultDate: "2023-05-28",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-05-29",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "388",
    sumOpenNumber: "9",
    resultDate: "2023-05-30",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "457",
    sumOpenNumber: "6",
    resultDate: "2023-05-31",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "555",
    sumOpenNumber: "5",
    resultDate: "2023-06-01",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "566",
    sumOpenNumber: "7",
    resultDate: "2023-06-02",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2023-06-03",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-06-04",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "778",
    sumOpenNumber: "2",
    resultDate: "2023-06-05",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2023-06-06",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "244",
    sumOpenNumber: "0",
    resultDate: "2023-06-07",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "990",
    sumOpenNumber: "8",
    resultDate: "2023-06-08",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "166",
    sumOpenNumber: "3",
    resultDate: "2023-06-09",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "568",
    sumOpenNumber: "9",
    resultDate: "2023-06-10",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "277",
    sumOpenNumber: "6",
    resultDate: "2023-06-11",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-06-12",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2023-06-13",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "188",
    sumOpenNumber: "7",
    resultDate: "2023-06-14",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "335",
    sumOpenNumber: "1",
    resultDate: "2023-06-15",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "668",
    sumOpenNumber: "0",
    resultDate: "2023-06-16",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "447",
    sumOpenNumber: "5",
    resultDate: "2023-06-17",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "199",
    sumOpenNumber: "9",
    resultDate: "2023-06-18",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "224",
    sumOpenNumber: "8",
    resultDate: "2023-06-19",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2023-06-20",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "268",
    sumOpenNumber: "6",
    resultDate: "2023-06-21",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2023-06-22",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2023-06-23",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "799",
    sumOpenNumber: "5",
    resultDate: "2023-06-24",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "228",
    sumOpenNumber: "2",
    resultDate: "2023-06-25",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "890",
    sumOpenNumber: "7",
    resultDate: "2023-06-26",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "599",
    sumOpenNumber: "3",
    resultDate: "2023-06-27",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "227",
    sumOpenNumber: "1",
    resultDate: "2023-06-28",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "477",
    sumOpenNumber: "8",
    resultDate: "2023-06-29",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2023-06-30",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "799",
    sumOpenNumber: "5",
    resultDate: "2023-07-01",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2023-07-02",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "559",
    sumOpenNumber: "9",
    resultDate: "2023-07-03",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2023-07-04",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2023-07-05",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "188",
    sumOpenNumber: "7",
    resultDate: "2023-07-06",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2023-07-07",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-07-08",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "445",
    sumOpenNumber: "3",
    resultDate: "2023-07-09",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "339",
    sumOpenNumber: "5",
    resultDate: "2023-07-10",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-07-11",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "277",
    sumOpenNumber: "6",
    resultDate: "2023-07-12",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "134",
    sumOpenNumber: "8",
    resultDate: "2023-07-13",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "367",
    sumOpenNumber: "6",
    resultDate: "2023-07-14",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "388",
    sumOpenNumber: "9",
    resultDate: "2023-07-15",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "699",
    sumOpenNumber: "4",
    resultDate: "2023-07-16",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2023-07-17",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "688",
    sumOpenNumber: "2",
    resultDate: "2023-07-18",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "377",
    sumOpenNumber: "7",
    resultDate: "2023-07-19",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "556",
    sumOpenNumber: "6",
    resultDate: "2023-07-20",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2023-07-21",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "339",
    sumOpenNumber: "5",
    resultDate: "2023-07-22",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "166",
    sumOpenNumber: "3",
    resultDate: "2023-07-23",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "236",
    sumOpenNumber: "1",
    resultDate: "2023-07-24",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "667",
    sumOpenNumber: "9",
    resultDate: "2023-07-25",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "377",
    sumOpenNumber: "7",
    resultDate: "2023-07-26",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-07-27",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "477",
    sumOpenNumber: "8",
    resultDate: "2023-07-28",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "356",
    sumOpenNumber: "4",
    resultDate: "2023-07-29",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2023-07-30",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "178",
    sumOpenNumber: "6",
    resultDate: "2023-07-31",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "225",
    sumOpenNumber: "9",
    resultDate: "2023-08-01",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "177",
    sumOpenNumber: "5",
    resultDate: "2023-08-02",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "588",
    sumOpenNumber: "1",
    resultDate: "2023-08-03",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "346",
    sumOpenNumber: "3",
    resultDate: "2023-08-04",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2023-08-05",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2023-08-06",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2023-08-07",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "699",
    sumOpenNumber: "4",
    resultDate: "2023-08-08",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "480",
    sumOpenNumber: "2",
    resultDate: "2023-08-09",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2023-08-10",
  },
  {
    MarketTime: "10:00:00 AM",
    openNumber: "577",
    sumOpenNumber: "9",
    resultDate: "2023-08-11",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2021-09-07",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2021-09-08",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2021-09-09",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-09-10",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2021-09-11",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2021-09-12",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2021-09-13",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2021-09-14",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "136",
    sumOpenNumber: "0",
    resultDate: "2021-09-15",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-09-16",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "126",
    sumOpenNumber: "9",
    resultDate: "2021-09-17",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "147",
    sumOpenNumber: "2",
    resultDate: "2021-09-18",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "888",
    sumOpenNumber: "4",
    resultDate: "2021-09-19",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "111",
    sumOpenNumber: "3",
    resultDate: "2021-09-20",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2021-09-21",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2021-09-22",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-09-23",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2021-09-24",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-09-25",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2021-09-26",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2021-09-27",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "259",
    sumOpenNumber: "6",
    resultDate: "2021-09-28",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-09-29",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2021-09-30",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2021-10-01",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2021-10-02",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "346",
    sumOpenNumber: "3",
    resultDate: "2021-10-03",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-10-04",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-10-05",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2021-10-06",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2021-10-07",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-10-08",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "0",
    sumOpenNumber: "0",
    resultDate: "2021-10-09",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2021-10-10",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2021-10-11",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2021-10-12",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2021-10-13",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-10-14",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "990",
    sumOpenNumber: "8",
    resultDate: "2021-10-15",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2021-10-16",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2021-10-17",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2021-10-18",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-10-19",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2021-10-20",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2021-10-21",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2021-10-22",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2021-10-23",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2021-10-24",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2021-10-25",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2021-10-26",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-10-27",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2021-10-28",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2021-10-29",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-10-30",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2021-10-31",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2021-11-01",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2021-11-02",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-11-03",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2021-11-04",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2021-11-05",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-11-06",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2021-11-07",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2021-11-08",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-11-09",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2021-11-10",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2021-11-11",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-11-12",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "444",
    sumOpenNumber: "2",
    resultDate: "2021-11-13",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2021-11-14",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2021-11-15",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2021-11-16",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "166",
    sumOpenNumber: "3",
    resultDate: "2021-11-17",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "166",
    sumOpenNumber: "3",
    resultDate: "2021-11-18",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-11-19",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "146",
    sumOpenNumber: "1",
    resultDate: "2021-11-20",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2021-11-21",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2021-11-22",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "115",
    sumOpenNumber: "7",
    resultDate: "2021-11-23",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "555",
    sumOpenNumber: "5",
    resultDate: "2021-11-24",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2021-11-25",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-11-26",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-11-27",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2021-11-28",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-11-29",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "679",
    sumOpenNumber: "2",
    resultDate: "2021-11-30",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2021-12-01",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-12-02",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "999",
    sumOpenNumber: "7",
    resultDate: "2021-12-03",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-12-04",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2021-12-05",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2021-12-06",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2021-12-07",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-12-08",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-12-09",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2021-12-10",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "359",
    sumOpenNumber: "7",
    resultDate: "2021-12-11",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "479",
    sumOpenNumber: "0",
    resultDate: "2021-12-12",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "577",
    sumOpenNumber: "9",
    resultDate: "2021-12-13",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "689",
    sumOpenNumber: "3",
    resultDate: "2021-12-14",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2021-12-15",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2021-12-16",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "236",
    sumOpenNumber: "1",
    resultDate: "2021-12-17",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2021-12-18",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "368",
    sumOpenNumber: "7",
    resultDate: "2021-12-19",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2021-12-20",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2021-12-21",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2021-12-22",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2021-12-23",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "229",
    sumOpenNumber: "3",
    resultDate: "2021-12-24",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2021-12-25",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2021-12-26",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2021-12-27",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "388",
    sumOpenNumber: "9",
    resultDate: "2021-12-28",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "346",
    sumOpenNumber: "3",
    resultDate: "2021-12-29",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2021-12-30",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2021-12-31",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "249",
    sumOpenNumber: "5",
    resultDate: "2022-01-01",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2022-01-02",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "115",
    sumOpenNumber: "7",
    resultDate: "2022-01-03",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-01-04",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "127",
    sumOpenNumber: "0",
    resultDate: "2022-01-05",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2022-01-06",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2022-01-07",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-01-08",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "489",
    sumOpenNumber: "1",
    resultDate: "2022-01-09",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2022-01-10",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "268",
    sumOpenNumber: "6",
    resultDate: "2022-01-11",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "790",
    sumOpenNumber: "6",
    resultDate: "2022-01-12",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2022-01-13",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2022-01-14",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "470",
    sumOpenNumber: "1",
    resultDate: "2022-01-15",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-01-16",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2022-01-17",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "299",
    sumOpenNumber: "0",
    resultDate: "2022-01-18",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "356",
    sumOpenNumber: "4",
    resultDate: "2022-01-19",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2022-01-20",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2022-01-21",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2022-01-22",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "567",
    sumOpenNumber: "8",
    resultDate: "2022-01-23",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "112",
    sumOpenNumber: "4",
    resultDate: "2022-01-24",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "558",
    sumOpenNumber: "8",
    resultDate: "2022-01-25",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "233",
    sumOpenNumber: "8",
    resultDate: "2022-01-26",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-01-27",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "137",
    sumOpenNumber: "1",
    resultDate: "2022-01-28",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "359",
    sumOpenNumber: "7",
    resultDate: "2022-01-29",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "490",
    sumOpenNumber: "3",
    resultDate: "2022-01-30",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2022-01-31",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2022-02-01",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2022-02-02",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "259",
    sumOpenNumber: "6",
    resultDate: "2022-02-03",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "279",
    sumOpenNumber: "8",
    resultDate: "2022-02-04",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "390",
    sumOpenNumber: "2",
    resultDate: "2022-02-05",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "335",
    sumOpenNumber: "1",
    resultDate: "2022-02-06",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-02-07",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-02-08",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "136",
    sumOpenNumber: "0",
    resultDate: "2022-02-09",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2022-02-10",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2022-02-11",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "344",
    sumOpenNumber: "1",
    resultDate: "2022-02-12",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "589",
    sumOpenNumber: "2",
    resultDate: "2022-02-13",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-02-14",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2022-02-15",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2022-02-16",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2022-02-17",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "113",
    sumOpenNumber: "5",
    resultDate: "2022-02-18",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-02-19",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "457",
    sumOpenNumber: "6",
    resultDate: "2022-02-20",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2022-02-21",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "248",
    sumOpenNumber: "4",
    resultDate: "2022-02-22",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "114",
    sumOpenNumber: "6",
    resultDate: "2022-02-23",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2022-02-24",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "239",
    sumOpenNumber: "4",
    resultDate: "2022-02-25",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "556",
    sumOpenNumber: "6",
    resultDate: "2022-02-26",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2022-02-27",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "156",
    sumOpenNumber: "2",
    resultDate: "2022-02-28",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-03-01",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-03-02",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2022-03-03",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "146",
    sumOpenNumber: "1",
    resultDate: "2022-03-04",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "167",
    sumOpenNumber: "4",
    resultDate: "2022-03-05",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2022-03-06",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2022-03-07",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2022-03-08",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "138",
    sumOpenNumber: "2",
    resultDate: "2022-03-09",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2022-03-10",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2022-03-11",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2022-03-12",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "379",
    sumOpenNumber: "9",
    resultDate: "2022-03-13",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-03-14",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2022-03-15",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-03-16",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2022-03-17",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2022-03-18",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-03-19",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2022-03-20",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-03-21",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-03-22",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-03-23",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-03-24",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "366",
    sumOpenNumber: "5",
    resultDate: "2022-03-25",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2022-03-26",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2022-03-27",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2022-03-28",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "680",
    sumOpenNumber: "4",
    resultDate: "2022-03-29",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2022-03-30",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2022-03-31",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2022-04-01",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2022-04-02",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "590",
    sumOpenNumber: "4",
    resultDate: "2022-04-03",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2022-04-04",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "167",
    sumOpenNumber: "4",
    resultDate: "2022-04-05",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "570",
    sumOpenNumber: "2",
    resultDate: "2022-04-06",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "359",
    sumOpenNumber: "7",
    resultDate: "2022-04-07",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2022-04-08",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2022-04-09",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2022-04-10",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-04-11",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "449",
    sumOpenNumber: "7",
    resultDate: "2022-04-12",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2022-04-13",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "388",
    sumOpenNumber: "9",
    resultDate: "2022-04-14",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "188",
    sumOpenNumber: "7",
    resultDate: "2022-04-15",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2022-04-16",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2022-04-17",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "335",
    sumOpenNumber: "1",
    resultDate: "2022-04-18",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "268",
    sumOpenNumber: "6",
    resultDate: "2022-04-19",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-04-20",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2022-04-21",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "229",
    sumOpenNumber: "3",
    resultDate: "2022-04-22",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2022-04-23",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "266",
    sumOpenNumber: "4",
    resultDate: "2022-04-24",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2022-04-25",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2022-04-26",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2022-04-27",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-04-28",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "367",
    sumOpenNumber: "6",
    resultDate: "2022-04-29",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2022-04-30",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2022-05-01",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "148",
    sumOpenNumber: "3",
    resultDate: "2022-05-02",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "289",
    sumOpenNumber: "9",
    resultDate: "2022-05-03",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-05-04",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "128",
    sumOpenNumber: "1",
    resultDate: "2022-05-05",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "238",
    sumOpenNumber: "3",
    resultDate: "2022-05-06",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "390",
    sumOpenNumber: "2",
    resultDate: "2022-05-07",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "690",
    sumOpenNumber: "5",
    resultDate: "2022-05-08",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-05-09",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-05-10",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "337",
    sumOpenNumber: "3",
    resultDate: "2022-05-11",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2022-05-12",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "247",
    sumOpenNumber: "3",
    resultDate: "2022-05-13",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-05-14",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "369",
    sumOpenNumber: "8",
    resultDate: "2022-05-15",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2022-05-16",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "127",
    sumOpenNumber: "0",
    resultDate: "2022-05-17",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2022-05-18",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "137",
    sumOpenNumber: "1",
    resultDate: "2022-05-19",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2022-05-20",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-05-21",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "689",
    sumOpenNumber: "3",
    resultDate: "2022-05-22",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "349",
    sumOpenNumber: "6",
    resultDate: "2022-05-23",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-05-24",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2022-05-25",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2022-05-26",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2022-05-27",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2022-05-28",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2022-05-29",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2022-05-30",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "479",
    sumOpenNumber: "0",
    resultDate: "2022-05-31",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2022-06-01",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-06-02",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "679",
    sumOpenNumber: "2",
    resultDate: "2022-06-03",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "270",
    sumOpenNumber: "9",
    resultDate: "2022-06-04",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2022-06-05",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2022-06-06",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2022-06-07",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "668",
    sumOpenNumber: "0",
    resultDate: "2022-06-08",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "344",
    sumOpenNumber: "1",
    resultDate: "2022-06-09",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "238",
    sumOpenNumber: "3",
    resultDate: "2022-06-10",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "289",
    sumOpenNumber: "9",
    resultDate: "2022-06-11",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-06-12",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2022-06-13",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "369",
    sumOpenNumber: "8",
    resultDate: "2022-06-14",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2022-06-15",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2022-06-16",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "890",
    sumOpenNumber: "7",
    resultDate: "2022-06-17",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "289",
    sumOpenNumber: "9",
    resultDate: "2022-06-18",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2022-06-19",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "126",
    sumOpenNumber: "9",
    resultDate: "2022-06-20",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "270",
    sumOpenNumber: "9",
    resultDate: "2022-06-21",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2022-06-22",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-06-23",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-06-24",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-06-25",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2022-06-26",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2022-06-27",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-06-28",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "558",
    sumOpenNumber: "8",
    resultDate: "2022-06-29",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "770",
    sumOpenNumber: "4",
    resultDate: "2022-06-30",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "369",
    sumOpenNumber: "8",
    resultDate: "2022-07-01",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2022-07-02",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "368",
    sumOpenNumber: "7",
    resultDate: "2022-07-03",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "577",
    sumOpenNumber: "9",
    resultDate: "2022-07-04",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "138",
    sumOpenNumber: "2",
    resultDate: "2022-07-05",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2022-07-06",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-07-07",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-07-08",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "356",
    sumOpenNumber: "4",
    resultDate: "2022-07-09",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2022-07-10",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "359",
    sumOpenNumber: "7",
    resultDate: "2022-07-11",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "128",
    sumOpenNumber: "1",
    resultDate: "2022-07-12",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "236",
    sumOpenNumber: "1",
    resultDate: "2022-07-13",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2022-07-14",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "127",
    sumOpenNumber: "0",
    resultDate: "2022-07-15",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2022-07-16",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "259",
    sumOpenNumber: "6",
    resultDate: "2022-07-17",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "580",
    sumOpenNumber: "3",
    resultDate: "2022-07-18",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-07-19",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2022-07-20",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "157",
    sumOpenNumber: "3",
    resultDate: "2022-07-21",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "128",
    sumOpenNumber: "1",
    resultDate: "2022-07-22",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2022-07-23",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2022-07-24",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-07-25",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "570",
    sumOpenNumber: "2",
    resultDate: "2022-07-26",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-07-27",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "390",
    sumOpenNumber: "2",
    resultDate: "2022-07-28",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "470",
    sumOpenNumber: "1",
    resultDate: "2022-07-29",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "247",
    sumOpenNumber: "3",
    resultDate: "2022-07-30",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "590",
    sumOpenNumber: "4",
    resultDate: "2022-07-31",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-08-01",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "156",
    sumOpenNumber: "2",
    resultDate: "2022-08-02",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "890",
    sumOpenNumber: "7",
    resultDate: "2022-08-03",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "570",
    sumOpenNumber: "2",
    resultDate: "2022-08-04",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2022-08-05",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-08-06",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2022-08-07",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "116",
    sumOpenNumber: "8",
    resultDate: "2022-08-08",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "369",
    sumOpenNumber: "8",
    resultDate: "2022-08-09",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "349",
    sumOpenNumber: "6",
    resultDate: "2022-08-10",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2022-08-11",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2022-08-12",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-08-13",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2022-08-14",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2022-08-15",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "278",
    sumOpenNumber: "7",
    resultDate: "2022-08-16",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "379",
    sumOpenNumber: "9",
    resultDate: "2022-08-17",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "390",
    sumOpenNumber: "2",
    resultDate: "2022-08-18",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2022-08-19",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-08-20",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "136",
    sumOpenNumber: "0",
    resultDate: "2022-08-21",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-08-22",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "147",
    sumOpenNumber: "2",
    resultDate: "2022-08-23",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2022-08-24",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "248",
    sumOpenNumber: "4",
    resultDate: "2022-08-25",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2022-08-26",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2022-08-27",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-08-28",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2022-08-29",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2022-08-30",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "134",
    sumOpenNumber: "8",
    resultDate: "2022-08-31",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-09-01",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-09-02",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2022-09-03",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2022-09-04",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2022-09-05",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-09-06",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-09-07",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "469",
    sumOpenNumber: "9",
    resultDate: "2022-09-08",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "480",
    sumOpenNumber: "2",
    resultDate: "2022-09-09",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2022-09-10",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2022-09-11",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-09-12",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-09-13",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "346",
    sumOpenNumber: "3",
    resultDate: "2022-09-14",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2022-09-15",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "248",
    sumOpenNumber: "4",
    resultDate: "2022-09-16",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2022-09-17",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "457",
    sumOpenNumber: "6",
    resultDate: "2022-09-18",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-09-19",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "228",
    sumOpenNumber: "2",
    resultDate: "2022-09-20",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "580",
    sumOpenNumber: "3",
    resultDate: "2022-09-21",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-09-22",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2022-09-23",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "379",
    sumOpenNumber: "9",
    resultDate: "2022-09-24",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-09-25",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2022-09-26",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-09-27",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "248",
    sumOpenNumber: "4",
    resultDate: "2022-09-28",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "689",
    sumOpenNumber: "3",
    resultDate: "2022-09-29",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-09-30",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "157",
    sumOpenNumber: "3",
    resultDate: "2022-10-01",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2022-10-02",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2022-10-03",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "469",
    sumOpenNumber: "9",
    resultDate: "2022-10-04",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-10-05",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-10-06",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "134",
    sumOpenNumber: "8",
    resultDate: "2022-10-07",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "268",
    sumOpenNumber: "6",
    resultDate: "2022-10-08",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-10-09",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-10-10",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-10-11",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2022-10-12",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2022-10-13",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2022-10-14",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "356",
    sumOpenNumber: "4",
    resultDate: "2022-10-15",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-10-16",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-10-17",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "177",
    sumOpenNumber: "5",
    resultDate: "2022-10-18",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-10-19",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2022-10-20",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2022-10-21",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2022-10-22",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2022-10-23",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-10-24",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "356",
    sumOpenNumber: "4",
    resultDate: "2022-10-25",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2022-10-26",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2022-10-27",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-10-28",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2022-10-29",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2022-10-30",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "390",
    sumOpenNumber: "2",
    resultDate: "2022-10-31",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "146",
    sumOpenNumber: "1",
    resultDate: "2022-11-01",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2022-11-02",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2022-11-03",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2022-11-04",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "566",
    sumOpenNumber: "7",
    resultDate: "2022-11-05",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "234",
    sumOpenNumber: "9",
    resultDate: "2022-11-06",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-11-07",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-11-08",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2022-11-09",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2022-11-10",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2022-11-11",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "239",
    sumOpenNumber: "4",
    resultDate: "2022-11-12",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-11-13",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-11-14",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "228",
    sumOpenNumber: "2",
    resultDate: "2022-11-15",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "236",
    sumOpenNumber: "1",
    resultDate: "2022-11-16",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2022-11-17",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2022-11-18",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2022-11-19",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-11-20",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2022-11-21",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-11-22",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2022-11-23",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2022-11-24",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "379",
    sumOpenNumber: "9",
    resultDate: "2022-11-25",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "589",
    sumOpenNumber: "2",
    resultDate: "2022-11-26",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2022-11-27",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "369",
    sumOpenNumber: "8",
    resultDate: "2022-11-28",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-11-29",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2022-11-30",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2022-12-01",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2022-12-02",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-12-03",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "337",
    sumOpenNumber: "3",
    resultDate: "2022-12-04",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2022-12-05",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-12-06",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "338",
    sumOpenNumber: "4",
    resultDate: "2022-12-07",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "147",
    sumOpenNumber: "2",
    resultDate: "2022-12-08",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "344",
    sumOpenNumber: "1",
    resultDate: "2022-12-09",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2022-12-10",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "335",
    sumOpenNumber: "1",
    resultDate: "2022-12-11",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2022-12-12",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2022-12-13",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2022-12-14",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "599",
    sumOpenNumber: "3",
    resultDate: "2022-12-15",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-12-16",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2022-12-17",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "166",
    sumOpenNumber: "3",
    resultDate: "2022-12-18",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "238",
    sumOpenNumber: "3",
    resultDate: "2022-12-19",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "580",
    sumOpenNumber: "3",
    resultDate: "2022-12-20",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-12-21",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-12-22",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2022-12-23",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "679",
    sumOpenNumber: "2",
    resultDate: "2022-12-24",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-12-25",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-12-26",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2022-12-27",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "699",
    sumOpenNumber: "4",
    resultDate: "2022-12-28",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "489",
    sumOpenNumber: "1",
    resultDate: "2022-12-29",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "668",
    sumOpenNumber: "0",
    resultDate: "2022-12-30",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "470",
    sumOpenNumber: "1",
    resultDate: "2022-12-31",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "599",
    sumOpenNumber: "3",
    resultDate: "2023-01-01",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2023-01-02",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2023-01-03",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "570",
    sumOpenNumber: "2",
    resultDate: "2023-01-04",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2023-01-05",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2023-01-06",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "259",
    sumOpenNumber: "6",
    resultDate: "2023-01-07",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2023-01-08",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2023-01-09",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-01-10",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "499",
    sumOpenNumber: "2",
    resultDate: "2023-01-11",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "557",
    sumOpenNumber: "7",
    resultDate: "2023-01-12",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "157",
    sumOpenNumber: "3",
    resultDate: "2023-01-13",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "799",
    sumOpenNumber: "5",
    resultDate: "2023-01-14",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "266",
    sumOpenNumber: "4",
    resultDate: "2023-01-15",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "388",
    sumOpenNumber: "9",
    resultDate: "2023-01-16",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "136",
    sumOpenNumber: "0",
    resultDate: "2023-01-17",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "278",
    sumOpenNumber: "7",
    resultDate: "2023-01-18",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-01-19",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2023-01-20",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "366",
    sumOpenNumber: "5",
    resultDate: "2023-01-21",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2023-01-22",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "490",
    sumOpenNumber: "3",
    resultDate: "2023-01-23",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-01-24",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-01-25",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "225",
    sumOpenNumber: "9",
    resultDate: "2023-01-26",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-01-27",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2023-01-28",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "229",
    sumOpenNumber: "3",
    resultDate: "2023-01-29",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "445",
    sumOpenNumber: "3",
    resultDate: "2023-01-30",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "136",
    sumOpenNumber: "0",
    resultDate: "2023-01-31",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "477",
    sumOpenNumber: "8",
    resultDate: "2023-02-01",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-02-02",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "488",
    sumOpenNumber: "0",
    resultDate: "2023-02-03",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "677",
    sumOpenNumber: "0",
    resultDate: "2023-02-04",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2023-02-05",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "388",
    sumOpenNumber: "9",
    resultDate: "2023-02-06",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "226",
    sumOpenNumber: "0",
    resultDate: "2023-02-07",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2023-02-08",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "335",
    sumOpenNumber: "1",
    resultDate: "2023-02-09",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "118",
    sumOpenNumber: "0",
    resultDate: "2023-02-10",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "490",
    sumOpenNumber: "3",
    resultDate: "2023-02-11",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "233",
    sumOpenNumber: "8",
    resultDate: "2023-02-12",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "177",
    sumOpenNumber: "5",
    resultDate: "2023-02-13",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "390",
    sumOpenNumber: "2",
    resultDate: "2023-02-14",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "448",
    sumOpenNumber: "6",
    resultDate: "2023-02-15",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "557",
    sumOpenNumber: "7",
    resultDate: "2023-02-16",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "890",
    sumOpenNumber: "7",
    resultDate: "2023-02-17",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "470",
    sumOpenNumber: "1",
    resultDate: "2023-02-18",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2023-02-19",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-02-20",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "248",
    sumOpenNumber: "4",
    resultDate: "2023-02-21",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2023-02-22",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2023-02-23",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "999",
    sumOpenNumber: "7",
    resultDate: "2023-02-24",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "177",
    sumOpenNumber: "5",
    resultDate: "2023-02-25",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-02-26",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-02-27",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "111",
    sumOpenNumber: "3",
    resultDate: "2023-02-28",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2023-03-01",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "668",
    sumOpenNumber: "0",
    resultDate: "2023-03-02",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "488",
    sumOpenNumber: "0",
    resultDate: "2023-03-03",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "677",
    sumOpenNumber: "0",
    resultDate: "2023-03-04",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2023-03-05",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2023-03-06",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-03-07",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2023-03-08",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2023-03-09",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-03-10",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-03-11",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "499",
    sumOpenNumber: "2",
    resultDate: "2023-03-12",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2023-03-16",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-03-17",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2023-03-18",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-03-19",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-03-20",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2023-03-21",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "244",
    sumOpenNumber: "0",
    resultDate: "2023-03-22",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-03-23",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-03-24",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2023-03-25",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2023-03-26",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "666",
    sumOpenNumber: "8",
    resultDate: "2023-03-27",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2023-03-28",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-03-29",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2023-03-30",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-03-31",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "388",
    sumOpenNumber: "9",
    resultDate: "2023-04-01",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2023-04-02",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-04-03",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "777",
    sumOpenNumber: "1",
    resultDate: "2023-04-04",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "224",
    sumOpenNumber: "8",
    resultDate: "2023-04-05",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "367",
    sumOpenNumber: "6",
    resultDate: "2023-04-06",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "567",
    sumOpenNumber: "8",
    resultDate: "2023-04-07",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "229",
    sumOpenNumber: "3",
    resultDate: "2023-04-08",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "112",
    sumOpenNumber: "4",
    resultDate: "2023-04-09",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-04-10",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2023-04-11",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-04-12",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-04-13",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "0",
    sumOpenNumber: "0",
    resultDate: "2023-04-14",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2023-04-15",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "225",
    sumOpenNumber: "9",
    resultDate: "2023-04-16",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "178",
    sumOpenNumber: "6",
    resultDate: "2023-04-17",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2023-04-18",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "488",
    sumOpenNumber: "0",
    resultDate: "2023-04-19",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "277",
    sumOpenNumber: "6",
    resultDate: "2023-04-20",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "470",
    sumOpenNumber: "1",
    resultDate: "2023-04-21",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-04-22",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2023-04-23",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "228",
    sumOpenNumber: "2",
    resultDate: "2023-04-24",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "455",
    sumOpenNumber: "4",
    resultDate: "2023-04-25",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-04-26",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2023-04-27",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "577",
    sumOpenNumber: "9",
    resultDate: "2023-04-28",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "445",
    sumOpenNumber: "3",
    resultDate: "2023-04-29",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "448",
    sumOpenNumber: "6",
    resultDate: "2023-04-30",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "226",
    sumOpenNumber: "0",
    resultDate: "2023-05-01",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-05-02",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "138",
    sumOpenNumber: "2",
    resultDate: "2023-05-03",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2023-05-04",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "247",
    sumOpenNumber: "3",
    resultDate: "2023-05-05",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "269",
    sumOpenNumber: "7",
    resultDate: "2023-05-06",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2023-05-07",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "288",
    sumOpenNumber: "8",
    resultDate: "2023-05-08",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "899",
    sumOpenNumber: "6",
    resultDate: "2023-05-09",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2023-05-10",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2023-05-11",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "690",
    sumOpenNumber: "5",
    resultDate: "2023-05-12",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2023-05-13",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "770",
    sumOpenNumber: "4",
    resultDate: "2023-05-14",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "247",
    sumOpenNumber: "3",
    resultDate: "2023-05-15",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "224",
    sumOpenNumber: "8",
    resultDate: "2023-05-16",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-05-17",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2023-05-18",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "388",
    sumOpenNumber: "9",
    resultDate: "2023-05-19",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "113",
    sumOpenNumber: "5",
    resultDate: "2023-05-20",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "377",
    sumOpenNumber: "7",
    resultDate: "2023-05-21",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2023-05-22",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2023-05-23",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2023-05-24",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "480",
    sumOpenNumber: "2",
    resultDate: "2023-05-25",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2023-05-26",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "445",
    sumOpenNumber: "3",
    resultDate: "2023-05-27",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2023-05-28",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "449",
    sumOpenNumber: "7",
    resultDate: "2023-05-29",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "668",
    sumOpenNumber: "0",
    resultDate: "2023-05-30",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2023-05-31",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-06-01",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "338",
    sumOpenNumber: "4",
    resultDate: "2023-06-02",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-06-03",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-06-04",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2023-06-05",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2023-06-06",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "399",
    sumOpenNumber: "1",
    resultDate: "2023-06-07",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "478",
    sumOpenNumber: "9",
    resultDate: "2023-06-08",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "448",
    sumOpenNumber: "6",
    resultDate: "2023-06-09",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "888",
    sumOpenNumber: "4",
    resultDate: "2023-06-10",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-06-11",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2023-06-12",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2023-06-13",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "226",
    sumOpenNumber: "0",
    resultDate: "2023-06-14",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-06-15",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2023-06-16",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "490",
    sumOpenNumber: "3",
    resultDate: "2023-06-17",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "344",
    sumOpenNumber: "1",
    resultDate: "2023-06-18",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "388",
    sumOpenNumber: "9",
    resultDate: "2023-06-19",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2023-06-20",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2023-06-21",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "339",
    sumOpenNumber: "5",
    resultDate: "2023-06-22",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "448",
    sumOpenNumber: "6",
    resultDate: "2023-06-23",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "449",
    sumOpenNumber: "7",
    resultDate: "2023-06-24",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-06-25",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "335",
    sumOpenNumber: "1",
    resultDate: "2023-06-26",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "499",
    sumOpenNumber: "2",
    resultDate: "2023-06-27",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2023-06-28",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "247",
    sumOpenNumber: "3",
    resultDate: "2023-06-29",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "388",
    sumOpenNumber: "9",
    resultDate: "2023-06-30",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-07-01",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "799",
    sumOpenNumber: "5",
    resultDate: "2023-07-02",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2023-07-03",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "377",
    sumOpenNumber: "7",
    resultDate: "2023-07-04",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "337",
    sumOpenNumber: "3",
    resultDate: "2023-07-05",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "228",
    sumOpenNumber: "2",
    resultDate: "2023-07-06",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "667",
    sumOpenNumber: "9",
    resultDate: "2023-07-07",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "590",
    sumOpenNumber: "4",
    resultDate: "2023-07-08",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2023-07-09",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "990",
    sumOpenNumber: "8",
    resultDate: "2023-07-10",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "113",
    sumOpenNumber: "5",
    resultDate: "2023-07-11",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2023-07-12",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2023-07-13",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "667",
    sumOpenNumber: "9",
    resultDate: "2023-07-14",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2023-07-15",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2023-07-16",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2023-07-17",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "111",
    sumOpenNumber: "3",
    resultDate: "2023-07-18",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "224",
    sumOpenNumber: "8",
    resultDate: "2023-07-19",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "699",
    sumOpenNumber: "4",
    resultDate: "2023-07-20",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "366",
    sumOpenNumber: "5",
    resultDate: "2023-07-21",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "448",
    sumOpenNumber: "6",
    resultDate: "2023-07-22",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "226",
    sumOpenNumber: "0",
    resultDate: "2023-07-23",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "289",
    sumOpenNumber: "9",
    resultDate: "2023-07-24",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "488",
    sumOpenNumber: "0",
    resultDate: "2023-07-25",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-07-26",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2023-07-27",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "890",
    sumOpenNumber: "7",
    resultDate: "2023-07-28",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "128",
    sumOpenNumber: "1",
    resultDate: "2023-07-29",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2023-07-30",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "390",
    sumOpenNumber: "2",
    resultDate: "2023-07-31",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "770",
    sumOpenNumber: "4",
    resultDate: "2023-08-01",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "259",
    sumOpenNumber: "6",
    resultDate: "2023-08-02",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "348",
    sumOpenNumber: "5",
    resultDate: "2023-08-03",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "226",
    sumOpenNumber: "0",
    resultDate: "2023-08-04",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "558",
    sumOpenNumber: "8",
    resultDate: "2023-08-05",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2023-08-06",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-08-07",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "777",
    sumOpenNumber: "1",
    resultDate: "2023-08-08",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "445",
    sumOpenNumber: "3",
    resultDate: "2023-08-09",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "138",
    sumOpenNumber: "2",
    resultDate: "2023-08-10",
  },
  {
    MarketTime: "11:00:00 AM",
    openNumber: "239",
    sumOpenNumber: "4",
    resultDate: "2023-08-11",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-09-07",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2021-09-08",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-09-09",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2021-09-10",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-09-11",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2021-09-12",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "478",
    sumOpenNumber: "9",
    resultDate: "2021-09-13",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "157",
    sumOpenNumber: "3",
    resultDate: "2021-09-14",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "368",
    sumOpenNumber: "7",
    resultDate: "2021-09-15",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-09-16",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2021-09-17",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "225",
    sumOpenNumber: "9",
    resultDate: "2021-09-18",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "699",
    sumOpenNumber: "4",
    resultDate: "2021-09-19",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-09-20",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2021-09-21",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "336",
    sumOpenNumber: "2",
    resultDate: "2021-09-22",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "348",
    sumOpenNumber: "5",
    resultDate: "2021-09-23",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2021-09-24",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2021-09-25",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2021-09-26",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "113",
    sumOpenNumber: "5",
    resultDate: "2021-09-27",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2021-09-28",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2021-09-29",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-09-30",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2021-10-01",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "167",
    sumOpenNumber: "4",
    resultDate: "2021-10-02",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "666",
    sumOpenNumber: "8",
    resultDate: "2021-10-03",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "277",
    sumOpenNumber: "6",
    resultDate: "2021-10-04",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "269",
    sumOpenNumber: "7",
    resultDate: "2021-10-05",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "557",
    sumOpenNumber: "7",
    resultDate: "2021-10-06",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2021-10-07",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2021-10-08",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2021-10-09",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2021-10-10",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-10-11",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "333",
    sumOpenNumber: "9",
    resultDate: "2021-10-12",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "126",
    sumOpenNumber: "9",
    resultDate: "2021-10-13",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-10-14",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "111",
    sumOpenNumber: "3",
    resultDate: "2021-10-15",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2021-10-16",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "339",
    sumOpenNumber: "5",
    resultDate: "2021-10-17",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "888",
    sumOpenNumber: "4",
    resultDate: "2021-10-18",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2021-10-19",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-10-20",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2021-10-21",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2021-10-22",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2021-10-23",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2021-10-24",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2021-10-25",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2021-10-26",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-10-27",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "266",
    sumOpenNumber: "4",
    resultDate: "2021-10-28",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-10-29",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2021-10-30",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-10-31",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2021-11-01",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2021-11-02",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-11-03",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2021-11-04",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-11-05",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2021-11-06",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2021-11-07",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "236",
    sumOpenNumber: "1",
    resultDate: "2021-11-08",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "770",
    sumOpenNumber: "4",
    resultDate: "2021-11-09",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-11-10",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "117",
    sumOpenNumber: "9",
    resultDate: "2021-11-11",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "0",
    sumOpenNumber: "0",
    resultDate: "2021-11-12",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2021-11-13",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-11-14",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-11-15",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-11-16",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2021-11-17",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-11-18",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2021-11-19",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-11-20",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-11-21",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "999",
    sumOpenNumber: "7",
    resultDate: "2021-11-22",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2021-11-23",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "999",
    sumOpenNumber: "7",
    resultDate: "2021-11-24",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "188",
    sumOpenNumber: "7",
    resultDate: "2021-11-25",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-11-26",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2021-11-27",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-11-28",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "116",
    sumOpenNumber: "8",
    resultDate: "2021-11-29",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "890",
    sumOpenNumber: "7",
    resultDate: "2021-11-30",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-12-01",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2021-12-02",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2021-12-03",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2021-12-04",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2021-12-05",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2021-12-06",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2021-12-07",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2021-12-08",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2021-12-09",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-12-10",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2021-12-11",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "477",
    sumOpenNumber: "8",
    resultDate: "2021-12-12",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "557",
    sumOpenNumber: "7",
    resultDate: "2021-12-13",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2021-12-14",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "339",
    sumOpenNumber: "5",
    resultDate: "2021-12-15",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "690",
    sumOpenNumber: "5",
    resultDate: "2021-12-16",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2021-12-17",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "690",
    sumOpenNumber: "5",
    resultDate: "2021-12-18",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "223",
    sumOpenNumber: "7",
    resultDate: "2021-12-19",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2021-12-20",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2021-12-21",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "448",
    sumOpenNumber: "6",
    resultDate: "2021-12-22",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2021-12-23",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "479",
    sumOpenNumber: "0",
    resultDate: "2021-12-24",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "790",
    sumOpenNumber: "6",
    resultDate: "2021-12-25",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2021-12-26",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "579",
    sumOpenNumber: "1",
    resultDate: "2021-12-27",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2021-12-28",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2021-12-29",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2021-12-30",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "349",
    sumOpenNumber: "6",
    resultDate: "2021-12-31",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "369",
    sumOpenNumber: "8",
    resultDate: "2022-01-01",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2022-01-02",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "346",
    sumOpenNumber: "3",
    resultDate: "2022-01-03",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "577",
    sumOpenNumber: "9",
    resultDate: "2022-01-04",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2022-01-05",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "390",
    sumOpenNumber: "2",
    resultDate: "2022-01-06",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "378",
    sumOpenNumber: "8",
    resultDate: "2022-01-07",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2022-01-08",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2022-01-09",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-01-10",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "567",
    sumOpenNumber: "8",
    resultDate: "2022-01-11",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-01-12",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "268",
    sumOpenNumber: "6",
    resultDate: "2022-01-13",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "146",
    sumOpenNumber: "1",
    resultDate: "2022-01-14",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "378",
    sumOpenNumber: "8",
    resultDate: "2022-01-15",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "479",
    sumOpenNumber: "0",
    resultDate: "2022-01-16",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "457",
    sumOpenNumber: "6",
    resultDate: "2022-01-17",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2022-01-18",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "589",
    sumOpenNumber: "2",
    resultDate: "2022-01-19",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2022-01-20",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "145",
    sumOpenNumber: "0",
    resultDate: "2022-01-21",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2022-01-22",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2022-01-23",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "390",
    sumOpenNumber: "2",
    resultDate: "2022-01-24",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "889",
    sumOpenNumber: "5",
    resultDate: "2022-01-25",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2022-01-26",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2022-01-27",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2022-01-28",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2022-01-29",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-01-30",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2022-01-31",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-02-01",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-02-02",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2022-02-03",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2022-02-04",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2022-02-05",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "990",
    sumOpenNumber: "8",
    resultDate: "2022-02-06",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2022-02-07",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-02-08",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2022-02-09",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "269",
    sumOpenNumber: "7",
    resultDate: "2022-02-10",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-02-11",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "369",
    sumOpenNumber: "8",
    resultDate: "2022-02-12",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "588",
    sumOpenNumber: "1",
    resultDate: "2022-02-13",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "356",
    sumOpenNumber: "4",
    resultDate: "2022-02-14",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-02-15",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2022-02-16",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-02-17",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "489",
    sumOpenNumber: "1",
    resultDate: "2022-02-18",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2022-02-19",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2022-02-20",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2022-02-21",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "567",
    sumOpenNumber: "8",
    resultDate: "2022-02-22",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2022-02-23",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2022-02-24",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "224",
    sumOpenNumber: "8",
    resultDate: "2022-02-25",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-02-26",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "378",
    sumOpenNumber: "8",
    resultDate: "2022-02-27",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-02-28",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "338",
    sumOpenNumber: "4",
    resultDate: "2022-03-01",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "288",
    sumOpenNumber: "8",
    resultDate: "2022-03-02",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2022-03-03",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "570",
    sumOpenNumber: "2",
    resultDate: "2022-03-04",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "689",
    sumOpenNumber: "3",
    resultDate: "2022-03-05",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "188",
    sumOpenNumber: "7",
    resultDate: "2022-03-06",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2022-03-07",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2022-03-08",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2022-03-09",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "390",
    sumOpenNumber: "2",
    resultDate: "2022-03-10",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "489",
    sumOpenNumber: "1",
    resultDate: "2022-03-11",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "679",
    sumOpenNumber: "2",
    resultDate: "2022-03-12",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "128",
    sumOpenNumber: "1",
    resultDate: "2022-03-13",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "890",
    sumOpenNumber: "7",
    resultDate: "2022-03-14",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-03-15",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "559",
    sumOpenNumber: "9",
    resultDate: "2022-03-16",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "490",
    sumOpenNumber: "3",
    resultDate: "2022-03-17",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "115",
    sumOpenNumber: "7",
    resultDate: "2022-03-18",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-03-19",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "690",
    sumOpenNumber: "5",
    resultDate: "2022-03-20",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-03-21",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2022-03-22",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "390",
    sumOpenNumber: "2",
    resultDate: "2022-03-23",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "390",
    sumOpenNumber: "2",
    resultDate: "2022-03-24",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2022-03-25",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2022-03-26",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "369",
    sumOpenNumber: "8",
    resultDate: "2022-03-27",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-03-28",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2022-03-29",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "145",
    sumOpenNumber: "0",
    resultDate: "2022-03-30",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2022-03-31",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-04-01",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "137",
    sumOpenNumber: "1",
    resultDate: "2022-04-02",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2022-04-03",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-04-04",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "790",
    sumOpenNumber: "6",
    resultDate: "2022-04-05",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-04-06",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-04-07",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2022-04-08",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "478",
    sumOpenNumber: "9",
    resultDate: "2022-04-09",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-04-10",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "226",
    sumOpenNumber: "0",
    resultDate: "2022-04-11",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2022-04-12",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "115",
    sumOpenNumber: "7",
    resultDate: "2022-04-13",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "377",
    sumOpenNumber: "7",
    resultDate: "2022-04-14",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2022-04-15",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-04-16",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2022-04-17",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "590",
    sumOpenNumber: "4",
    resultDate: "2022-04-18",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "113",
    sumOpenNumber: "5",
    resultDate: "2022-04-19",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "499",
    sumOpenNumber: "2",
    resultDate: "2022-04-20",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-04-21",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2022-04-22",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "167",
    sumOpenNumber: "4",
    resultDate: "2022-04-23",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2022-04-24",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2022-04-25",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "288",
    sumOpenNumber: "8",
    resultDate: "2022-04-26",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "227",
    sumOpenNumber: "1",
    resultDate: "2022-04-27",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2022-04-28",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "555",
    sumOpenNumber: "5",
    resultDate: "2022-04-29",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2022-04-30",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-05-01",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2022-05-02",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "239",
    sumOpenNumber: "4",
    resultDate: "2022-05-03",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2022-05-04",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "267",
    sumOpenNumber: "5",
    resultDate: "2022-05-05",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "388",
    sumOpenNumber: "9",
    resultDate: "2022-05-06",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-05-07",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "147",
    sumOpenNumber: "2",
    resultDate: "2022-05-08",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "248",
    sumOpenNumber: "4",
    resultDate: "2022-05-09",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "118",
    sumOpenNumber: "0",
    resultDate: "2022-05-10",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "349",
    sumOpenNumber: "6",
    resultDate: "2022-05-11",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-05-12",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "226",
    sumOpenNumber: "0",
    resultDate: "2022-05-13",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2022-05-14",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2022-05-15",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-05-16",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "279",
    sumOpenNumber: "8",
    resultDate: "2022-05-17",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "589",
    sumOpenNumber: "2",
    resultDate: "2022-05-18",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2022-05-19",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "689",
    sumOpenNumber: "3",
    resultDate: "2022-05-20",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2022-05-21",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2022-05-22",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-05-23",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "570",
    sumOpenNumber: "2",
    resultDate: "2022-05-24",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "690",
    sumOpenNumber: "5",
    resultDate: "2022-05-25",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-05-26",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "127",
    sumOpenNumber: "0",
    resultDate: "2022-05-27",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-05-28",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "338",
    sumOpenNumber: "4",
    resultDate: "2022-05-29",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "580",
    sumOpenNumber: "3",
    resultDate: "2022-05-30",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-05-31",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "770",
    sumOpenNumber: "4",
    resultDate: "2022-06-01",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "489",
    sumOpenNumber: "1",
    resultDate: "2022-06-02",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "580",
    sumOpenNumber: "3",
    resultDate: "2022-06-03",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "579",
    sumOpenNumber: "1",
    resultDate: "2022-06-04",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-06-05",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "270",
    sumOpenNumber: "9",
    resultDate: "2022-06-06",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2022-06-07",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-06-08",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "455",
    sumOpenNumber: "4",
    resultDate: "2022-06-09",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "366",
    sumOpenNumber: "5",
    resultDate: "2022-06-10",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-06-11",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-06-12",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "668",
    sumOpenNumber: "0",
    resultDate: "2022-06-13",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-06-14",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "388",
    sumOpenNumber: "9",
    resultDate: "2022-06-15",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2022-06-16",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "390",
    sumOpenNumber: "2",
    resultDate: "2022-06-17",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "590",
    sumOpenNumber: "4",
    resultDate: "2022-06-18",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2022-06-19",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-06-20",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "680",
    sumOpenNumber: "4",
    resultDate: "2022-06-21",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "680",
    sumOpenNumber: "4",
    resultDate: "2022-06-22",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2022-06-23",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-06-24",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "378",
    sumOpenNumber: "8",
    resultDate: "2022-06-25",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-06-26",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2022-06-27",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-06-28",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2022-06-29",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "112",
    sumOpenNumber: "4",
    resultDate: "2022-06-30",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2022-07-01",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-07-02",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "137",
    sumOpenNumber: "1",
    resultDate: "2022-07-03",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "248",
    sumOpenNumber: "4",
    resultDate: "2022-07-04",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "338",
    sumOpenNumber: "4",
    resultDate: "2022-07-05",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-07-06",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "590",
    sumOpenNumber: "4",
    resultDate: "2022-07-07",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2022-07-08",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "148",
    sumOpenNumber: "3",
    resultDate: "2022-07-09",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-07-10",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-07-11",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-07-12",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "156",
    sumOpenNumber: "2",
    resultDate: "2022-07-13",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "570",
    sumOpenNumber: "2",
    resultDate: "2022-07-14",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2022-07-15",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "490",
    sumOpenNumber: "3",
    resultDate: "2022-07-16",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "559",
    sumOpenNumber: "9",
    resultDate: "2022-07-17",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-07-18",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "457",
    sumOpenNumber: "6",
    resultDate: "2022-07-19",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "268",
    sumOpenNumber: "6",
    resultDate: "2022-07-20",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-07-21",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "225",
    sumOpenNumber: "9",
    resultDate: "2022-07-22",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-07-23",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2022-07-24",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "378",
    sumOpenNumber: "8",
    resultDate: "2022-07-25",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2022-07-26",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "580",
    sumOpenNumber: "3",
    resultDate: "2022-07-27",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-07-28",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2022-07-29",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2022-07-30",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "457",
    sumOpenNumber: "6",
    resultDate: "2022-07-31",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "580",
    sumOpenNumber: "3",
    resultDate: "2022-08-01",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2022-08-02",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-08-03",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "479",
    sumOpenNumber: "0",
    resultDate: "2022-08-04",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2022-08-05",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "770",
    sumOpenNumber: "4",
    resultDate: "2022-08-06",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-08-07",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "559",
    sumOpenNumber: "9",
    resultDate: "2022-08-08",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-08-09",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "369",
    sumOpenNumber: "8",
    resultDate: "2022-08-10",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "157",
    sumOpenNumber: "3",
    resultDate: "2022-08-11",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "378",
    sumOpenNumber: "8",
    resultDate: "2022-08-12",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-08-13",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2022-08-14",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2022-08-15",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2022-08-16",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-08-17",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-08-18",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "590",
    sumOpenNumber: "4",
    resultDate: "2022-08-19",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-08-20",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2022-08-21",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-08-22",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "233",
    sumOpenNumber: "8",
    resultDate: "2022-08-23",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "277",
    sumOpenNumber: "6",
    resultDate: "2022-08-24",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "118",
    sumOpenNumber: "0",
    resultDate: "2022-08-25",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2022-08-26",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "228",
    sumOpenNumber: "2",
    resultDate: "2022-08-27",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "145",
    sumOpenNumber: "0",
    resultDate: "2022-08-28",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2022-08-29",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "168",
    sumOpenNumber: "5",
    resultDate: "2022-08-30",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "145",
    sumOpenNumber: "0",
    resultDate: "2022-08-31",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "156",
    sumOpenNumber: "2",
    resultDate: "2022-09-01",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "115",
    sumOpenNumber: "7",
    resultDate: "2022-09-02",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "168",
    sumOpenNumber: "5",
    resultDate: "2022-09-03",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-09-04",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2022-09-05",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2022-09-06",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2022-09-07",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-09-08",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "557",
    sumOpenNumber: "7",
    resultDate: "2022-09-09",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "146",
    sumOpenNumber: "1",
    resultDate: "2022-09-10",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2022-09-11",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-09-12",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "259",
    sumOpenNumber: "6",
    resultDate: "2022-09-13",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2022-09-14",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "478",
    sumOpenNumber: "9",
    resultDate: "2022-09-15",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "570",
    sumOpenNumber: "2",
    resultDate: "2022-09-16",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "470",
    sumOpenNumber: "1",
    resultDate: "2022-09-17",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2022-09-18",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2022-09-19",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-09-20",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2022-09-21",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "680",
    sumOpenNumber: "4",
    resultDate: "2022-09-22",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-09-23",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2022-09-24",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "128",
    sumOpenNumber: "1",
    resultDate: "2022-09-25",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "244",
    sumOpenNumber: "0",
    resultDate: "2022-09-26",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "566",
    sumOpenNumber: "7",
    resultDate: "2022-09-27",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2022-09-28",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2022-09-29",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "579",
    sumOpenNumber: "1",
    resultDate: "2022-09-30",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-10-01",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2022-10-02",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "390",
    sumOpenNumber: "2",
    resultDate: "2022-10-03",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-10-04",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "115",
    sumOpenNumber: "7",
    resultDate: "2022-10-05",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-10-06",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-10-07",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-10-08",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "136",
    sumOpenNumber: "0",
    resultDate: "2022-10-09",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2022-10-10",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2022-10-11",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "469",
    sumOpenNumber: "9",
    resultDate: "2022-10-12",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2022-10-13",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-10-14",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "390",
    sumOpenNumber: "2",
    resultDate: "2022-10-15",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2022-10-16",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-10-17",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2022-10-18",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-10-19",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2022-10-20",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "244",
    sumOpenNumber: "0",
    resultDate: "2022-10-21",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2022-10-22",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "488",
    sumOpenNumber: "0",
    resultDate: "2022-10-23",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-10-24",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2022-10-25",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2022-10-26",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2022-10-27",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2022-10-28",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2022-10-29",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2022-10-30",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2022-10-31",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-11-01",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2022-11-02",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "356",
    sumOpenNumber: "4",
    resultDate: "2022-11-03",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-11-04",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "348",
    sumOpenNumber: "5",
    resultDate: "2022-11-05",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "568",
    sumOpenNumber: "9",
    resultDate: "2022-11-06",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2022-11-07",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "266",
    sumOpenNumber: "4",
    resultDate: "2022-11-08",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-11-09",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2022-11-10",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "890",
    sumOpenNumber: "7",
    resultDate: "2022-11-11",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "399",
    sumOpenNumber: "1",
    resultDate: "2022-11-12",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "368",
    sumOpenNumber: "7",
    resultDate: "2022-11-13",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-11-14",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "689",
    sumOpenNumber: "3",
    resultDate: "2022-11-15",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "134",
    sumOpenNumber: "8",
    resultDate: "2022-11-16",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "367",
    sumOpenNumber: "6",
    resultDate: "2022-11-17",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-11-18",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2022-11-19",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-11-20",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2022-11-21",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2022-11-22",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "890",
    sumOpenNumber: "7",
    resultDate: "2022-11-23",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-11-24",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-11-25",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2022-11-26",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-11-27",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "469",
    sumOpenNumber: "9",
    resultDate: "2022-11-28",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-11-29",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2022-11-30",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-12-01",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "270",
    sumOpenNumber: "9",
    resultDate: "2022-12-02",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-12-03",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2022-12-04",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-12-05",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2022-12-06",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2022-12-07",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2022-12-08",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2022-12-09",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "559",
    sumOpenNumber: "9",
    resultDate: "2022-12-10",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2022-12-11",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "488",
    sumOpenNumber: "0",
    resultDate: "2022-12-12",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2022-12-13",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2022-12-14",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "113",
    sumOpenNumber: "5",
    resultDate: "2022-12-15",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-12-16",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2022-12-17",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "477",
    sumOpenNumber: "8",
    resultDate: "2022-12-18",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2022-12-19",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-12-20",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-12-21",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-12-22",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "112",
    sumOpenNumber: "4",
    resultDate: "2022-12-23",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "480",
    sumOpenNumber: "2",
    resultDate: "2022-12-24",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-12-25",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "277",
    sumOpenNumber: "6",
    resultDate: "2022-12-26",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2022-12-27",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "366",
    sumOpenNumber: "5",
    resultDate: "2022-12-28",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "268",
    sumOpenNumber: "6",
    resultDate: "2022-12-29",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-12-30",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-12-31",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-01-01",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "890",
    sumOpenNumber: "7",
    resultDate: "2023-01-02",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "580",
    sumOpenNumber: "3",
    resultDate: "2023-01-03",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "366",
    sumOpenNumber: "5",
    resultDate: "2023-01-04",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "118",
    sumOpenNumber: "0",
    resultDate: "2023-01-05",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "680",
    sumOpenNumber: "4",
    resultDate: "2023-01-06",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2023-01-07",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2023-01-08",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-01-09",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "359",
    sumOpenNumber: "7",
    resultDate: "2023-01-10",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2023-01-11",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "590",
    sumOpenNumber: "4",
    resultDate: "2023-01-12",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-01-13",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "168",
    sumOpenNumber: "5",
    resultDate: "2023-01-14",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-01-15",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "369",
    sumOpenNumber: "8",
    resultDate: "2023-01-16",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2023-01-17",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "127",
    sumOpenNumber: "0",
    resultDate: "2023-01-18",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-01-19",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2023-01-20",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2023-01-21",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "379",
    sumOpenNumber: "9",
    resultDate: "2023-01-22",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "448",
    sumOpenNumber: "6",
    resultDate: "2023-01-23",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-01-24",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-01-25",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "277",
    sumOpenNumber: "6",
    resultDate: "2023-01-26",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-01-27",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2023-01-28",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2023-01-29",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2023-01-30",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "790",
    sumOpenNumber: "6",
    resultDate: "2023-01-31",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-02-01",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "770",
    sumOpenNumber: "4",
    resultDate: "2023-02-02",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "266",
    sumOpenNumber: "4",
    resultDate: "2023-02-03",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "299",
    sumOpenNumber: "0",
    resultDate: "2023-02-04",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-02-05",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "699",
    sumOpenNumber: "4",
    resultDate: "2023-02-06",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "118",
    sumOpenNumber: "0",
    resultDate: "2023-02-07",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-02-08",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2023-02-09",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-02-10",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-02-11",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2023-02-12",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-02-13",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-02-14",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2023-02-15",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2023-02-16",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "299",
    sumOpenNumber: "0",
    resultDate: "2023-02-17",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2023-02-18",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "690",
    sumOpenNumber: "5",
    resultDate: "2023-02-19",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "379",
    sumOpenNumber: "9",
    resultDate: "2023-02-20",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2023-02-21",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "177",
    sumOpenNumber: "5",
    resultDate: "2023-02-22",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2023-02-23",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "777",
    sumOpenNumber: "1",
    resultDate: "2023-02-24",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-02-25",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "990",
    sumOpenNumber: "8",
    resultDate: "2023-02-26",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2023-02-27",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "667",
    sumOpenNumber: "9",
    resultDate: "2023-02-28",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "890",
    sumOpenNumber: "7",
    resultDate: "2023-03-01",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "777",
    sumOpenNumber: "1",
    resultDate: "2023-03-02",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2023-03-03",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2023-03-04",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "559",
    sumOpenNumber: "9",
    resultDate: "2023-03-05",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "334",
    sumOpenNumber: "0",
    resultDate: "2023-03-06",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "244",
    sumOpenNumber: "0",
    resultDate: "2023-03-07",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2023-03-08",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2023-03-09",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-03-10",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-03-11",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-03-12",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "244",
    sumOpenNumber: "0",
    resultDate: "2023-03-16",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "223",
    sumOpenNumber: "7",
    resultDate: "2023-03-17",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-03-18",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "222",
    sumOpenNumber: "6",
    resultDate: "2023-03-19",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-03-20",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "157",
    sumOpenNumber: "3",
    resultDate: "2023-03-21",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "599",
    sumOpenNumber: "3",
    resultDate: "2023-03-22",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "166",
    sumOpenNumber: "3",
    resultDate: "2023-03-23",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "269",
    sumOpenNumber: "7",
    resultDate: "2023-03-24",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-03-25",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2023-03-26",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-03-27",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2023-03-28",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2023-03-29",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2023-03-30",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-03-31",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "369",
    sumOpenNumber: "8",
    resultDate: "2023-04-01",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "356",
    sumOpenNumber: "4",
    resultDate: "2023-04-02",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-04-03",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "666",
    sumOpenNumber: "8",
    resultDate: "2023-04-04",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "999",
    sumOpenNumber: "7",
    resultDate: "2023-04-05",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "448",
    sumOpenNumber: "6",
    resultDate: "2023-04-06",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-04-07",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "333",
    sumOpenNumber: "9",
    resultDate: "2023-04-08",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "344",
    sumOpenNumber: "1",
    resultDate: "2023-04-09",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "244",
    sumOpenNumber: "0",
    resultDate: "2023-04-10",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-04-11",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2023-04-12",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "559",
    sumOpenNumber: "9",
    resultDate: "2023-04-13",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "336",
    sumOpenNumber: "2",
    resultDate: "2023-04-14",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2023-04-15",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "888",
    sumOpenNumber: "4",
    resultDate: "2023-04-16",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "449",
    sumOpenNumber: "7",
    resultDate: "2023-04-17",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2023-04-18",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2023-04-19",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "699",
    sumOpenNumber: "4",
    resultDate: "2023-04-20",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-04-21",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2023-04-22",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "399",
    sumOpenNumber: "1",
    resultDate: "2023-04-23",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2023-04-24",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "333",
    sumOpenNumber: "9",
    resultDate: "2023-04-25",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "555",
    sumOpenNumber: "5",
    resultDate: "2023-04-26",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-04-27",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-04-28",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "337",
    sumOpenNumber: "3",
    resultDate: "2023-04-29",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2023-04-30",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-05-01",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "334",
    sumOpenNumber: "0",
    resultDate: "2023-05-02",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-05-03",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "177",
    sumOpenNumber: "5",
    resultDate: "2023-05-04",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "369",
    sumOpenNumber: "8",
    resultDate: "2023-05-05",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2023-05-06",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-05-07",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "778",
    sumOpenNumber: "2",
    resultDate: "2023-05-08",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2023-05-09",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-05-10",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-05-11",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "457",
    sumOpenNumber: "6",
    resultDate: "2023-05-12",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2023-05-13",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "399",
    sumOpenNumber: "1",
    resultDate: "2023-05-14",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "778",
    sumOpenNumber: "2",
    resultDate: "2023-05-15",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-05-16",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "689",
    sumOpenNumber: "3",
    resultDate: "2023-05-17",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "444",
    sumOpenNumber: "2",
    resultDate: "2023-05-18",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-05-19",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-05-20",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-05-21",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-05-22",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "111",
    sumOpenNumber: "3",
    resultDate: "2023-05-23",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "177",
    sumOpenNumber: "5",
    resultDate: "2023-05-24",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-05-25",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-05-26",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-05-27",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-05-28",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2023-05-29",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2023-05-30",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2023-05-31",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2023-06-01",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "356",
    sumOpenNumber: "4",
    resultDate: "2023-06-02",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2023-06-03",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-06-04",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "667",
    sumOpenNumber: "9",
    resultDate: "2023-06-05",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "777",
    sumOpenNumber: "1",
    resultDate: "2023-06-06",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-06-07",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-06-08",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "447",
    sumOpenNumber: "5",
    resultDate: "2023-06-09",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "688",
    sumOpenNumber: "2",
    resultDate: "2023-06-10",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-06-11",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2023-06-12",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-06-13",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "288",
    sumOpenNumber: "8",
    resultDate: "2023-06-14",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "227",
    sumOpenNumber: "1",
    resultDate: "2023-06-15",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-06-16",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-06-17",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2023-06-18",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2023-06-19",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2023-06-20",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "249",
    sumOpenNumber: "5",
    resultDate: "2023-06-21",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "266",
    sumOpenNumber: "4",
    resultDate: "2023-06-22",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "449",
    sumOpenNumber: "7",
    resultDate: "2023-06-23",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-06-24",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "227",
    sumOpenNumber: "1",
    resultDate: "2023-06-25",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "388",
    sumOpenNumber: "9",
    resultDate: "2023-06-26",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "478",
    sumOpenNumber: "9",
    resultDate: "2023-06-27",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "377",
    sumOpenNumber: "7",
    resultDate: "2023-06-28",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "488",
    sumOpenNumber: "0",
    resultDate: "2023-06-29",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-06-30",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2023-07-01",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "279",
    sumOpenNumber: "8",
    resultDate: "2023-07-02",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-07-03",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2023-07-04",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "177",
    sumOpenNumber: "5",
    resultDate: "2023-07-05",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "337",
    sumOpenNumber: "3",
    resultDate: "2023-07-06",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2023-07-07",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "266",
    sumOpenNumber: "4",
    resultDate: "2023-07-08",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2023-07-09",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2023-07-10",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-07-11",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-07-12",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "199",
    sumOpenNumber: "9",
    resultDate: "2023-07-13",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-07-14",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "477",
    sumOpenNumber: "8",
    resultDate: "2023-07-15",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-07-16",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2023-07-17",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "556",
    sumOpenNumber: "6",
    resultDate: "2023-07-18",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2023-07-19",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-07-20",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-07-21",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2023-07-22",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2023-07-23",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "399",
    sumOpenNumber: "1",
    resultDate: "2023-07-24",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "224",
    sumOpenNumber: "8",
    resultDate: "2023-07-25",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "488",
    sumOpenNumber: "0",
    resultDate: "2023-07-26",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "111",
    sumOpenNumber: "3",
    resultDate: "2023-07-27",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "367",
    sumOpenNumber: "6",
    resultDate: "2023-07-28",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2023-07-29",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-07-30",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "590",
    sumOpenNumber: "4",
    resultDate: "2023-07-31",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2023-08-01",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "270",
    sumOpenNumber: "9",
    resultDate: "2023-08-02",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "224",
    sumOpenNumber: "8",
    resultDate: "2023-08-03",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2023-08-04",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2023-08-05",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "229",
    sumOpenNumber: "3",
    resultDate: "2023-08-06",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "118",
    sumOpenNumber: "0",
    resultDate: "2023-08-07",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "790",
    sumOpenNumber: "6",
    resultDate: "2023-08-08",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "167",
    sumOpenNumber: "4",
    resultDate: "2023-08-09",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2023-08-10",
  },
  {
    MarketTime: "04:00:00 PM",
    openNumber: "588",
    sumOpenNumber: "1",
    resultDate: "2023-08-11",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "",
    sumOpenNumber: "",
    resultDate: "2021-09-07",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2021-09-08",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2021-09-09",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "556",
    sumOpenNumber: "6",
    resultDate: "2021-09-10",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2021-09-11",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "269",
    sumOpenNumber: "7",
    resultDate: "2021-09-12",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-09-13",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2021-09-14",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2021-09-15",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "117",
    sumOpenNumber: "9",
    resultDate: "2021-09-16",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "779",
    sumOpenNumber: "3",
    resultDate: "2021-09-17",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2021-09-18",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "555",
    sumOpenNumber: "5",
    resultDate: "2021-09-19",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2021-09-20",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "444",
    sumOpenNumber: "2",
    resultDate: "2021-09-21",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2021-09-22",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "888",
    sumOpenNumber: "4",
    resultDate: "2021-09-23",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2021-09-24",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2021-09-25",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2021-09-26",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-09-27",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2021-09-28",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2021-09-29",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2021-09-30",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2021-10-01",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "558",
    sumOpenNumber: "8",
    resultDate: "2021-10-02",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2021-10-03",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "234",
    sumOpenNumber: "9",
    resultDate: "2021-10-04",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2021-10-05",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "333",
    sumOpenNumber: "9",
    resultDate: "2021-10-06",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2021-10-07",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-10-08",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "568",
    sumOpenNumber: "9",
    resultDate: "2021-10-09",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-10-10",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "239",
    sumOpenNumber: "4",
    resultDate: "2021-10-11",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2021-10-12",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2021-10-13",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2021-10-14",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-10-15",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-10-16",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2021-10-17",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-10-18",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "222",
    sumOpenNumber: "6",
    resultDate: "2021-10-19",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2021-10-20",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-10-21",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2021-10-22",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2021-10-23",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-10-24",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2021-10-25",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2021-10-26",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "477",
    sumOpenNumber: "8",
    resultDate: "2021-10-27",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-10-28",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "448",
    sumOpenNumber: "6",
    resultDate: "2021-10-29",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2021-10-30",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-10-31",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2021-11-01",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2021-11-02",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-11-03",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2021-11-04",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-11-05",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2021-11-06",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-11-07",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2021-11-08",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "0",
    sumOpenNumber: "0",
    resultDate: "2021-11-09",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "444",
    sumOpenNumber: "2",
    resultDate: "2021-11-10",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-11-11",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2021-11-12",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-11-13",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2021-11-14",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "489",
    sumOpenNumber: "1",
    resultDate: "2021-11-15",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "470",
    sumOpenNumber: "1",
    resultDate: "2021-11-16",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "777",
    sumOpenNumber: "1",
    resultDate: "2021-11-17",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2021-11-18",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-11-19",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2021-11-20",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2021-11-21",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2021-11-22",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-11-23",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2021-11-24",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2021-11-25",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "888",
    sumOpenNumber: "4",
    resultDate: "2021-11-26",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-11-27",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "449",
    sumOpenNumber: "7",
    resultDate: "2021-11-28",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2021-11-29",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "222",
    sumOpenNumber: "6",
    resultDate: "2021-11-30",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "999",
    sumOpenNumber: "7",
    resultDate: "2021-12-01",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2021-12-02",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "111",
    sumOpenNumber: "3",
    resultDate: "2021-12-03",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2021-12-04",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-12-05",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "114",
    sumOpenNumber: "6",
    resultDate: "2021-12-06",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2021-12-07",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2021-12-08",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "222",
    sumOpenNumber: "6",
    resultDate: "2021-12-09",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2021-12-10",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-12-11",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2021-12-12",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2021-12-13",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2021-12-14",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2021-12-15",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2021-12-16",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "790",
    sumOpenNumber: "6",
    resultDate: "2021-12-17",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "112",
    sumOpenNumber: "4",
    resultDate: "2021-12-18",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2021-12-19",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2021-12-20",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2021-12-21",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "157",
    sumOpenNumber: "3",
    resultDate: "2021-12-22",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "248",
    sumOpenNumber: "4",
    resultDate: "2021-12-23",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2021-12-24",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2021-12-25",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2021-12-26",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "679",
    sumOpenNumber: "2",
    resultDate: "2021-12-27",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "126",
    sumOpenNumber: "9",
    resultDate: "2021-12-28",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2021-12-29",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2021-12-30",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "557",
    sumOpenNumber: "7",
    resultDate: "2021-12-31",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-01-01",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "477",
    sumOpenNumber: "8",
    resultDate: "2022-01-02",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "479",
    sumOpenNumber: "0",
    resultDate: "2022-01-03",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "590",
    sumOpenNumber: "4",
    resultDate: "2022-01-04",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "188",
    sumOpenNumber: "7",
    resultDate: "2022-01-05",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-01-06",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "689",
    sumOpenNumber: "3",
    resultDate: "2022-01-07",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "244",
    sumOpenNumber: "0",
    resultDate: "2022-01-08",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-01-09",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-01-10",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "334",
    sumOpenNumber: "0",
    resultDate: "2022-01-11",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "589",
    sumOpenNumber: "2",
    resultDate: "2022-01-12",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-01-13",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "470",
    sumOpenNumber: "1",
    resultDate: "2022-01-14",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2022-01-15",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "223",
    sumOpenNumber: "7",
    resultDate: "2022-01-16",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-01-17",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2022-01-18",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "145",
    sumOpenNumber: "0",
    resultDate: "2022-01-19",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "778",
    sumOpenNumber: "2",
    resultDate: "2022-01-20",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "449",
    sumOpenNumber: "7",
    resultDate: "2022-01-21",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-01-22",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2022-01-23",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2022-01-24",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "134",
    sumOpenNumber: "8",
    resultDate: "2022-01-25",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2022-01-26",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "236",
    sumOpenNumber: "1",
    resultDate: "2022-01-27",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2022-01-28",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2022-01-29",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "479",
    sumOpenNumber: "0",
    resultDate: "2022-01-30",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "778",
    sumOpenNumber: "2",
    resultDate: "2022-01-31",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2022-02-01",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2022-02-02",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "470",
    sumOpenNumber: "1",
    resultDate: "2022-02-03",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "227",
    sumOpenNumber: "1",
    resultDate: "2022-02-04",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-02-05",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "334",
    sumOpenNumber: "0",
    resultDate: "2022-02-06",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-02-07",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-02-08",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "259",
    sumOpenNumber: "6",
    resultDate: "2022-02-09",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2022-02-10",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2022-02-11",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "299",
    sumOpenNumber: "0",
    resultDate: "2022-02-12",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2022-02-13",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "156",
    sumOpenNumber: "2",
    resultDate: "2022-02-14",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2022-02-15",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-02-16",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2022-02-17",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "112",
    sumOpenNumber: "4",
    resultDate: "2022-02-18",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "115",
    sumOpenNumber: "7",
    resultDate: "2022-02-19",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "356",
    sumOpenNumber: "4",
    resultDate: "2022-02-20",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2022-02-21",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "390",
    sumOpenNumber: "2",
    resultDate: "2022-02-22",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "668",
    sumOpenNumber: "0",
    resultDate: "2022-02-23",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "790",
    sumOpenNumber: "6",
    resultDate: "2022-02-24",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-02-25",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "199",
    sumOpenNumber: "9",
    resultDate: "2022-02-26",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "138",
    sumOpenNumber: "2",
    resultDate: "2022-02-27",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "289",
    sumOpenNumber: "9",
    resultDate: "2022-02-28",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-03-01",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2022-03-02",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2022-03-03",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-03-04",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "299",
    sumOpenNumber: "0",
    resultDate: "2022-03-05",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "229",
    sumOpenNumber: "3",
    resultDate: "2022-03-06",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-03-07",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "479",
    sumOpenNumber: "0",
    resultDate: "2022-03-08",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "590",
    sumOpenNumber: "4",
    resultDate: "2022-03-09",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "689",
    sumOpenNumber: "3",
    resultDate: "2022-03-10",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "379",
    sumOpenNumber: "9",
    resultDate: "2022-03-11",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "889",
    sumOpenNumber: "5",
    resultDate: "2022-03-12",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-03-13",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2022-03-14",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-03-15",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "690",
    sumOpenNumber: "5",
    resultDate: "2022-03-16",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "259",
    sumOpenNumber: "6",
    resultDate: "2022-03-17",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "690",
    sumOpenNumber: "5",
    resultDate: "2022-03-18",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-03-19",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "469",
    sumOpenNumber: "9",
    resultDate: "2022-03-20",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2022-03-21",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-03-22",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "359",
    sumOpenNumber: "7",
    resultDate: "2022-03-23",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2022-03-24",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-03-25",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-03-26",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2022-03-27",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2022-03-28",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2022-03-29",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-03-30",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-03-31",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "457",
    sumOpenNumber: "6",
    resultDate: "2022-04-01",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "188",
    sumOpenNumber: "7",
    resultDate: "2022-04-02",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2022-04-03",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "136",
    sumOpenNumber: "0",
    resultDate: "2022-04-04",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2022-04-05",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-04-06",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "279",
    sumOpenNumber: "8",
    resultDate: "2022-04-07",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "699",
    sumOpenNumber: "4",
    resultDate: "2022-04-08",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2022-04-09",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "270",
    sumOpenNumber: "9",
    resultDate: "2022-04-10",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "799",
    sumOpenNumber: "5",
    resultDate: "2022-04-11",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-04-12",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "590",
    sumOpenNumber: "4",
    resultDate: "2022-04-13",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "499",
    sumOpenNumber: "2",
    resultDate: "2022-04-14",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2022-04-15",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "990",
    sumOpenNumber: "8",
    resultDate: "2022-04-16",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "338",
    sumOpenNumber: "4",
    resultDate: "2022-04-17",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2022-04-18",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "127",
    sumOpenNumber: "0",
    resultDate: "2022-04-19",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-04-20",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "378",
    sumOpenNumber: "8",
    resultDate: "2022-04-21",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2022-04-22",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "356",
    sumOpenNumber: "4",
    resultDate: "2022-04-23",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2022-04-24",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2022-04-25",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2022-04-26",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2022-04-27",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-04-28",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-04-29",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-04-30",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "148",
    sumOpenNumber: "3",
    resultDate: "2022-05-01",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2022-05-02",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-05-03",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "489",
    sumOpenNumber: "1",
    resultDate: "2022-05-04",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-05-05",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2022-05-06",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "259",
    sumOpenNumber: "6",
    resultDate: "2022-05-07",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2022-05-08",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "799",
    sumOpenNumber: "5",
    resultDate: "2022-05-09",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-05-10",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2022-05-11",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "336",
    sumOpenNumber: "2",
    resultDate: "2022-05-12",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-05-13",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2022-05-14",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "338",
    sumOpenNumber: "4",
    resultDate: "2022-05-15",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "136",
    sumOpenNumber: "0",
    resultDate: "2022-05-16",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2022-05-17",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "177",
    sumOpenNumber: "5",
    resultDate: "2022-05-18",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2022-05-19",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "770",
    sumOpenNumber: "4",
    resultDate: "2022-05-20",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "469",
    sumOpenNumber: "9",
    resultDate: "2022-05-21",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-05-22",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2022-05-23",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "790",
    sumOpenNumber: "6",
    resultDate: "2022-05-24",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-05-25",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2022-05-26",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "690",
    sumOpenNumber: "5",
    resultDate: "2022-05-27",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2022-05-28",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2022-05-29",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-05-30",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "580",
    sumOpenNumber: "3",
    resultDate: "2022-05-31",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2022-06-01",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "359",
    sumOpenNumber: "7",
    resultDate: "2022-06-02",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2022-06-03",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-06-04",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2022-06-05",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-06-06",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2022-06-07",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-06-08",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2022-06-09",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "156",
    sumOpenNumber: "2",
    resultDate: "2022-06-10",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "890",
    sumOpenNumber: "7",
    resultDate: "2022-06-11",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "570",
    sumOpenNumber: "2",
    resultDate: "2022-06-12",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "558",
    sumOpenNumber: "8",
    resultDate: "2022-06-13",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "248",
    sumOpenNumber: "4",
    resultDate: "2022-06-14",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "177",
    sumOpenNumber: "5",
    resultDate: "2022-06-15",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-06-16",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "199",
    sumOpenNumber: "9",
    resultDate: "2022-06-17",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "249",
    sumOpenNumber: "5",
    resultDate: "2022-06-18",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "378",
    sumOpenNumber: "8",
    resultDate: "2022-06-19",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "489",
    sumOpenNumber: "1",
    resultDate: "2022-06-20",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2022-06-21",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2022-06-22",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2022-06-23",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-06-24",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "117",
    sumOpenNumber: "9",
    resultDate: "2022-06-25",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "156",
    sumOpenNumber: "2",
    resultDate: "2022-06-26",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2022-06-27",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2022-06-28",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "126",
    sumOpenNumber: "9",
    resultDate: "2022-06-29",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "178",
    sumOpenNumber: "6",
    resultDate: "2022-06-30",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "277",
    sumOpenNumber: "6",
    resultDate: "2022-07-01",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "566",
    sumOpenNumber: "7",
    resultDate: "2022-07-02",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "377",
    sumOpenNumber: "7",
    resultDate: "2022-07-03",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "580",
    sumOpenNumber: "3",
    resultDate: "2022-07-04",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2022-07-05",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "224",
    sumOpenNumber: "8",
    resultDate: "2022-07-06",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "236",
    sumOpenNumber: "1",
    resultDate: "2022-07-07",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2022-07-08",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2022-07-09",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2022-07-10",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-07-11",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2022-07-12",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-07-13",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "113",
    sumOpenNumber: "5",
    resultDate: "2022-07-14",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2022-07-15",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-07-16",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2022-07-17",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2022-07-18",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2022-07-19",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2022-07-20",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-07-21",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "470",
    sumOpenNumber: "1",
    resultDate: "2022-07-22",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2022-07-23",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2022-07-24",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-07-25",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "359",
    sumOpenNumber: "7",
    resultDate: "2022-07-26",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2022-07-27",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-07-28",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2022-07-29",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "478",
    sumOpenNumber: "9",
    resultDate: "2022-07-30",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2022-07-31",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2022-08-01",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2022-08-02",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2022-08-03",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2022-08-04",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2022-08-05",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-08-06",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "379",
    sumOpenNumber: "9",
    resultDate: "2022-08-07",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2022-08-08",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2022-08-09",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "359",
    sumOpenNumber: "7",
    resultDate: "2022-08-10",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-08-11",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-08-12",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "278",
    sumOpenNumber: "7",
    resultDate: "2022-08-13",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2022-08-14",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-08-15",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2022-08-16",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2022-08-17",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-08-18",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2022-08-19",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-08-20",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-08-21",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2022-08-22",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-08-23",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "157",
    sumOpenNumber: "3",
    resultDate: "2022-08-24",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "889",
    sumOpenNumber: "5",
    resultDate: "2022-08-25",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2022-08-26",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-08-27",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2022-08-28",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "249",
    sumOpenNumber: "5",
    resultDate: "2022-08-29",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "157",
    sumOpenNumber: "3",
    resultDate: "2022-08-30",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2022-08-31",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2022-09-01",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2022-09-03",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2022-09-04",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "790",
    sumOpenNumber: "6",
    resultDate: "2022-09-05",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "270",
    sumOpenNumber: "9",
    resultDate: "2022-09-06",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2022-09-07",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2022-09-08",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-09-09",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-09-10",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-09-11",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "590",
    sumOpenNumber: "4",
    resultDate: "2022-09-12",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "479",
    sumOpenNumber: "0",
    resultDate: "2022-09-13",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2022-09-14",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "770",
    sumOpenNumber: "4",
    resultDate: "2022-09-15",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "268",
    sumOpenNumber: "6",
    resultDate: "2022-09-16",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "568",
    sumOpenNumber: "9",
    resultDate: "2022-09-17",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-09-18",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-09-19",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-09-20",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "367",
    sumOpenNumber: "6",
    resultDate: "2022-09-21",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2022-09-23",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-09-24",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2022-09-25",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-09-26",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "234",
    sumOpenNumber: "9",
    resultDate: "2022-09-27",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "679",
    sumOpenNumber: "2",
    resultDate: "2022-09-28",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2022-09-29",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2022-09-30",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2022-10-01",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2022-10-02",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "488",
    sumOpenNumber: "0",
    resultDate: "2022-10-03",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2022-10-04",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2022-10-05",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-10-06",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-10-07",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-10-08",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2022-10-09",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2022-10-10",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-10-11",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2022-10-12",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "478",
    sumOpenNumber: "9",
    resultDate: "2022-10-13",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-10-14",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2022-10-15",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "369",
    sumOpenNumber: "8",
    resultDate: "2022-10-16",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "344",
    sumOpenNumber: "1",
    resultDate: "2022-10-17",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-10-19",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-10-20",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2022-10-21",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2022-10-22",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "379",
    sumOpenNumber: "9",
    resultDate: "2022-10-23",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "157",
    sumOpenNumber: "3",
    resultDate: "2022-10-24",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "368",
    sumOpenNumber: "7",
    resultDate: "2022-10-25",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-10-26",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2022-10-27",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "479",
    sumOpenNumber: "0",
    resultDate: "2022-10-28",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "234",
    sumOpenNumber: "9",
    resultDate: "2022-10-29",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-10-30",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2022-10-31",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2022-11-01",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "145",
    sumOpenNumber: "0",
    resultDate: "2022-11-02",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "366",
    sumOpenNumber: "5",
    resultDate: "2022-11-03",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2022-11-04",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2022-11-05",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-11-06",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "134",
    sumOpenNumber: "8",
    resultDate: "2022-11-07",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "378",
    sumOpenNumber: "8",
    resultDate: "2022-11-08",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2022-11-09",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-11-10",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2022-11-11",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "236",
    sumOpenNumber: "1",
    resultDate: "2022-11-12",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "248",
    sumOpenNumber: "4",
    resultDate: "2022-11-13",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "478",
    sumOpenNumber: "9",
    resultDate: "2022-11-14",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "479",
    sumOpenNumber: "0",
    resultDate: "2022-11-15",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "127",
    sumOpenNumber: "0",
    resultDate: "2022-11-16",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "567",
    sumOpenNumber: "8",
    resultDate: "2022-11-17",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2022-11-18",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-11-19",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "289",
    sumOpenNumber: "9",
    resultDate: "2022-11-20",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2022-11-21",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2022-11-22",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-11-23",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "890",
    sumOpenNumber: "7",
    resultDate: "2022-11-24",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "680",
    sumOpenNumber: "4",
    resultDate: "2022-11-25",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2022-11-26",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "566",
    sumOpenNumber: "7",
    resultDate: "2022-11-27",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "136",
    sumOpenNumber: "0",
    resultDate: "2022-11-28",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2022-11-29",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-11-30",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "680",
    sumOpenNumber: "4",
    resultDate: "2022-12-01",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "770",
    sumOpenNumber: "4",
    resultDate: "2022-12-02",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "579",
    sumOpenNumber: "1",
    resultDate: "2022-12-03",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-12-04",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "445",
    sumOpenNumber: "3",
    resultDate: "2022-12-05",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "147",
    sumOpenNumber: "2",
    resultDate: "2022-12-06",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "248",
    sumOpenNumber: "4",
    resultDate: "2022-12-07",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "369",
    sumOpenNumber: "8",
    resultDate: "2022-12-08",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "790",
    sumOpenNumber: "6",
    resultDate: "2022-12-09",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2022-12-10",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-12-11",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2022-12-12",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2022-12-13",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2022-12-14",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2022-12-15",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2022-12-16",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-12-17",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2022-12-18",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-12-19",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "677",
    sumOpenNumber: "0",
    resultDate: "2022-12-20",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "990",
    sumOpenNumber: "8",
    resultDate: "2022-12-21",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-12-22",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2022-12-23",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2022-12-24",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2022-12-25",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2022-12-26",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2022-12-27",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2022-12-28",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2022-12-29",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "445",
    sumOpenNumber: "3",
    resultDate: "2022-12-30",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "270",
    sumOpenNumber: "9",
    resultDate: "2022-12-31",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-01-01",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "335",
    sumOpenNumber: "1",
    resultDate: "2023-01-02",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "777",
    sumOpenNumber: "1",
    resultDate: "2023-01-03",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-01-04",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2023-01-05",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "199",
    sumOpenNumber: "9",
    resultDate: "2023-01-06",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "470",
    sumOpenNumber: "1",
    resultDate: "2023-01-07",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2023-01-08",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "469",
    sumOpenNumber: "9",
    resultDate: "2023-01-09",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2023-01-10",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2023-01-11",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "137",
    sumOpenNumber: "1",
    resultDate: "2023-01-12",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-01-13",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "145",
    sumOpenNumber: "0",
    resultDate: "2023-01-14",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2023-01-15",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2023-01-16",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2023-01-17",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "239",
    sumOpenNumber: "4",
    resultDate: "2023-01-18",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "338",
    sumOpenNumber: "4",
    resultDate: "2023-01-19",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2023-01-20",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "367",
    sumOpenNumber: "6",
    resultDate: "2023-01-21",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2023-01-22",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-01-23",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-01-24",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "157",
    sumOpenNumber: "3",
    resultDate: "2023-01-25",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "338",
    sumOpenNumber: "4",
    resultDate: "2023-01-26",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2023-01-27",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "990",
    sumOpenNumber: "8",
    resultDate: "2023-01-28",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "156",
    sumOpenNumber: "2",
    resultDate: "2023-01-29",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "238",
    sumOpenNumber: "3",
    resultDate: "2023-01-30",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2023-01-31",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "359",
    sumOpenNumber: "7",
    resultDate: "2023-02-01",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2023-02-02",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "118",
    sumOpenNumber: "0",
    resultDate: "2023-02-03",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-02-04",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "224",
    sumOpenNumber: "8",
    resultDate: "2023-02-05",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "228",
    sumOpenNumber: "2",
    resultDate: "2023-02-06",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "455",
    sumOpenNumber: "4",
    resultDate: "2023-02-07",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-02-08",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2023-02-09",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-02-10",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-02-11",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "167",
    sumOpenNumber: "4",
    resultDate: "2023-02-12",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "226",
    sumOpenNumber: "0",
    resultDate: "2023-02-13",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "680",
    sumOpenNumber: "4",
    resultDate: "2023-02-14",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-02-15",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-02-16",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "188",
    sumOpenNumber: "7",
    resultDate: "2023-02-17",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2023-02-18",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "238",
    sumOpenNumber: "3",
    resultDate: "2023-02-19",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2023-02-20",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-02-21",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-02-22",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "223",
    sumOpenNumber: "7",
    resultDate: "2023-02-23",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2023-02-24",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "112",
    sumOpenNumber: "4",
    resultDate: "2023-02-25",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-02-26",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-02-27",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2023-02-28",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "680",
    sumOpenNumber: "4",
    resultDate: "2023-03-01",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2023-03-02",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "588",
    sumOpenNumber: "1",
    resultDate: "2023-03-03",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "166",
    sumOpenNumber: "3",
    resultDate: "2023-03-04",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-03-05",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2023-03-06",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-03-07",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-03-08",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "339",
    sumOpenNumber: "5",
    resultDate: "2023-03-10",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-03-11",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "147",
    sumOpenNumber: "2",
    resultDate: "2023-03-12",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "222",
    sumOpenNumber: "6",
    resultDate: "2023-03-16",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "448",
    sumOpenNumber: "6",
    resultDate: "2023-03-17",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "227",
    sumOpenNumber: "1",
    resultDate: "2023-03-18",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "445",
    sumOpenNumber: "3",
    resultDate: "2023-03-19",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2023-03-20",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-03-21",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "477",
    sumOpenNumber: "8",
    resultDate: "2023-03-22",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-03-23",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-03-24",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2023-03-25",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "336",
    sumOpenNumber: "2",
    resultDate: "2023-03-26",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "778",
    sumOpenNumber: "2",
    resultDate: "2023-03-27",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2023-03-28",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "299",
    sumOpenNumber: "0",
    resultDate: "2023-03-29",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2023-03-30",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2023-03-31",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2023-04-01",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2023-04-02",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-04-03",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-04-04",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-04-05",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-04-06",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "177",
    sumOpenNumber: "5",
    resultDate: "2023-04-07",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-04-08",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "455",
    sumOpenNumber: "4",
    resultDate: "2023-04-09",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "344",
    sumOpenNumber: "1",
    resultDate: "2023-04-10",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-04-11",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-04-12",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2023-04-13",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-04-14",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "288",
    sumOpenNumber: "8",
    resultDate: "2023-04-15",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "336",
    sumOpenNumber: "2",
    resultDate: "2023-04-16",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "667",
    sumOpenNumber: "9",
    resultDate: "2023-04-17",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "566",
    sumOpenNumber: "7",
    resultDate: "2023-04-18",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "224",
    sumOpenNumber: "8",
    resultDate: "2023-04-19",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "127",
    sumOpenNumber: "0",
    resultDate: "2023-04-20",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-04-21",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "289",
    sumOpenNumber: "9",
    resultDate: "2023-04-22",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-04-23",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2023-04-24",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2023-04-25",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2023-04-26",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-04-27",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2023-04-28",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "688",
    sumOpenNumber: "2",
    resultDate: "2023-04-29",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2023-04-30",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2023-05-01",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "557",
    sumOpenNumber: "7",
    resultDate: "2023-05-02",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-05-03",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2023-05-04",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "249",
    sumOpenNumber: "5",
    resultDate: "2023-05-05",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "147",
    sumOpenNumber: "2",
    resultDate: "2023-05-06",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2023-05-07",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-05-08",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "990",
    sumOpenNumber: "8",
    resultDate: "2023-05-09",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "333",
    sumOpenNumber: "9",
    resultDate: "2023-05-10",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "556",
    sumOpenNumber: "6",
    resultDate: "2023-05-11",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "127",
    sumOpenNumber: "0",
    resultDate: "2023-05-12",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2023-05-13",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "166",
    sumOpenNumber: "3",
    resultDate: "2023-05-14",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "666",
    sumOpenNumber: "8",
    resultDate: "2023-05-15",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "166",
    sumOpenNumber: "3",
    resultDate: "2023-05-16",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-05-17",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-05-18",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "777",
    sumOpenNumber: "1",
    resultDate: "2023-05-19",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2023-05-20",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-05-21",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "599",
    sumOpenNumber: "3",
    resultDate: "2023-05-22",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2023-05-23",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "777",
    sumOpenNumber: "1",
    resultDate: "2023-05-24",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2023-05-25",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-05-26",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-05-27",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "337",
    sumOpenNumber: "3",
    resultDate: "2023-05-28",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "367",
    sumOpenNumber: "6",
    resultDate: "2023-05-29",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-05-30",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2023-05-31",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "566",
    sumOpenNumber: "7",
    resultDate: "2023-06-01",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "679",
    sumOpenNumber: "2",
    resultDate: "2023-06-02",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2023-06-03",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2023-06-04",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-06-05",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "667",
    sumOpenNumber: "9",
    resultDate: "2023-06-06",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2023-06-07",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2023-06-08",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "366",
    sumOpenNumber: "5",
    resultDate: "2023-06-09",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2023-06-10",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "113",
    sumOpenNumber: "5",
    resultDate: "2023-06-11",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2023-06-12",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "390",
    sumOpenNumber: "2",
    resultDate: "2023-06-13",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-06-14",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "269",
    sumOpenNumber: "7",
    resultDate: "2023-06-15",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "227",
    sumOpenNumber: "1",
    resultDate: "2023-06-16",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "346",
    sumOpenNumber: "3",
    resultDate: "2023-06-17",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2023-06-18",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "889",
    sumOpenNumber: "5",
    resultDate: "2023-06-19",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "577",
    sumOpenNumber: "9",
    resultDate: "2023-06-20",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-06-21",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "667",
    sumOpenNumber: "9",
    resultDate: "2023-06-22",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2023-06-23",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2023-06-24",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "558",
    sumOpenNumber: "8",
    resultDate: "2023-06-25",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2023-06-26",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "244",
    sumOpenNumber: "0",
    resultDate: "2023-06-27",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "299",
    sumOpenNumber: "0",
    resultDate: "2023-06-28",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "269",
    sumOpenNumber: "7",
    resultDate: "2023-06-29",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2023-06-30",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2023-07-01",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2023-07-02",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "444",
    sumOpenNumber: "2",
    resultDate: "2023-07-03",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2023-07-04",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "344",
    sumOpenNumber: "1",
    resultDate: "2023-07-05",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "679",
    sumOpenNumber: "2",
    resultDate: "2023-07-06",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-07-07",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "779",
    sumOpenNumber: "3",
    resultDate: "2023-07-08",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "899",
    sumOpenNumber: "6",
    resultDate: "2023-07-09",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-07-10",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "999",
    sumOpenNumber: "7",
    resultDate: "2023-07-11",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2023-07-12",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "777",
    sumOpenNumber: "1",
    resultDate: "2023-07-13",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "127",
    sumOpenNumber: "0",
    resultDate: "2023-07-14",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2023-07-15",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-07-16",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "367",
    sumOpenNumber: "6",
    resultDate: "2023-07-17",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2023-07-18",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "770",
    sumOpenNumber: "4",
    resultDate: "2023-07-19",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "334",
    sumOpenNumber: "0",
    resultDate: "2023-07-20",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2023-07-21",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "366",
    sumOpenNumber: "5",
    resultDate: "2023-07-22",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2023-07-23",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2023-07-24",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2023-07-25",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2023-07-26",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "270",
    sumOpenNumber: "9",
    resultDate: "2023-07-27",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2023-07-28",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "277",
    sumOpenNumber: "6",
    resultDate: "2023-07-29",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2023-07-30",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "336",
    sumOpenNumber: "2",
    resultDate: "2023-07-31",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "779",
    sumOpenNumber: "3",
    resultDate: "2023-08-01",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-08-02",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "118",
    sumOpenNumber: "0",
    resultDate: "2023-08-03",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "335",
    sumOpenNumber: "1",
    resultDate: "2023-08-04",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "234",
    sumOpenNumber: "9",
    resultDate: "2023-08-05",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "770",
    sumOpenNumber: "4",
    resultDate: "2023-08-06",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "799",
    sumOpenNumber: "5",
    resultDate: "2023-08-07",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2023-08-08",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "336",
    sumOpenNumber: "2",
    resultDate: "2023-08-09",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "148",
    sumOpenNumber: "3",
    resultDate: "2023-08-10",
  },
  {
    MarketTime: "06:00:00 PM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2023-08-11",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2021-09-07",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2021-09-08",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2021-09-09",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2021-09-10",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2021-09-11",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-09-12",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-09-13",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2021-09-14",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "348",
    sumOpenNumber: "5",
    resultDate: "2021-09-15",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2021-09-16",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2021-09-17",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2021-09-18",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "568",
    sumOpenNumber: "9",
    resultDate: "2021-09-19",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "117",
    sumOpenNumber: "9",
    resultDate: "2021-09-20",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "238",
    sumOpenNumber: "3",
    resultDate: "2021-09-21",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2021-09-22",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2021-09-23",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "666",
    sumOpenNumber: "8",
    resultDate: "2021-09-24",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2021-09-25",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "147",
    sumOpenNumber: "2",
    resultDate: "2021-09-26",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "457",
    sumOpenNumber: "6",
    resultDate: "2021-09-27",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "666",
    sumOpenNumber: "8",
    resultDate: "2021-09-28",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2021-09-29",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "799",
    sumOpenNumber: "5",
    resultDate: "2021-09-30",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "145",
    sumOpenNumber: "0",
    resultDate: "2021-10-01",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2021-10-02",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "888",
    sumOpenNumber: "4",
    resultDate: "2021-10-03",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "770",
    sumOpenNumber: "4",
    resultDate: "2021-10-04",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-10-05",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2021-10-06",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2021-10-07",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "477",
    sumOpenNumber: "8",
    resultDate: "2021-10-08",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2021-10-09",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "399",
    sumOpenNumber: "1",
    resultDate: "2021-10-10",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-10-11",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2021-10-12",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-10-13",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "568",
    sumOpenNumber: "9",
    resultDate: "2021-10-14",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "112",
    sumOpenNumber: "4",
    resultDate: "2021-10-15",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-10-16",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2021-10-17",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2021-10-18",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-10-19",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2021-10-20",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2021-10-21",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2021-10-22",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "488",
    sumOpenNumber: "0",
    resultDate: "2021-10-23",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2021-10-24",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-10-25",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-10-26",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "334",
    sumOpenNumber: "0",
    resultDate: "2021-10-27",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2021-10-28",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2021-10-29",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2021-10-30",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2021-10-31",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2021-11-01",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-11-02",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2021-11-03",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2021-11-04",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "479",
    sumOpenNumber: "0",
    resultDate: "2021-11-05",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2021-11-06",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-11-07",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "177",
    sumOpenNumber: "5",
    resultDate: "2021-11-08",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2021-11-09",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-11-10",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-11-11",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2021-11-12",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2021-11-13",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2021-11-14",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-11-15",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2021-11-16",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-11-17",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2021-11-18",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2021-11-19",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2021-11-20",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "118",
    sumOpenNumber: "0",
    resultDate: "2021-11-21",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2021-11-22",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-11-23",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-11-24",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "247",
    sumOpenNumber: "3",
    resultDate: "2021-11-25",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "567",
    sumOpenNumber: "8",
    resultDate: "2021-11-26",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2021-11-27",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2021-11-28",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-11-29",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-11-30",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2021-12-01",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2021-12-02",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "588",
    sumOpenNumber: "1",
    resultDate: "2021-12-03",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2021-12-04",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-12-05",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-12-06",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2021-12-07",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-12-08",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-12-09",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2021-12-10",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2021-12-11",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "369",
    sumOpenNumber: "8",
    resultDate: "2021-12-12",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2021-12-13",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "690",
    sumOpenNumber: "5",
    resultDate: "2021-12-14",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2021-12-15",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "990",
    sumOpenNumber: "8",
    resultDate: "2021-12-16",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2021-12-17",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2021-12-18",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "348",
    sumOpenNumber: "5",
    resultDate: "2021-12-19",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2021-12-20",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2021-12-21",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2021-12-22",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "289",
    sumOpenNumber: "9",
    resultDate: "2021-12-23",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2021-12-24",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "990",
    sumOpenNumber: "8",
    resultDate: "2021-12-25",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2021-12-26",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2021-12-27",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "555",
    sumOpenNumber: "5",
    resultDate: "2021-12-28",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "390",
    sumOpenNumber: "2",
    resultDate: "2021-12-29",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "223",
    sumOpenNumber: "7",
    resultDate: "2021-12-30",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2021-12-31",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "236",
    sumOpenNumber: "1",
    resultDate: "2022-01-01",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-01-02",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "570",
    sumOpenNumber: "2",
    resultDate: "2022-01-03",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2022-01-04",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2022-01-05",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "570",
    sumOpenNumber: "2",
    resultDate: "2022-01-06",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "117",
    sumOpenNumber: "9",
    resultDate: "2022-01-07",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "288",
    sumOpenNumber: "8",
    resultDate: "2022-01-08",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "236",
    sumOpenNumber: "1",
    resultDate: "2022-01-09",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2022-01-10",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-01-11",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "799",
    sumOpenNumber: "5",
    resultDate: "2022-01-12",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-01-13",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "117",
    sumOpenNumber: "9",
    resultDate: "2022-01-14",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "339",
    sumOpenNumber: "5",
    resultDate: "2022-01-15",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-01-16",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-01-17",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "790",
    sumOpenNumber: "6",
    resultDate: "2022-01-18",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2022-01-19",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-01-20",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2022-01-21",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "599",
    sumOpenNumber: "3",
    resultDate: "2022-01-22",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2022-01-23",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "335",
    sumOpenNumber: "1",
    resultDate: "2022-01-24",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "679",
    sumOpenNumber: "2",
    resultDate: "2022-01-25",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-01-26",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2022-01-27",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2022-01-28",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "112",
    sumOpenNumber: "4",
    resultDate: "2022-01-29",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "580",
    sumOpenNumber: "3",
    resultDate: "2022-01-30",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "356",
    sumOpenNumber: "4",
    resultDate: "2022-01-31",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "770",
    sumOpenNumber: "4",
    resultDate: "2022-02-01",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "455",
    sumOpenNumber: "4",
    resultDate: "2022-02-02",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-02-03",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "589",
    sumOpenNumber: "2",
    resultDate: "2022-02-04",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2022-02-05",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2022-02-06",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-02-07",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "233",
    sumOpenNumber: "8",
    resultDate: "2022-02-08",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2022-02-09",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-02-10",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "699",
    sumOpenNumber: "4",
    resultDate: "2022-02-11",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2022-02-12",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "229",
    sumOpenNumber: "3",
    resultDate: "2022-02-13",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "790",
    sumOpenNumber: "6",
    resultDate: "2022-02-14",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "225",
    sumOpenNumber: "9",
    resultDate: "2022-02-15",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "338",
    sumOpenNumber: "4",
    resultDate: "2022-02-16",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2022-02-17",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2022-02-18",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "136",
    sumOpenNumber: "0",
    resultDate: "2022-02-19",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2022-02-20",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "177",
    sumOpenNumber: "5",
    resultDate: "2022-02-21",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "289",
    sumOpenNumber: "9",
    resultDate: "2022-02-22",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2022-02-23",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "167",
    sumOpenNumber: "4",
    resultDate: "2022-02-24",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-02-25",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2022-02-26",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-02-27",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "889",
    sumOpenNumber: "5",
    resultDate: "2022-02-28",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2022-03-01",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "478",
    sumOpenNumber: "9",
    resultDate: "2022-03-02",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "268",
    sumOpenNumber: "6",
    resultDate: "2022-03-03",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-03-04",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2022-03-05",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2022-03-06",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2022-03-07",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2022-03-08",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-03-09",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "228",
    sumOpenNumber: "2",
    resultDate: "2022-03-10",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "269",
    sumOpenNumber: "7",
    resultDate: "2022-03-11",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "488",
    sumOpenNumber: "0",
    resultDate: "2022-03-12",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "226",
    sumOpenNumber: "0",
    resultDate: "2022-03-13",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "278",
    sumOpenNumber: "7",
    resultDate: "2022-03-14",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "679",
    sumOpenNumber: "2",
    resultDate: "2022-03-15",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "568",
    sumOpenNumber: "9",
    resultDate: "2022-03-16",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "470",
    sumOpenNumber: "1",
    resultDate: "2022-03-17",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2022-03-18",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2022-03-19",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "228",
    sumOpenNumber: "2",
    resultDate: "2022-03-20",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2022-03-21",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-03-22",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2022-03-23",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-03-24",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2022-03-25",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2022-03-26",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-03-27",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2022-03-28",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "168",
    sumOpenNumber: "5",
    resultDate: "2022-03-29",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-03-30",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "590",
    sumOpenNumber: "4",
    resultDate: "2022-03-31",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "447",
    sumOpenNumber: "5",
    resultDate: "2022-04-01",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2022-04-02",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2022-04-03",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "480",
    sumOpenNumber: "2",
    resultDate: "2022-04-04",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2022-04-05",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-04-06",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2022-04-07",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2022-04-08",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2022-04-09",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "470",
    sumOpenNumber: "1",
    resultDate: "2022-04-10",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2022-04-11",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-04-12",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "233",
    sumOpenNumber: "8",
    resultDate: "2022-04-13",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2022-04-14",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-04-15",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2022-04-16",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "444",
    sumOpenNumber: "2",
    resultDate: "2022-04-17",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2022-04-18",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-04-19",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2022-04-20",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-04-21",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "115",
    sumOpenNumber: "7",
    resultDate: "2022-04-22",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2022-04-23",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2022-04-24",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "136",
    sumOpenNumber: "0",
    resultDate: "2022-04-25",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "889",
    sumOpenNumber: "5",
    resultDate: "2022-04-26",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "148",
    sumOpenNumber: "3",
    resultDate: "2022-04-27",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2022-04-28",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "490",
    sumOpenNumber: "3",
    resultDate: "2022-04-29",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2022-04-30",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-05-01",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "557",
    sumOpenNumber: "7",
    resultDate: "2022-05-02",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "116",
    sumOpenNumber: "8",
    resultDate: "2022-05-03",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "269",
    sumOpenNumber: "7",
    resultDate: "2022-05-04",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "338",
    sumOpenNumber: "4",
    resultDate: "2022-05-05",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "348",
    sumOpenNumber: "5",
    resultDate: "2022-05-06",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "690",
    sumOpenNumber: "5",
    resultDate: "2022-05-07",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2022-05-08",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "128",
    sumOpenNumber: "1",
    resultDate: "2022-05-09",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "188",
    sumOpenNumber: "7",
    resultDate: "2022-05-10",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2022-05-11",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2022-05-12",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "579",
    sumOpenNumber: "1",
    resultDate: "2022-05-13",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "234",
    sumOpenNumber: "9",
    resultDate: "2022-05-14",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "114",
    sumOpenNumber: "6",
    resultDate: "2022-05-15",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2022-05-16",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "688",
    sumOpenNumber: "2",
    resultDate: "2022-05-17",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "177",
    sumOpenNumber: "5",
    resultDate: "2022-05-18",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "126",
    sumOpenNumber: "9",
    resultDate: "2022-05-19",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2022-05-20",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "579",
    sumOpenNumber: "1",
    resultDate: "2022-05-21",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-05-22",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "399",
    sumOpenNumber: "1",
    resultDate: "2022-05-23",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-05-24",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "226",
    sumOpenNumber: "0",
    resultDate: "2022-05-25",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-05-26",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "490",
    sumOpenNumber: "3",
    resultDate: "2022-05-27",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2022-05-28",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-05-29",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2022-05-30",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "367",
    sumOpenNumber: "6",
    resultDate: "2022-05-31",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2022-06-01",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-06-02",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-06-03",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2022-06-04",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "449",
    sumOpenNumber: "7",
    resultDate: "2022-06-05",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2022-06-06",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-06-07",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2022-06-08",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-06-09",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2022-06-10",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-06-11",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-06-12",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "368",
    sumOpenNumber: "7",
    resultDate: "2022-06-13",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2022-06-14",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-06-15",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2022-06-16",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "259",
    sumOpenNumber: "6",
    resultDate: "2022-06-17",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "157",
    sumOpenNumber: "3",
    resultDate: "2022-06-18",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2022-06-19",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "688",
    sumOpenNumber: "2",
    resultDate: "2022-06-20",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-06-21",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "339",
    sumOpenNumber: "5",
    resultDate: "2022-06-22",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "367",
    sumOpenNumber: "6",
    resultDate: "2022-06-23",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "338",
    sumOpenNumber: "4",
    resultDate: "2022-06-24",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "779",
    sumOpenNumber: "3",
    resultDate: "2022-06-25",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2022-06-26",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "568",
    sumOpenNumber: "9",
    resultDate: "2022-06-27",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "356",
    sumOpenNumber: "4",
    resultDate: "2022-06-28",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2022-06-29",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "588",
    sumOpenNumber: "1",
    resultDate: "2022-06-30",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "115",
    sumOpenNumber: "7",
    resultDate: "2022-07-01",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2022-07-02",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2022-07-03",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-07-04",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "138",
    sumOpenNumber: "2",
    resultDate: "2022-07-05",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-07-06",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2022-07-07",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2022-07-08",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2022-07-09",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "188",
    sumOpenNumber: "7",
    resultDate: "2022-07-10",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "238",
    sumOpenNumber: "3",
    resultDate: "2022-07-11",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-07-12",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "690",
    sumOpenNumber: "5",
    resultDate: "2022-07-13",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "145",
    sumOpenNumber: "0",
    resultDate: "2022-07-14",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "112",
    sumOpenNumber: "4",
    resultDate: "2022-07-15",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2022-07-16",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "455",
    sumOpenNumber: "4",
    resultDate: "2022-07-17",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "115",
    sumOpenNumber: "7",
    resultDate: "2022-07-18",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "590",
    sumOpenNumber: "4",
    resultDate: "2022-07-19",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "134",
    sumOpenNumber: "8",
    resultDate: "2022-07-20",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "470",
    sumOpenNumber: "1",
    resultDate: "2022-07-21",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2022-07-22",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "558",
    sumOpenNumber: "8",
    resultDate: "2022-07-23",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "244",
    sumOpenNumber: "0",
    resultDate: "2022-07-24",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2022-07-25",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2022-07-26",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "178",
    sumOpenNumber: "6",
    resultDate: "2022-07-27",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "356",
    sumOpenNumber: "4",
    resultDate: "2022-07-28",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2022-07-29",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2022-07-30",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2022-07-31",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2022-08-01",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2022-08-02",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2022-08-03",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-08-04",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-08-05",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-08-06",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-08-07",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2022-08-08",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2022-08-09",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-08-10",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2022-08-11",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "356",
    sumOpenNumber: "4",
    resultDate: "2022-08-12",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2022-08-13",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-08-14",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2022-08-15",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2022-08-16",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "134",
    sumOpenNumber: "8",
    resultDate: "2022-08-17",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "225",
    sumOpenNumber: "9",
    resultDate: "2022-08-18",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-08-19",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2022-08-20",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "559",
    sumOpenNumber: "9",
    resultDate: "2022-08-21",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2022-08-22",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "233",
    sumOpenNumber: "8",
    resultDate: "2022-08-23",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2022-08-24",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-08-25",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-08-26",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-08-27",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2022-08-28",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "145",
    sumOpenNumber: "0",
    resultDate: "2022-08-29",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2022-08-30",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2022-08-31",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2022-09-01",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2022-09-02",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "335",
    sumOpenNumber: "1",
    resultDate: "2022-09-03",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "335",
    sumOpenNumber: "1",
    resultDate: "2022-09-04",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2022-09-05",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2022-09-06",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2022-09-07",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-09-08",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "455",
    sumOpenNumber: "4",
    resultDate: "2022-09-09",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "267",
    sumOpenNumber: "5",
    resultDate: "2022-09-10",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2022-09-11",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-09-12",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2022-09-13",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "680",
    sumOpenNumber: "4",
    resultDate: "2022-09-14",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2022-09-15",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-09-16",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2022-09-17",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-09-18",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2022-09-19",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-09-20",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-09-21",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2022-09-22",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2022-09-23",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-09-24",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "234",
    sumOpenNumber: "9",
    resultDate: "2022-09-25",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-09-26",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "469",
    sumOpenNumber: "9",
    resultDate: "2022-09-27",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2022-09-28",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "167",
    sumOpenNumber: "4",
    resultDate: "2022-09-29",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-09-30",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "126",
    sumOpenNumber: "9",
    resultDate: "2022-10-01",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2022-10-02",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-10-03",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-10-04",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "390",
    sumOpenNumber: "2",
    resultDate: "2022-10-05",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "168",
    sumOpenNumber: "5",
    resultDate: "2022-10-06",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2022-10-07",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2022-10-08",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2022-10-09",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-10-10",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "236",
    sumOpenNumber: "1",
    resultDate: "2022-10-11",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2022-10-12",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-10-13",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-10-14",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-10-15",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "178",
    sumOpenNumber: "6",
    resultDate: "2022-10-16",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2022-10-17",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "248",
    sumOpenNumber: "4",
    resultDate: "2022-10-18",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2022-10-19",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-10-20",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2022-10-21",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "148",
    sumOpenNumber: "3",
    resultDate: "2022-10-22",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "236",
    sumOpenNumber: "1",
    resultDate: "2022-10-23",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "147",
    sumOpenNumber: "2",
    resultDate: "2022-10-24",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2022-10-25",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2022-10-26",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "134",
    sumOpenNumber: "8",
    resultDate: "2022-10-27",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "248",
    sumOpenNumber: "4",
    resultDate: "2022-10-28",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2022-10-29",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "234",
    sumOpenNumber: "9",
    resultDate: "2022-10-30",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "288",
    sumOpenNumber: "8",
    resultDate: "2022-10-31",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2022-11-01",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2022-11-02",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "236",
    sumOpenNumber: "1",
    resultDate: "2022-11-03",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "899",
    sumOpenNumber: "6",
    resultDate: "2022-11-04",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-11-05",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2022-11-06",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2022-11-07",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2022-11-08",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2022-11-09",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2022-11-10",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-11-11",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "134",
    sumOpenNumber: "8",
    resultDate: "2022-11-12",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2022-11-13",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "137",
    sumOpenNumber: "1",
    resultDate: "2022-11-14",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2022-11-15",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "580",
    sumOpenNumber: "3",
    resultDate: "2022-11-16",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2022-11-17",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-11-18",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "677",
    sumOpenNumber: "0",
    resultDate: "2022-11-19",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-11-20",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "447",
    sumOpenNumber: "5",
    resultDate: "2022-11-21",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2022-11-22",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "259",
    sumOpenNumber: "6",
    resultDate: "2022-11-23",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-11-24",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-11-25",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2022-11-26",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-11-27",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2022-11-28",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2022-11-29",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-11-30",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "488",
    sumOpenNumber: "0",
    resultDate: "2022-12-01",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "889",
    sumOpenNumber: "5",
    resultDate: "2022-12-02",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-12-03",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-12-04",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "559",
    sumOpenNumber: "9",
    resultDate: "2022-12-05",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "266",
    sumOpenNumber: "4",
    resultDate: "2022-12-06",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2022-12-07",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-12-08",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-12-09",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "779",
    sumOpenNumber: "3",
    resultDate: "2022-12-10",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "356",
    sumOpenNumber: "4",
    resultDate: "2022-12-11",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "168",
    sumOpenNumber: "5",
    resultDate: "2022-12-12",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "680",
    sumOpenNumber: "4",
    resultDate: "2022-12-13",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "228",
    sumOpenNumber: "2",
    resultDate: "2022-12-14",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "236",
    sumOpenNumber: "1",
    resultDate: "2022-12-15",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "128",
    sumOpenNumber: "1",
    resultDate: "2022-12-16",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2022-12-17",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-12-18",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2022-12-19",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2022-12-20",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-12-21",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2022-12-22",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "390",
    sumOpenNumber: "2",
    resultDate: "2022-12-23",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "566",
    sumOpenNumber: "7",
    resultDate: "2022-12-24",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "570",
    sumOpenNumber: "2",
    resultDate: "2022-12-25",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "470",
    sumOpenNumber: "1",
    resultDate: "2022-12-26",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2022-12-27",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-12-28",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "390",
    sumOpenNumber: "2",
    resultDate: "2022-12-29",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2022-12-30",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-12-31",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "359",
    sumOpenNumber: "7",
    resultDate: "2023-01-01",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "266",
    sumOpenNumber: "4",
    resultDate: "2023-01-02",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "234",
    sumOpenNumber: "9",
    resultDate: "2023-01-03",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "445",
    sumOpenNumber: "3",
    resultDate: "2023-01-04",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "489",
    sumOpenNumber: "1",
    resultDate: "2023-01-05",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "238",
    sumOpenNumber: "3",
    resultDate: "2023-01-06",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2023-01-07",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2023-01-08",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "223",
    sumOpenNumber: "7",
    resultDate: "2023-01-09",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-01-10",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-01-11",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-01-12",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2023-01-13",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2023-01-14",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2023-01-15",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-01-16",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "244",
    sumOpenNumber: "0",
    resultDate: "2023-01-17",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "168",
    sumOpenNumber: "5",
    resultDate: "2023-01-18",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-01-19",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "770",
    sumOpenNumber: "4",
    resultDate: "2023-01-20",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "558",
    sumOpenNumber: "8",
    resultDate: "2023-01-21",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "455",
    sumOpenNumber: "4",
    resultDate: "2023-01-22",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-01-23",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "599",
    sumOpenNumber: "3",
    resultDate: "2023-01-24",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-01-25",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2023-01-26",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "277",
    sumOpenNumber: "6",
    resultDate: "2023-01-27",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2023-01-28",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2023-01-29",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "588",
    sumOpenNumber: "1",
    resultDate: "2023-01-30",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-01-31",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "990",
    sumOpenNumber: "8",
    resultDate: "2023-02-01",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2023-02-02",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2023-02-03",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-02-04",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-02-05",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2023-02-06",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "990",
    sumOpenNumber: "8",
    resultDate: "2023-02-07",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "117",
    sumOpenNumber: "9",
    resultDate: "2023-02-08",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "770",
    sumOpenNumber: "4",
    resultDate: "2023-02-09",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "390",
    sumOpenNumber: "2",
    resultDate: "2023-02-10",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "668",
    sumOpenNumber: "0",
    resultDate: "2023-02-11",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-02-12",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2023-02-13",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2023-02-14",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-02-15",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-02-16",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "166",
    sumOpenNumber: "3",
    resultDate: "2023-02-17",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "449",
    sumOpenNumber: "7",
    resultDate: "2023-02-18",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2023-02-19",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2023-02-20",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-02-21",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2023-02-22",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-02-23",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2023-02-24",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-02-25",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2023-02-26",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-02-27",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2023-02-28",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "777",
    sumOpenNumber: "1",
    resultDate: "2023-03-01",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2023-03-02",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "667",
    sumOpenNumber: "9",
    resultDate: "2023-03-03",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "0",
    sumOpenNumber: "0",
    resultDate: "2023-03-04",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "244",
    sumOpenNumber: "0",
    resultDate: "2023-03-05",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2023-03-06",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-03-07",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-03-08",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2023-03-09",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-03-10",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-03-11",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-03-12",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-03-16",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-03-17",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "338",
    sumOpenNumber: "4",
    resultDate: "2023-03-18",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "557",
    sumOpenNumber: "7",
    resultDate: "2023-03-19",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-03-20",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-03-21",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2023-03-22",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "114",
    sumOpenNumber: "6",
    resultDate: "2023-03-23",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "222",
    sumOpenNumber: "6",
    resultDate: "2023-03-24",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "244",
    sumOpenNumber: "0",
    resultDate: "2023-03-25",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "449",
    sumOpenNumber: "7",
    resultDate: "2023-03-26",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "667",
    sumOpenNumber: "9",
    resultDate: "2023-03-27",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "199",
    sumOpenNumber: "9",
    resultDate: "2023-03-28",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "117",
    sumOpenNumber: "9",
    resultDate: "2023-03-29",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-03-30",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-03-31",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "168",
    sumOpenNumber: "5",
    resultDate: "2023-04-01",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "224",
    sumOpenNumber: "8",
    resultDate: "2023-04-02",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-04-03",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2023-04-04",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "112",
    sumOpenNumber: "4",
    resultDate: "2023-04-05",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2023-04-06",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "266",
    sumOpenNumber: "4",
    resultDate: "2023-04-07",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-04-08",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-04-09",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "236",
    sumOpenNumber: "1",
    resultDate: "2023-04-10",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "266",
    sumOpenNumber: "4",
    resultDate: "2023-04-11",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-04-12",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-04-13",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-04-14",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-04-15",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-04-16",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "366",
    sumOpenNumber: "5",
    resultDate: "2023-04-17",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "667",
    sumOpenNumber: "9",
    resultDate: "2023-04-18",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "448",
    sumOpenNumber: "6",
    resultDate: "2023-04-19",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "366",
    sumOpenNumber: "5",
    resultDate: "2023-04-20",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2023-04-21",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "556",
    sumOpenNumber: "6",
    resultDate: "2023-04-22",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "444",
    sumOpenNumber: "2",
    resultDate: "2023-04-23",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2023-04-24",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-04-25",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "559",
    sumOpenNumber: "9",
    resultDate: "2023-04-26",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "228",
    sumOpenNumber: "2",
    resultDate: "2023-04-27",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-04-28",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-04-29",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "277",
    sumOpenNumber: "6",
    resultDate: "2023-04-30",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "244",
    sumOpenNumber: "0",
    resultDate: "2023-05-01",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-05-02",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "335",
    sumOpenNumber: "1",
    resultDate: "2023-05-03",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2023-05-04",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-05-05",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "690",
    sumOpenNumber: "5",
    resultDate: "2023-05-06",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "770",
    sumOpenNumber: "4",
    resultDate: "2023-05-07",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-05-08",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "448",
    sumOpenNumber: "6",
    resultDate: "2023-05-09",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-05-10",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-05-11",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "244",
    sumOpenNumber: "0",
    resultDate: "2023-05-12",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2023-05-13",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-05-14",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "488",
    sumOpenNumber: "0",
    resultDate: "2023-05-15",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2023-05-16",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2023-05-17",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-05-18",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "333",
    sumOpenNumber: "9",
    resultDate: "2023-05-19",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-05-20",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "367",
    sumOpenNumber: "6",
    resultDate: "2023-05-21",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "566",
    sumOpenNumber: "7",
    resultDate: "2023-05-22",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "366",
    sumOpenNumber: "5",
    resultDate: "2023-05-23",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "236",
    sumOpenNumber: "1",
    resultDate: "2023-05-24",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "127",
    sumOpenNumber: "0",
    resultDate: "2023-05-25",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2023-05-26",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "229",
    sumOpenNumber: "3",
    resultDate: "2023-05-27",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "688",
    sumOpenNumber: "2",
    resultDate: "2023-05-28",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-05-29",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2023-05-30",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-05-31",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-06-01",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "356",
    sumOpenNumber: "4",
    resultDate: "2023-06-02",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "338",
    sumOpenNumber: "4",
    resultDate: "2023-06-03",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-06-04",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "299",
    sumOpenNumber: "0",
    resultDate: "2023-06-05",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "668",
    sumOpenNumber: "0",
    resultDate: "2023-06-06",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "477",
    sumOpenNumber: "8",
    resultDate: "2023-06-07",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-06-08",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "689",
    sumOpenNumber: "3",
    resultDate: "2023-06-09",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "115",
    sumOpenNumber: "7",
    resultDate: "2023-06-10",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2023-06-11",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2023-06-12",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "279",
    sumOpenNumber: "8",
    resultDate: "2023-06-13",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2023-06-14",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "266",
    sumOpenNumber: "4",
    resultDate: "2023-06-15",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2023-06-16",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "777",
    sumOpenNumber: "1",
    resultDate: "2023-06-17",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "577",
    sumOpenNumber: "9",
    resultDate: "2023-06-18",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2023-06-19",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-06-20",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "118",
    sumOpenNumber: "0",
    resultDate: "2023-06-21",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "445",
    sumOpenNumber: "3",
    resultDate: "2023-06-22",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2023-06-23",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2023-06-24",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "448",
    sumOpenNumber: "6",
    resultDate: "2023-06-25",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "177",
    sumOpenNumber: "5",
    resultDate: "2023-06-26",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-06-27",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "667",
    sumOpenNumber: "9",
    resultDate: "2023-06-28",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "116",
    sumOpenNumber: "8",
    resultDate: "2023-06-29",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "377",
    sumOpenNumber: "7",
    resultDate: "2023-06-30",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "479",
    sumOpenNumber: "0",
    resultDate: "2023-07-01",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2023-07-02",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "770",
    sumOpenNumber: "4",
    resultDate: "2023-07-03",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-07-04",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "339",
    sumOpenNumber: "5",
    resultDate: "2023-07-05",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "227",
    sumOpenNumber: "1",
    resultDate: "2023-07-06",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "666",
    sumOpenNumber: "8",
    resultDate: "2023-07-07",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "778",
    sumOpenNumber: "2",
    resultDate: "2023-07-08",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "388",
    sumOpenNumber: "9",
    resultDate: "2023-07-09",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "366",
    sumOpenNumber: "5",
    resultDate: "2023-07-10",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "599",
    sumOpenNumber: "3",
    resultDate: "2023-07-11",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-07-12",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2023-07-13",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "399",
    sumOpenNumber: "1",
    resultDate: "2023-07-14",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "239",
    sumOpenNumber: "4",
    resultDate: "2023-07-15",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "447",
    sumOpenNumber: "5",
    resultDate: "2023-07-16",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "224",
    sumOpenNumber: "8",
    resultDate: "2023-07-17",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "113",
    sumOpenNumber: "5",
    resultDate: "2023-07-18",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2023-07-19",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "277",
    sumOpenNumber: "6",
    resultDate: "2023-07-20",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "270",
    sumOpenNumber: "9",
    resultDate: "2023-07-21",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "778",
    sumOpenNumber: "2",
    resultDate: "2023-07-22",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-07-23",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "115",
    sumOpenNumber: "7",
    resultDate: "2023-07-24",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "399",
    sumOpenNumber: "1",
    resultDate: "2023-07-25",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2023-07-26",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "378",
    sumOpenNumber: "8",
    resultDate: "2023-07-27",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "136",
    sumOpenNumber: "0",
    resultDate: "2023-07-28",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "249",
    sumOpenNumber: "5",
    resultDate: "2023-07-29",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "369",
    sumOpenNumber: "8",
    resultDate: "2023-07-30",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "337",
    sumOpenNumber: "3",
    resultDate: "2023-07-31",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-08-01",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "680",
    sumOpenNumber: "4",
    resultDate: "2023-08-02",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "457",
    sumOpenNumber: "6",
    resultDate: "2023-08-03",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-08-04",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "128",
    sumOpenNumber: "1",
    resultDate: "2023-08-05",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2023-08-06",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "570",
    sumOpenNumber: "2",
    resultDate: "2023-08-07",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "334",
    sumOpenNumber: "0",
    resultDate: "2023-08-08",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "249",
    sumOpenNumber: "5",
    resultDate: "2023-08-09",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2023-08-10",
  },
  {
    MarketTime: "01:00:00 PM",
    openNumber: "779",
    sumOpenNumber: "3",
    resultDate: "2023-08-11",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-09-07",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2021-09-08",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2021-09-09",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2021-09-10",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "599",
    sumOpenNumber: "3",
    resultDate: "2021-09-11",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2021-09-12",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "367",
    sumOpenNumber: "6",
    resultDate: "2021-09-13",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2021-09-14",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2021-09-15",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "668",
    sumOpenNumber: "0",
    resultDate: "2021-09-16",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2021-09-17",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "378",
    sumOpenNumber: "8",
    resultDate: "2021-09-18",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "690",
    sumOpenNumber: "5",
    resultDate: "2021-09-19",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "168",
    sumOpenNumber: "5",
    resultDate: "2021-09-20",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "222",
    sumOpenNumber: "6",
    resultDate: "2021-09-21",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "111",
    sumOpenNumber: "3",
    resultDate: "2021-09-22",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "680",
    sumOpenNumber: "4",
    resultDate: "2021-09-23",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "377",
    sumOpenNumber: "7",
    resultDate: "2021-09-24",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "337",
    sumOpenNumber: "3",
    resultDate: "2021-09-25",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2021-09-26",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "148",
    sumOpenNumber: "3",
    resultDate: "2021-09-27",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2021-09-28",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "589",
    sumOpenNumber: "2",
    resultDate: "2021-09-29",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "580",
    sumOpenNumber: "3",
    resultDate: "2021-09-30",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "680",
    sumOpenNumber: "4",
    resultDate: "2021-10-01",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "359",
    sumOpenNumber: "7",
    resultDate: "2021-10-02",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-10-03",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "568",
    sumOpenNumber: "9",
    resultDate: "2021-10-04",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2021-10-05",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "0",
    sumOpenNumber: "0",
    resultDate: "2021-10-06",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-10-07",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2021-10-08",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2021-10-09",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2021-10-10",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "444",
    sumOpenNumber: "2",
    resultDate: "2021-10-11",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-10-12",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2021-10-13",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-10-14",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "889",
    sumOpenNumber: "5",
    resultDate: "2021-10-15",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2021-10-16",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-10-17",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "444",
    sumOpenNumber: "2",
    resultDate: "2021-10-18",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-10-19",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-10-20",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2021-10-21",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-10-22",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2021-10-23",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "199",
    sumOpenNumber: "9",
    resultDate: "2021-10-24",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "146",
    sumOpenNumber: "1",
    resultDate: "2021-10-25",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "0",
    sumOpenNumber: "0",
    resultDate: "2021-10-26",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2021-10-27",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2021-10-28",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2021-10-29",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2021-10-30",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-10-31",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2021-11-01",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-11-02",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2021-11-03",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2021-11-04",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2021-11-05",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2021-11-06",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "568",
    sumOpenNumber: "9",
    resultDate: "2021-11-07",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2021-11-08",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2021-11-09",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "690",
    sumOpenNumber: "5",
    resultDate: "2021-11-10",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "244",
    sumOpenNumber: "0",
    resultDate: "2021-11-11",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-11-12",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "113",
    sumOpenNumber: "5",
    resultDate: "2021-11-13",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-11-14",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-11-15",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "689",
    sumOpenNumber: "3",
    resultDate: "2021-11-16",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2021-11-17",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-11-18",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2021-11-19",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2021-11-20",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "126",
    sumOpenNumber: "9",
    resultDate: "2021-11-21",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2021-11-22",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2021-11-23",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2021-11-24",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2021-11-25",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-11-26",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "169",
    sumOpenNumber: "6",
    resultDate: "2021-11-27",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "0",
    sumOpenNumber: "0",
    resultDate: "2021-11-28",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2021-11-29",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2021-11-30",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "0",
    sumOpenNumber: "0",
    resultDate: "2021-12-01",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2021-12-02",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "666",
    sumOpenNumber: "8",
    resultDate: "2021-12-03",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2021-12-04",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-12-05",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2021-12-06",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2021-12-07",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2021-12-08",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "149",
    sumOpenNumber: "4",
    resultDate: "2021-12-09",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "999",
    sumOpenNumber: "7",
    resultDate: "2021-12-10",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2021-12-11",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2021-12-12",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2021-12-13",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "359",
    sumOpenNumber: "7",
    resultDate: "2021-12-14",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "790",
    sumOpenNumber: "6",
    resultDate: "2021-12-15",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "134",
    sumOpenNumber: "8",
    resultDate: "2021-12-16",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "348",
    sumOpenNumber: "5",
    resultDate: "2021-12-17",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2021-12-18",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2021-12-19",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2021-12-20",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "157",
    sumOpenNumber: "3",
    resultDate: "2021-12-21",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2021-12-22",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2021-12-23",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2021-12-24",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2021-12-25",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2021-12-26",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2021-12-27",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2021-12-28",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2021-12-29",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2021-12-30",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "680",
    sumOpenNumber: "4",
    resultDate: "2021-12-31",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2022-01-01",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-01-02",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2022-01-03",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-01-04",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2022-01-05",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2022-01-06",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "689",
    sumOpenNumber: "3",
    resultDate: "2022-01-07",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2022-01-08",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-01-09",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "479",
    sumOpenNumber: "0",
    resultDate: "2022-01-10",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "116",
    sumOpenNumber: "8",
    resultDate: "2022-01-11",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "489",
    sumOpenNumber: "1",
    resultDate: "2022-01-12",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2022-01-13",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2022-01-14",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2022-01-15",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "589",
    sumOpenNumber: "2",
    resultDate: "2022-01-16",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "688",
    sumOpenNumber: "2",
    resultDate: "2022-01-17",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "338",
    sumOpenNumber: "4",
    resultDate: "2022-01-18",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2022-01-19",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "346",
    sumOpenNumber: "3",
    resultDate: "2022-01-20",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "799",
    sumOpenNumber: "5",
    resultDate: "2022-01-21",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2022-01-22",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2022-01-23",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2022-01-24",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2022-01-25",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2022-01-26",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2022-01-27",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "157",
    sumOpenNumber: "3",
    resultDate: "2022-01-28",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2022-01-29",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2022-01-30",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "559",
    sumOpenNumber: "9",
    resultDate: "2022-01-31",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "457",
    sumOpenNumber: "6",
    resultDate: "2022-02-01",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2022-02-02",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-02-03",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "559",
    sumOpenNumber: "9",
    resultDate: "2022-02-04",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "117",
    sumOpenNumber: "9",
    resultDate: "2022-02-05",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "128",
    sumOpenNumber: "1",
    resultDate: "2022-02-06",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2022-02-07",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-02-08",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "449",
    sumOpenNumber: "7",
    resultDate: "2022-02-09",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-02-10",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-02-11",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "580",
    sumOpenNumber: "3",
    resultDate: "2022-02-12",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2022-02-13",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "158",
    sumOpenNumber: "4",
    resultDate: "2022-02-14",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-02-15",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "156",
    sumOpenNumber: "2",
    resultDate: "2022-02-16",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "128",
    sumOpenNumber: "1",
    resultDate: "2022-02-17",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "590",
    sumOpenNumber: "4",
    resultDate: "2022-02-18",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "157",
    sumOpenNumber: "3",
    resultDate: "2022-02-19",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2022-02-20",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2022-02-21",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "368",
    sumOpenNumber: "7",
    resultDate: "2022-02-22",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "590",
    sumOpenNumber: "4",
    resultDate: "2022-02-23",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "359",
    sumOpenNumber: "7",
    resultDate: "2022-02-24",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2022-02-25",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "457",
    sumOpenNumber: "6",
    resultDate: "2022-02-26",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "188",
    sumOpenNumber: "7",
    resultDate: "2022-02-27",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "112",
    sumOpenNumber: "4",
    resultDate: "2022-02-28",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "248",
    sumOpenNumber: "4",
    resultDate: "2022-03-01",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2022-03-02",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2022-03-03",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2022-03-04",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-03-05",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2022-03-06",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2022-03-07",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-03-08",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "156",
    sumOpenNumber: "2",
    resultDate: "2022-03-09",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "455",
    sumOpenNumber: "4",
    resultDate: "2022-03-10",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-03-11",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "236",
    sumOpenNumber: "1",
    resultDate: "2022-03-12",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "247",
    sumOpenNumber: "3",
    resultDate: "2022-03-13",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2022-03-14",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2022-03-15",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "455",
    sumOpenNumber: "4",
    resultDate: "2022-03-16",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-03-17",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-03-18",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "567",
    sumOpenNumber: "8",
    resultDate: "2022-03-19",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2022-03-20",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "337",
    sumOpenNumber: "3",
    resultDate: "2022-03-21",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2022-03-22",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-03-23",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "177",
    sumOpenNumber: "5",
    resultDate: "2022-03-24",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "178",
    sumOpenNumber: "6",
    resultDate: "2022-03-25",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2022-03-26",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "448",
    sumOpenNumber: "6",
    resultDate: "2022-03-27",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "270",
    sumOpenNumber: "9",
    resultDate: "2022-03-28",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "570",
    sumOpenNumber: "2",
    resultDate: "2022-03-29",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2022-03-30",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2022-03-31",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2022-04-01",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "247",
    sumOpenNumber: "3",
    resultDate: "2022-04-02",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "589",
    sumOpenNumber: "2",
    resultDate: "2022-04-03",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "358",
    sumOpenNumber: "6",
    resultDate: "2022-04-04",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "367",
    sumOpenNumber: "6",
    resultDate: "2022-04-06",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2022-04-07",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "156",
    sumOpenNumber: "2",
    resultDate: "2022-04-08",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "278",
    sumOpenNumber: "7",
    resultDate: "2022-04-09",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "470",
    sumOpenNumber: "1",
    resultDate: "2022-04-10",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "680",
    sumOpenNumber: "4",
    resultDate: "2022-04-11",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2022-04-12",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-04-13",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "188",
    sumOpenNumber: "7",
    resultDate: "2022-04-14",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-04-15",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2022-04-16",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2022-04-17",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2022-04-18",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "368",
    sumOpenNumber: "7",
    resultDate: "2022-04-19",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "478",
    sumOpenNumber: "9",
    resultDate: "2022-04-20",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "228",
    sumOpenNumber: "2",
    resultDate: "2022-04-21",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "279",
    sumOpenNumber: "8",
    resultDate: "2022-04-22",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-04-23",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "377",
    sumOpenNumber: "7",
    resultDate: "2022-04-24",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "270",
    sumOpenNumber: "9",
    resultDate: "2022-04-25",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2022-04-26",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "248",
    sumOpenNumber: "4",
    resultDate: "2022-04-27",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2022-04-28",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2022-04-29",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2022-04-30",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2022-05-01",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2022-05-02",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "355",
    sumOpenNumber: "3",
    resultDate: "2022-05-03",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2022-05-04",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "399",
    sumOpenNumber: "1",
    resultDate: "2022-05-05",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-05-06",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-05-07",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "225",
    sumOpenNumber: "9",
    resultDate: "2022-05-08",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2022-05-09",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "346",
    sumOpenNumber: "3",
    resultDate: "2022-05-10",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "559",
    sumOpenNumber: "9",
    resultDate: "2022-05-11",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "668",
    sumOpenNumber: "0",
    resultDate: "2022-05-12",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "160",
    sumOpenNumber: "7",
    resultDate: "2022-05-13",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "269",
    sumOpenNumber: "7",
    resultDate: "2022-05-14",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "145",
    sumOpenNumber: "0",
    resultDate: "2022-05-15",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "156",
    sumOpenNumber: "2",
    resultDate: "2022-05-16",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "235",
    sumOpenNumber: "0",
    resultDate: "2022-05-17",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2022-05-18",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "680",
    sumOpenNumber: "4",
    resultDate: "2022-05-19",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-05-20",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-05-21",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "469",
    sumOpenNumber: "9",
    resultDate: "2022-05-22",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2022-05-23",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "579",
    sumOpenNumber: "1",
    resultDate: "2022-05-24",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2022-05-25",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2022-05-26",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "245",
    sumOpenNumber: "1",
    resultDate: "2022-05-27",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-05-28",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "568",
    sumOpenNumber: "9",
    resultDate: "2022-05-29",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "778",
    sumOpenNumber: "2",
    resultDate: "2022-05-30",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "579",
    sumOpenNumber: "1",
    resultDate: "2022-05-31",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "128",
    sumOpenNumber: "1",
    resultDate: "2022-06-01",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "356",
    sumOpenNumber: "4",
    resultDate: "2022-06-02",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "233",
    sumOpenNumber: "8",
    resultDate: "2022-06-03",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "228",
    sumOpenNumber: "2",
    resultDate: "2022-06-04",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2022-06-05",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-06-06",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "599",
    sumOpenNumber: "3",
    resultDate: "2022-06-07",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "337",
    sumOpenNumber: "3",
    resultDate: "2022-06-08",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2022-06-09",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-06-10",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2022-06-11",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2022-06-12",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "227",
    sumOpenNumber: "1",
    resultDate: "2022-06-13",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2022-06-14",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2022-06-15",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "799",
    sumOpenNumber: "5",
    resultDate: "2022-06-16",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2022-06-17",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "339",
    sumOpenNumber: "5",
    resultDate: "2022-06-18",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2022-06-19",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2022-06-20",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-06-21",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-06-22",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "255",
    sumOpenNumber: "2",
    resultDate: "2022-06-23",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "369",
    sumOpenNumber: "8",
    resultDate: "2022-06-24",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "277",
    sumOpenNumber: "6",
    resultDate: "2022-06-25",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "140",
    sumOpenNumber: "5",
    resultDate: "2022-06-26",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "369",
    sumOpenNumber: "8",
    resultDate: "2022-06-27",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "479",
    sumOpenNumber: "0",
    resultDate: "2022-06-28",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "890",
    sumOpenNumber: "7",
    resultDate: "2022-06-29",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2022-06-30",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2022-07-01",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "457",
    sumOpenNumber: "6",
    resultDate: "2022-07-02",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "680",
    sumOpenNumber: "4",
    resultDate: "2022-07-03",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2022-07-04",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2022-07-05",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2022-07-06",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-07-07",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-07-08",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "579",
    sumOpenNumber: "1",
    resultDate: "2022-07-09",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "119",
    sumOpenNumber: "1",
    resultDate: "2022-07-10",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2022-07-11",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "346",
    sumOpenNumber: "3",
    resultDate: "2022-07-12",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2022-07-13",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2022-07-14",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-07-15",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2022-07-16",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2022-07-17",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "366",
    sumOpenNumber: "5",
    resultDate: "2022-07-18",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-07-19",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2022-07-20",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2022-07-21",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2022-07-22",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "120",
    sumOpenNumber: "3",
    resultDate: "2022-07-23",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "150",
    sumOpenNumber: "6",
    resultDate: "2022-07-24",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "269",
    sumOpenNumber: "7",
    resultDate: "2022-07-25",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2022-07-26",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "259",
    sumOpenNumber: "6",
    resultDate: "2022-07-27",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2022-07-28",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2022-07-29",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-07-30",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "556",
    sumOpenNumber: "6",
    resultDate: "2022-07-31",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2022-08-01",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "145",
    sumOpenNumber: "0",
    resultDate: "2022-08-02",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "135",
    sumOpenNumber: "9",
    resultDate: "2022-08-03",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "458",
    sumOpenNumber: "7",
    resultDate: "2022-08-04",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2022-08-05",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "115",
    sumOpenNumber: "7",
    resultDate: "2022-08-06",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "346",
    sumOpenNumber: "3",
    resultDate: "2022-08-07",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "567",
    sumOpenNumber: "8",
    resultDate: "2022-08-08",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2022-08-09",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "579",
    sumOpenNumber: "1",
    resultDate: "2022-08-10",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "356",
    sumOpenNumber: "4",
    resultDate: "2022-08-11",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2022-08-12",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "130",
    sumOpenNumber: "4",
    resultDate: "2022-08-13",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2022-08-14",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2022-08-15",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2022-08-16",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "336",
    sumOpenNumber: "2",
    resultDate: "2022-08-17",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "448",
    sumOpenNumber: "6",
    resultDate: "2022-08-18",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-08-19",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2022-08-20",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "558",
    sumOpenNumber: "8",
    resultDate: "2022-08-21",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-08-22",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "448",
    sumOpenNumber: "6",
    resultDate: "2022-08-23",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "489",
    sumOpenNumber: "1",
    resultDate: "2022-08-24",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "134",
    sumOpenNumber: "8",
    resultDate: "2022-08-25",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "467",
    sumOpenNumber: "7",
    resultDate: "2022-08-26",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-08-27",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "359",
    sumOpenNumber: "7",
    resultDate: "2022-08-28",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-08-29",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2022-08-30",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "348",
    sumOpenNumber: "5",
    resultDate: "2022-08-31",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "470",
    sumOpenNumber: "1",
    resultDate: "2022-09-01",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-09-02",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "179",
    sumOpenNumber: "7",
    resultDate: "2022-09-03",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "389",
    sumOpenNumber: "0",
    resultDate: "2022-09-04",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-09-05",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "189",
    sumOpenNumber: "8",
    resultDate: "2022-09-06",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "233",
    sumOpenNumber: "8",
    resultDate: "2022-09-07",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "457",
    sumOpenNumber: "6",
    resultDate: "2022-09-08",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-09-09",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-09-10",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-09-11",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "360",
    sumOpenNumber: "9",
    resultDate: "2022-09-12",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-09-13",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-09-14",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-09-15",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "568",
    sumOpenNumber: "9",
    resultDate: "2022-09-16",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "689",
    sumOpenNumber: "3",
    resultDate: "2022-09-17",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-09-18",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-09-19",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "146",
    sumOpenNumber: "1",
    resultDate: "2022-09-20",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-09-21",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2022-09-22",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-09-23",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2022-09-24",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "455",
    sumOpenNumber: "4",
    resultDate: "2022-09-25",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-09-26",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2022-09-27",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2022-09-28",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "236",
    sumOpenNumber: "1",
    resultDate: "2022-09-29",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "147",
    sumOpenNumber: "2",
    resultDate: "2022-09-30",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-10-01",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "880",
    sumOpenNumber: "6",
    resultDate: "2022-10-02",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "890",
    sumOpenNumber: "7",
    resultDate: "2022-10-03",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-10-04",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2022-10-05",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-10-06",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-10-07",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2022-10-08",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2022-10-09",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-10-10",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2022-10-11",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "170",
    sumOpenNumber: "8",
    resultDate: "2022-10-12",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "336",
    sumOpenNumber: "2",
    resultDate: "2022-10-13",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-10-14",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-10-15",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "225",
    sumOpenNumber: "9",
    resultDate: "2022-10-16",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-10-17",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-10-18",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-10-19",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2022-10-20",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "227",
    sumOpenNumber: "1",
    resultDate: "2022-10-21",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "567",
    sumOpenNumber: "8",
    resultDate: "2022-10-22",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2022-10-23",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2022-10-24",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-10-25",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "178",
    sumOpenNumber: "6",
    resultDate: "2022-10-26",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "560",
    sumOpenNumber: "1",
    resultDate: "2022-10-27",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "457",
    sumOpenNumber: "6",
    resultDate: "2022-10-28",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-10-29",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2022-10-30",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-10-31",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "0",
    sumOpenNumber: "0",
    resultDate: "2022-11-01",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "568",
    sumOpenNumber: "9",
    resultDate: "2022-11-02",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "469",
    sumOpenNumber: "9",
    resultDate: "2022-11-03",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2022-11-04",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "445",
    sumOpenNumber: "3",
    resultDate: "2022-11-05",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "334",
    sumOpenNumber: "0",
    resultDate: "2022-11-06",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-11-07",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "449",
    sumOpenNumber: "7",
    resultDate: "2022-11-08",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2022-11-09",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2022-11-10",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2022-11-11",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "369",
    sumOpenNumber: "8",
    resultDate: "2022-11-12",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "479",
    sumOpenNumber: "0",
    resultDate: "2022-11-13",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "156",
    sumOpenNumber: "2",
    resultDate: "2022-11-14",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2022-11-15",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "367",
    sumOpenNumber: "6",
    resultDate: "2022-11-16",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "469",
    sumOpenNumber: "9",
    resultDate: "2022-11-17",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2022-11-18",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "357",
    sumOpenNumber: "5",
    resultDate: "2022-11-19",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "799",
    sumOpenNumber: "5",
    resultDate: "2022-11-20",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-11-21",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-11-22",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2022-11-23",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "290",
    sumOpenNumber: "1",
    resultDate: "2022-11-24",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "368",
    sumOpenNumber: "7",
    resultDate: "2022-11-25",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2022-11-26",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "569",
    sumOpenNumber: "0",
    resultDate: "2022-11-27",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "678",
    sumOpenNumber: "1",
    resultDate: "2022-11-28",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "990",
    sumOpenNumber: "8",
    resultDate: "2022-11-29",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "566",
    sumOpenNumber: "7",
    resultDate: "2022-11-30",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "679",
    sumOpenNumber: "2",
    resultDate: "2022-12-01",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2022-12-02",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "118",
    sumOpenNumber: "0",
    resultDate: "2022-12-03",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2022-12-04",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2022-12-05",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "155",
    sumOpenNumber: "1",
    resultDate: "2022-12-06",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2022-12-07",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "125",
    sumOpenNumber: "8",
    resultDate: "2022-12-08",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "488",
    sumOpenNumber: "0",
    resultDate: "2022-12-09",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2022-12-10",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2022-12-11",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "890",
    sumOpenNumber: "7",
    resultDate: "2022-12-12",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2022-12-13",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "288",
    sumOpenNumber: "8",
    resultDate: "2022-12-14",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "890",
    sumOpenNumber: "7",
    resultDate: "2022-12-15",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "466",
    sumOpenNumber: "6",
    resultDate: "2022-12-16",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2022-12-17",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "299",
    sumOpenNumber: "0",
    resultDate: "2022-12-18",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "578",
    sumOpenNumber: "0",
    resultDate: "2022-12-19",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "224",
    sumOpenNumber: "8",
    resultDate: "2022-12-20",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "468",
    sumOpenNumber: "8",
    resultDate: "2022-12-21",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "780",
    sumOpenNumber: "5",
    resultDate: "2022-12-22",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "337",
    sumOpenNumber: "3",
    resultDate: "2022-12-23",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2022-12-24",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2022-12-25",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2022-12-26",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "336",
    sumOpenNumber: "2",
    resultDate: "2022-12-27",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "256",
    sumOpenNumber: "3",
    resultDate: "2022-12-28",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2022-12-29",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2022-12-30",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "459",
    sumOpenNumber: "8",
    resultDate: "2022-12-31",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "470",
    sumOpenNumber: "1",
    resultDate: "2023-01-01",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "799",
    sumOpenNumber: "5",
    resultDate: "2023-01-02",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2023-01-03",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "288",
    sumOpenNumber: "8",
    resultDate: "2023-01-04",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "230",
    sumOpenNumber: "5",
    resultDate: "2023-01-05",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "449",
    sumOpenNumber: "7",
    resultDate: "2023-01-06",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-01-07",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2023-01-08",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "799",
    sumOpenNumber: "5",
    resultDate: "2023-01-09",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-01-10",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-01-11",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "138",
    sumOpenNumber: "2",
    resultDate: "2023-01-12",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-01-13",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "266",
    sumOpenNumber: "4",
    resultDate: "2023-01-14",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "670",
    sumOpenNumber: "3",
    resultDate: "2023-01-15",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "116",
    sumOpenNumber: "8",
    resultDate: "2023-01-16",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2023-01-17",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "228",
    sumOpenNumber: "2",
    resultDate: "2023-01-18",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "225",
    sumOpenNumber: "9",
    resultDate: "2023-01-19",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "380",
    sumOpenNumber: "1",
    resultDate: "2023-01-20",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "559",
    sumOpenNumber: "9",
    resultDate: "2023-01-21",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "124",
    sumOpenNumber: "7",
    resultDate: "2023-01-22",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2023-01-23",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "288",
    sumOpenNumber: "8",
    resultDate: "2023-01-24",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "477",
    sumOpenNumber: "8",
    resultDate: "2023-01-25",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "777",
    sumOpenNumber: "1",
    resultDate: "2023-01-26",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "499",
    sumOpenNumber: "2",
    resultDate: "2023-01-27",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "577",
    sumOpenNumber: "9",
    resultDate: "2023-01-28",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "157",
    sumOpenNumber: "3",
    resultDate: "2023-01-29",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2023-01-30",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "350",
    sumOpenNumber: "8",
    resultDate: "2023-01-31",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "600",
    sumOpenNumber: "6",
    resultDate: "2023-02-01",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2023-02-02",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2023-02-03",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-02-04",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2023-02-05",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "788",
    sumOpenNumber: "3",
    resultDate: "2023-02-06",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-02-07",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "226",
    sumOpenNumber: "0",
    resultDate: "2023-02-08",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "266",
    sumOpenNumber: "4",
    resultDate: "2023-02-09",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "111",
    sumOpenNumber: "3",
    resultDate: "2023-02-10",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-02-11",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "555",
    sumOpenNumber: "5",
    resultDate: "2023-02-12",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "333",
    sumOpenNumber: "9",
    resultDate: "2023-02-13",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-02-14",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "139",
    sumOpenNumber: "3",
    resultDate: "2023-02-15",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2023-02-16",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "667",
    sumOpenNumber: "9",
    resultDate: "2023-02-17",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "223",
    sumOpenNumber: "7",
    resultDate: "2023-02-18",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "137",
    sumOpenNumber: "1",
    resultDate: "2023-02-19",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "258",
    sumOpenNumber: "5",
    resultDate: "2023-02-20",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "590",
    sumOpenNumber: "4",
    resultDate: "2023-02-21",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "337",
    sumOpenNumber: "3",
    resultDate: "2023-02-22",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "222",
    sumOpenNumber: "6",
    resultDate: "2023-02-23",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2023-02-24",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-02-25",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "227",
    sumOpenNumber: "1",
    resultDate: "2023-02-26",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-02-27",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "280",
    sumOpenNumber: "0",
    resultDate: "2023-02-28",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2023-03-01",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "447",
    sumOpenNumber: "5",
    resultDate: "2023-03-02",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "190",
    sumOpenNumber: "0",
    resultDate: "2023-03-03",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "444",
    sumOpenNumber: "2",
    resultDate: "2023-03-04",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "344",
    sumOpenNumber: "1",
    resultDate: "2023-03-05",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "227",
    sumOpenNumber: "1",
    resultDate: "2023-03-06",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2023-03-07",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "470",
    sumOpenNumber: "1",
    resultDate: "2023-03-08",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "577",
    sumOpenNumber: "9",
    resultDate: "2023-03-09",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-03-10",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "557",
    sumOpenNumber: "7",
    resultDate: "2023-03-11",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "488",
    sumOpenNumber: "0",
    resultDate: "2023-03-12",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "388",
    sumOpenNumber: "9",
    resultDate: "2023-03-16",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "338",
    sumOpenNumber: "4",
    resultDate: "2023-03-17",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-03-18",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "250",
    sumOpenNumber: "7",
    resultDate: "2023-03-19",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "567",
    sumOpenNumber: "8",
    resultDate: "2023-03-20",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-03-21",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "668",
    sumOpenNumber: "0",
    resultDate: "2023-03-22",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "100",
    sumOpenNumber: "1",
    resultDate: "2023-03-23",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-03-24",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "225",
    sumOpenNumber: "9",
    resultDate: "2023-03-25",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "447",
    sumOpenNumber: "5",
    resultDate: "2023-03-26",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-03-27",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2023-03-28",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "366",
    sumOpenNumber: "5",
    resultDate: "2023-03-29",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "444",
    sumOpenNumber: "2",
    resultDate: "2023-03-30",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-03-31",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "449",
    sumOpenNumber: "7",
    resultDate: "2023-04-01",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2023-04-02",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-04-03",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-04-04",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "690",
    sumOpenNumber: "5",
    resultDate: "2023-04-05",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2023-04-06",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "244",
    sumOpenNumber: "0",
    resultDate: "2023-04-07",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2023-04-08",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2023-04-09",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2023-04-10",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-04-11",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-04-12",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "116",
    sumOpenNumber: "8",
    resultDate: "2023-04-13",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2023-04-14",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "110",
    sumOpenNumber: "2",
    resultDate: "2023-04-15",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-04-16",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "229",
    sumOpenNumber: "3",
    resultDate: "2023-04-17",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2023-04-18",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-04-19",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2023-04-20",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2023-04-21",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-04-22",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "180",
    sumOpenNumber: "9",
    resultDate: "2023-04-23",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "123",
    sumOpenNumber: "6",
    resultDate: "2023-04-24",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-04-25",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "200",
    sumOpenNumber: "2",
    resultDate: "2023-04-26",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "300",
    sumOpenNumber: "3",
    resultDate: "2023-04-27",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "344",
    sumOpenNumber: "1",
    resultDate: "2023-04-28",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "666",
    sumOpenNumber: "8",
    resultDate: "2023-04-29",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-04-30",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "399",
    sumOpenNumber: "1",
    resultDate: "2023-05-01",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-05-02",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "222",
    sumOpenNumber: "6",
    resultDate: "2023-05-03",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-05-04",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "456",
    sumOpenNumber: "5",
    resultDate: "2023-05-05",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "599",
    sumOpenNumber: "3",
    resultDate: "2023-05-06",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2023-05-07",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2023-05-08",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-05-09",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2023-05-10",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "444",
    sumOpenNumber: "2",
    resultDate: "2023-05-11",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "344",
    sumOpenNumber: "1",
    resultDate: "2023-05-12",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "566",
    sumOpenNumber: "7",
    resultDate: "2023-05-13",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "500",
    sumOpenNumber: "5",
    resultDate: "2023-05-14",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "345",
    sumOpenNumber: "2",
    resultDate: "2023-05-15",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "669",
    sumOpenNumber: "1",
    resultDate: "2023-05-16",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "666",
    sumOpenNumber: "8",
    resultDate: "2023-05-17",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-05-18",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "490",
    sumOpenNumber: "3",
    resultDate: "2023-05-19",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-05-20",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "257",
    sumOpenNumber: "4",
    resultDate: "2023-05-21",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "278",
    sumOpenNumber: "7",
    resultDate: "2023-05-22",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "567",
    sumOpenNumber: "8",
    resultDate: "2023-05-23",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-05-24",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-05-25",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-05-26",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "177",
    sumOpenNumber: "5",
    resultDate: "2023-05-27",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-05-28",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "129",
    sumOpenNumber: "2",
    resultDate: "2023-05-29",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-05-30",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "370",
    sumOpenNumber: "0",
    resultDate: "2023-05-31",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "490",
    sumOpenNumber: "3",
    resultDate: "2023-06-01",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-06-02",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "246",
    sumOpenNumber: "2",
    resultDate: "2023-06-03",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "400",
    sumOpenNumber: "4",
    resultDate: "2023-06-04",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "338",
    sumOpenNumber: "4",
    resultDate: "2023-06-05",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2023-06-06",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "330",
    sumOpenNumber: "6",
    resultDate: "2023-06-07",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "227",
    sumOpenNumber: "1",
    resultDate: "2023-06-08",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "233",
    sumOpenNumber: "8",
    resultDate: "2023-06-09",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2023-06-10",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "122",
    sumOpenNumber: "5",
    resultDate: "2023-06-11",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "460",
    sumOpenNumber: "0",
    resultDate: "2023-06-12",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "347",
    sumOpenNumber: "4",
    resultDate: "2023-06-13",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "577",
    sumOpenNumber: "9",
    resultDate: "2023-06-14",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-06-15",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2023-06-16",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "399",
    sumOpenNumber: "1",
    resultDate: "2023-06-17",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "299",
    sumOpenNumber: "0",
    resultDate: "2023-06-18",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "900",
    sumOpenNumber: "9",
    resultDate: "2023-06-19",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "700",
    sumOpenNumber: "7",
    resultDate: "2023-06-20",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "229",
    sumOpenNumber: "3",
    resultDate: "2023-06-21",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "990",
    sumOpenNumber: "8",
    resultDate: "2023-06-22",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "690",
    sumOpenNumber: "5",
    resultDate: "2023-06-23",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "688",
    sumOpenNumber: "2",
    resultDate: "2023-06-24",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "277",
    sumOpenNumber: "6",
    resultDate: "2023-06-25",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "667",
    sumOpenNumber: "9",
    resultDate: "2023-06-26",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "366",
    sumOpenNumber: "5",
    resultDate: "2023-06-27",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "577",
    sumOpenNumber: "9",
    resultDate: "2023-06-28",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "220",
    sumOpenNumber: "4",
    resultDate: "2023-06-29",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "449",
    sumOpenNumber: "7",
    resultDate: "2023-06-30",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "668",
    sumOpenNumber: "0",
    resultDate: "2023-07-01",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "335",
    sumOpenNumber: "1",
    resultDate: "2023-07-02",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-07-03",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "223",
    sumOpenNumber: "7",
    resultDate: "2023-07-04",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "457",
    sumOpenNumber: "6",
    resultDate: "2023-07-05",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "478",
    sumOpenNumber: "9",
    resultDate: "2023-07-06",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "445",
    sumOpenNumber: "3",
    resultDate: "2023-07-07",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "339",
    sumOpenNumber: "5",
    resultDate: "2023-07-08",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "147",
    sumOpenNumber: "2",
    resultDate: "2023-07-09",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "789",
    sumOpenNumber: "4",
    resultDate: "2023-07-10",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "244",
    sumOpenNumber: "0",
    resultDate: "2023-07-11",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "111",
    sumOpenNumber: "3",
    resultDate: "2023-07-12",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "660",
    sumOpenNumber: "2",
    resultDate: "2023-07-13",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2023-07-14",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "133",
    sumOpenNumber: "7",
    resultDate: "2023-07-15",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "344",
    sumOpenNumber: "1",
    resultDate: "2023-07-16",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "377",
    sumOpenNumber: "7",
    resultDate: "2023-07-17",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "144",
    sumOpenNumber: "9",
    resultDate: "2023-07-18",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "340",
    sumOpenNumber: "7",
    resultDate: "2023-07-19",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-07-20",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "777",
    sumOpenNumber: "1",
    resultDate: "2023-07-21",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "800",
    sumOpenNumber: "8",
    resultDate: "2023-07-22",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "450",
    sumOpenNumber: "9",
    resultDate: "2023-07-23",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "550",
    sumOpenNumber: "0",
    resultDate: "2023-07-24",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "159",
    sumOpenNumber: "5",
    resultDate: "2023-07-25",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "799",
    sumOpenNumber: "5",
    resultDate: "2023-07-26",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "237",
    sumOpenNumber: "2",
    resultDate: "2023-07-27",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "446",
    sumOpenNumber: "4",
    resultDate: "2023-07-28",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "166",
    sumOpenNumber: "3",
    resultDate: "2023-07-29",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "240",
    sumOpenNumber: "6",
    resultDate: "2023-07-30",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "449",
    sumOpenNumber: "7",
    resultDate: "2023-07-31",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "440",
    sumOpenNumber: "8",
    resultDate: "2023-08-01",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "579",
    sumOpenNumber: "1",
    resultDate: "2023-08-02",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "469",
    sumOpenNumber: "9",
    resultDate: "2023-08-03",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "239",
    sumOpenNumber: "4",
    resultDate: "2023-08-04",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "145",
    sumOpenNumber: "0",
    resultDate: "2023-08-05",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "899",
    sumOpenNumber: "5",
    resultDate: "2023-08-06",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "147",
    sumOpenNumber: "2",
    resultDate: "2023-08-07",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "580",
    sumOpenNumber: "3",
    resultDate: "2023-08-08",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "556",
    sumOpenNumber: "6",
    resultDate: "2023-08-09",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "115",
    sumOpenNumber: "7",
    resultDate: "2023-08-10",
  },
  {
    MarketTime: "05:00:00 PM",
    openNumber: "260",
    sumOpenNumber: "8",
    resultDate: "2023-08-11",
  },
];
