import React from 'react'
import './Footer.css'
import logo from '../../Images/Spl-logo.png'
import shade from '../../Images/btn-shade.png'
import { downloadApk } from '../../App'

const Footer = () => {
    return (

        <div className='footer'>

            <div className='container'>

                <div className='footer-content'>

                    <div className='footer-logo'>
                        <div className='company-logo'>

                            <div className='logo-img'><img src={logo} /></div>

                        </div>
                        <div className='logo-shadow'></div>
                    </div>

                    <div className='company-disclaimer'>

                        <div className='disclaimer-content'>

                            <h6>Viewing This WebSite Is On Your Own Risk. All The information Shown On Website Is Based on Numerology and Astrology for Information Purposes.
                                We Are Not Associated with Any Illegal Matka Business or Gamblers.We Warn You That Matka Gambling in Your Country May be Banned or Illegal.
                                We Are Not Responsible For Any Issues or Scam. We Respect All Country Rules/Laws. If You Not Agree With Our Site Disclaimer.
                                Please Quit Our Site Right Now. Copying/Promoting/Publishing Any of Our Content in Any Type Of Media or Other Source is Illegal and against Law.
                            </h6>

                        </div>

                        <div className='download-btn'>

                            <div className='download-content' onClick={() => downloadApk()}>
                                <div className='shade'><img src={shade} /></div>
                                <h5>download app</h5>
                            </div>

                        </div>

                        <div className='logo-shadow'></div>

                    </div>

                </div>

                <hr />

                <div className='copyright'><h6>© Copyright 2021 spl.live all right reserved.</h6></div>

            </div>

        </div>
    )
}

export default Footer
