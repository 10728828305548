import React from 'react'
import './Header.css'
import anna from '../../Images/anna-vector.png'
// import anna from '../../Images/boy.png'
import shade from '../../Images/btn-shade.png'
import { downloadApk } from '../../App'

const Header = () => {

    return (
        <div className='header'>

            <div className='container'>

                <div className='header-content'>

                    <div className='header-text-content'>

                        <div className='sub-heading-1'><h4>Welcome to your</h4></div>
                        <div className='main-heading'><h1>Most Trusted App</h1></div>
                        <div className='sub-heading-1 mt-3'><h4>Play Instant Online Matka with SPL Live & Win Money Daily!</h4></div>

                        <div className='download-btn'>

                            <div className='download-content' onClick={() => downloadApk()}>
                                <div className='shade'><img src={shade} /></div>
                                <h5>download app</h5>
                            </div>

                        </div>

                    </div>

                    <div className='anna-vector'>

                        <img src={anna} />

                    </div>

                </div>

            </div>

        </div>
    )
}

export default Header
