import React from 'react'
import './FloatingBtn.css'
import whatsapp from '../../Images/whatsapp-icon.png'
import telegram from '../../Images/telegram-icon.png'

const FloatingBtn = () => {
  return (
    <div className='floating-btn'>

      <div className='floting-btn-content'>

        <div className='social-icons'>

          <div className='whatsapp' onClick={() => window.open(`https://wa.me/${917769826748}`)}><img src={whatsapp} /></div>
          <div className='telegram' onClick={() => window.open(`https://t.me/satta_matka_kalyan_bazar_milan`)}><img src={telegram} /></div>

        </div>

      </div>

    </div>
  )
}

export default FloatingBtn
