import React, { useEffect, useState } from 'react'
import './MarketJodiChart.css'
import { useLocation, useParams } from 'react-router-dom'
import axios from 'axios'
import { format } from 'date-fns'

const MarketJodiChart = () => {

    const location = useLocation()
    const { id } = useParams()
    const [jodichartApiData, setJodichartApiData] = useState({});

    const getChart = async () => {
        await axios.get(`${process.env.REACT_APP_BASE_URL}/web/chart/${id}`)
            .then((response) => {
                setJodichartApiData(response?.data?.data)
            })
            .catch((err) => console.log(err.message))
    }
    useEffect(() => {
        getChart()
    }, [])

    const numberToDigit = (number) => {

        let digit = [];

        while (number > 0) {
            digit.push(number % 10);
            number = Math.floor(number / 10)
        }

        return digit.reverse();


    }

    const DateToLocalDateString = (date) => format(new Date(date), 'dd-MM-yyyy');

    function sumGetLastDigit(value) {

        if (value == null) {
            return null;
        } else {
            return (
                value
                    .toString()
                    .split('')
                    .map(Number)
                    .reduce(function (a, b) {
                        return a + b;
                    }, 0) % 10
            );
        }
    }

    const columns = React.useMemo(
        () => [
            {
                name: 'Monday',
                data: 'Monday',
            },
            {
                name: 'Tuesday',
                data: 'Tuesday',
            },
            {
                name: 'Wednesday',
                data: 'Wednesday',
            },
            {
                name: 'Thursday',
                data: 'Thursday',
            },
            {
                name: 'Friday',
                data: 'Friday',
            },
            {
                name: 'Saturday',
                data: 'Saturday',
            },
            {
                name: 'Sunday',
                data: 'Sunday',
            }
        ],
        [],
    );

    return (
        <div className={`market-jodi-chart ${location.pathname === `/market-results/jodi-chart/${id}` ? 'addmargin' : ''}`}>

            <div className='container'>

                <div className='market-chart-content'>

                    <div className='market-chart-main-title'>

                        <h2> market <span>jodi</span> chart </h2>
                        <div className='underline'><hr /></div>

                    </div>

                    <div className='market-chart-title'><h3>{jodichartApiData.marketName}</h3></div>

                    <div className='market-chart-table'>

                        <table className='table align-middle text-center'>

                            <thead className='table-head'>

                                <tr>
                                    <th className='table-head-date'><h5>Date</h5></th>

                                    {columns.map((data) => (
                                        <th className='table-head-time' key={data.data}><h6>{data.name}</h6></th>
                                    ))
                                    }
                                </tr>

                            </thead>

                            <tbody className='table-body'>

                                {

                                    jodichartApiData?.marketData?.map((data, index) =>

                                        <tr key={index}>

                                            <th className='table-date' key={data.id}>

                                                <div className='table-body-date'>
                                                    <div className='table-from-date'><h6>{DateToLocalDateString(data.dateFrom)}</h6></div>
                                                    <div className='table-date-day'><h6>to</h6></div>
                                                    <div className='table-to-date'><h6>{DateToLocalDateString(data.dateTo)}</h6></div>
                                                </div>

                                            </th>

                                            {
                                                columns.map((column, index) => {


                                                    const aaa = data.days.find((e) => e.day === column.data)

                                                    return <td key={index}>

                                                        <div className='table-result'>

                                                            <div className='main-result main-result-data'>
                                                                <h6>{aaa?.open ? sumGetLastDigit(aaa?.open) : <div className={`star ${aaa?.open ? '' : ''}`}>*</div>}{aaa?.close ? sumGetLastDigit(aaa?.close) : <div className={`star ${aaa?.open ? 'ms-1' : ''}`}>*</div>}</h6>
                                                            </div>

                                                        </div>

                                                    </td>

                                                })}

                                        </tr>

                                    )

                                }

                            </tbody>

                        </table>

                    </div>

                </div>

            </div>

        </div>
    )
}

export default MarketJodiChart
