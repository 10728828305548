import React from 'react'
import './Contactus.css'
import whatsapp from '../../Images/whatsapp-icon.png'
import telegram from '../../Images/telegram-icon.png'

const Contactus = () => {
    return (
        <div className='contact-us'>

            <div className='container'>

                <div className='contact-us-contnet'>

                    <div className='contact-us-heading'>
                        <h4>contact us</h4>
                    </div>

                    <div className='contact-us-number'>
                        <h4>+91 77698 26748</h4>
                    </div>

                    <div className='social-media-panel'>

                        <div className='whatsapp-icon' onClick={() => window.open(`https://wa.me/${917769826748}`)}>
                            <div className='icon-img' ><img src={whatsapp} /></div>
                            <div className='icon-text'><h5>Whatsapp</h5></div>
                        </div>

                        <div className='telegram-icon' onClick={() => window.open(`https://t.me/satta_matka_kalyan_bazar_milan`)}>
                            <div className='icon-img'><img src={telegram} /></div>
                            <div className='icon-text'><h5>Telegram</h5></div>
                        </div>

                    </div>

                </div>

            </div>

        </div>
    )
}

export default Contactus
