import React, { lazy, useEffect } from 'react';
import './App.css';
import { Routes, Route, useLocation, useParams } from 'react-router-dom';
import Navbar from './Components/01-Navbar/Navbar';
import Home from './Components/Home/Home';
import RateChart from './Components/04-RateChart/RateChart';
import MarketResult from './Components/06-Market-result/MarketResult';
// import StarlineChart from './Components/10-Starline-chart/StarlineChart';

import Footer from './Components/11-footer/Footer';
import MarketJodiChart from './Components/08-Market-jodi-chart/MarketJodiChart';
import MarketPanelChart from './Components/09-Market-panel-chart/MarketPanelChart';
import FloatingBtn from './Components/12-Floating-button/FloatingBtn';
import StarlineChart from './Components/10-Starline-chart/StarlineChart';
import anna from './Apk/splv2_0_1.apk'


export const downloadApk = () => {

  const anchor = document.createElement('a');
  anchor.href = anna;
  anchor.download = "spl.apk";

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);

}

function App() {

  const { pathname } = useLocation();


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });


  }, [pathname]);


  return (
    <div className="App">

      <Navbar />

      <Routes>

        <Route path="/" element={<Home />} />
        <Route path="/rate-chart" element={<RateChart />} />
        <Route path="/market-results" element={<MarketResult />} />
        <Route path="/starline-chart" element={<StarlineChart />} />
        <Route path="/market-results/jodi-chart/:id" element={<MarketJodiChart />} />
        <Route path="/market-results/panel-chart/:id" element={<MarketPanelChart />} />

      </Routes>

      {
        pathname === '/rate-chart' || pathname.includes('/market-results') || pathname === '/starline-chart' ? <FloatingBtn /> : ''
      }

      <Footer />

    </div>
  );
}

export default App;
