import React, { useEffect, useState } from 'react'
import './StarlineChart.css'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { format, parse } from 'date-fns'
import { splData } from './SPLData';
import { ThreeDots } from 'react-loader-spinner'

const StarlineChart = () => {

  const location = useLocation()

  const [starlineChartApiData, setStarlineChartApiData] = useState([]);

  const getChart = async () => {
    await axios.get(`${process.env.REACT_APP_BASE_URL}/web/starlineChart`)
      .then((response) => {
        setStarlineChartApiData(response.data.data)
      })
      .catch((err) => console.log(err.message))
  }

  useEffect(() => {
    getChart()
  }, [])

  const DateToLocalDateString = (date) => format(new Date(date), 'dd-MM-yyyy');
  const DateToLocalDayString = (date) => format(new Date(date), 'eeee');
  const DateToLocalTime = (time) => {
    const inputTime = parse(time, 'hh:mm:ss a', new Date());
    const formattedTime = format(inputTime, "hh:mm a").toLowerCase();
    return formattedTime
  };

  console.log(DateToLocalTime('01:00:00 PM'));

  function sumGetLastDigit(value) {

    if (value == null) {
      return null;
    } else {
      return (
        value
          .toString()
          .split('')
          .map(Number)
          .reduce(function (a, b) {
            return a + b;
          }, 0) % 10
      );
    }
  }

  const [isLoading, setIsLoading] = useState(true);
  const [allDatas, setAllDatas] = useState()

  console.log(allDatas);

  useEffect(() => {

    setTimeout(() => {

      let allDate = [];
      let allData = [];

      allDate = splData.map((data) => data.resultDate)


      allDate = new Set(allDate)

      const allDateArray = Array.from(allDate)
      const allDateSortArray = allDateArray.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
      allDateSortArray.map((date) => {
        let objData = {
          "Date": date,
          "Time": []
        };
        objData["Time"] = splData.filter((data) => data.resultDate === date)
        allData.push(objData)
        setAllDatas(allData)
      }
      )
      setIsLoading(false);

    }, 2000);


  }, [])

  return (
    <div className={`starline-chart ${location.pathname === '/starline-chart' ? 'addmargin' : ''}`}>

      <div className=''>

        <div className='starline-chart-content'>

          <div className='starline-chart-content-title'><h3>STARLINE CHART</h3></div>

          <div className='starline-chart-table'>

            {isLoading ? (
              <div className='loader'>
                <ThreeDots
                  height="80"
                  width="80"
                  radius="9"
                  color="#cc00b3"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            ) : (

              <table className="table align-middle text-center">

                <thead className='table-head'>

                  <tr>

                    <th className='table-head-date'><h5>Date</h5></th>

                    {
                      starlineChartApiData?.Markets?.map((data, index) => {
                        return (
                          <th className='table-head-time' key={index}><h6 className=''>{data?.MarketName.toLowerCase()}</h6></th>
                        )

                      })
                    }

                  </tr>

                </thead>

                <tbody className='table-body'>

                  {
                    allDatas?.map((data, index) =>

                      <tr key={index}>

                        <th className='table-date'>

                          <div className='table-body-date'>
                            <div className='table-date-year'><h6>{DateToLocalDateString(data?.Date)}</h6></div>
                            <div className='table-date-day'><h6>{DateToLocalDayString(data?.Date)}</h6></div>
                          </div>

                        </th>

                        {
                          starlineChartApiData?.Markets?.map((maket, i) => {
                            const value = data.Time.find(time => DateToLocalTime(time?.MarketTime) === maket?.MarketName.toLowerCase())
                            const resultData = value ? value.openNumber : ''
                            return (

                              <td key={i}>

                                <div className='table-result'>
                                  <div className='starline-result-ank'><h6>{resultData ? resultData : <div className='star'>***</div>}</h6></div>
                                  <div className='starline-main-result'><h6>{resultData ? sumGetLastDigit(resultData) : <div className='star'>*</div>}</h6></div>
                                </div>

                              </td>
                            )
                          }

                          )
                        }




                      </tr>

                    )
                  }
                  {
                    starlineChartApiData?.data?.map((data, index) =>

                      <tr key={index}>

                        <th className='table-date'>

                          <div className='table-body-date'>
                            <div className='table-date-year'><h6>{DateToLocalDateString(data.Date)}</h6></div>
                            <div className='table-date-day'><h6>{DateToLocalDayString(data.Date)}</h6></div>
                          </div>

                        </th>

                        {
                          starlineChartApiData?.Markets?.map((maket, i) => {
                            const value = data.Time.find(time => time.MarketName === maket.MarketName)
                            const resultData = value ? value.Result : ''
                            return (

                              <td key={i}>

                                <div className='table-result'>
                                  <div className='starline-result-ank'><h6>{resultData ? resultData : <div className='star'>***</div>}</h6></div>
                                  <div className='starline-main-result'><h6>{resultData ? sumGetLastDigit(resultData) : <div className='star'>*</div>}</h6></div>
                                </div>

                              </td>
                            )
                          }

                          )
                        }




                      </tr>

                    )
                  }
                </tbody>

              </table>

            )}

          </div>

        </div>

      </div>

    </div>
  )
}

export default StarlineChart
