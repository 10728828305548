import React, { useEffect, useState } from 'react'
import './StarlineResult.css'
import moment from 'moment/moment'
import shade from '../../Images/btn-shade1.png'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'

const StarlineResult = () => {

  const currentDate = () => moment().format('DD MMMM YYYY')

  const navigate = useNavigate()


  const [starlineApiData, setStarlineApiData] = useState([]);

  const getStarline = async () => {
    await axios.get(`${process.env.REACT_APP_BASE_URL}/web/getStarlineMarkets`)
      .then((r) => {
        setStarlineApiData(r.data.data)
      })
      .catch((err) => console.log(err.message));
  }

  useEffect(() => {

    getStarline()
  }, []);

  function sumGetLastDigit(value) {

    if (value == null) {
      return null;
    } else {
      return (
        value
          .toString()
          .split('')
          .map(Number)
          .reduce(function (a, b) {
            return a + b;
          }, 0) % 10
      );
    }
  }


  return (
    <div className='starline-result'>

      <div className='container'>

        <div className='starline-result-content'>

          <div className='starline-result-heading'>

            <h2>online <span>starline</span> result </h2>
            <div className='underline'><hr /></div>

          </div>

          <div className='current-date'>

            <h4>{currentDate()}</h4>

          </div>

          <div className='starline-result-table'>

            <div className='starline-result-table-bg'>

              <div className='starline-result-table-title-row'>

                <div className='starline-result-table-title-time'><h4>Time</h4></div>
                <div className='starline-result-table-title-result'><h4>Result</h4></div>

              </div>

              <div className='starline-result-data-content mt-2'>

                {
                  starlineApiData.slice(0, 6).map((data) =>

                    <div className='starline-result-table-data-row' key={data.id}>

                      <div className='starline-result-tabale-time-data'><h5>{data.Time}</h5></div>
                      <div className='starline-result-tabale-result-data'>

                        <h5 className='star-data'>

                          {data.Result ? data.Result : <div className='star'>***</div>}
                          <span className='dash'><hr /></span>
                          {data?.Result ? sumGetLastDigit(data?.Result) : <div className='star'>*</div>}

                        </h5>

                      </div>

                    </div>

                  )
                }


                {/* <div className='starline-result-table-data-row'>

                  <div className='starline-result-tabale-time-data'><h5>11:00 AM</h5></div>
                  <div className='starline-result-tabale-result-data'>

                    <h5 className='star-data'>

                      <div className='star'>***</div>
                      <span className='dash'><hr /></span>
                      <div className='star'>*</div>


                    </h5>

                  </div>

                </div>

                <div className='starline-result-table-data-row'>

                  <div className='starline-result-tabale-time-data'><h5>12:00 PM</h5></div>
                  <div className='starline-result-tabale-result-data'>

                    <h5 className='star-data'>

                      <div className='star'>***</div>
                      <span className='dash'><hr /></span>
                      <div className='star'>*</div>


                    </h5>

                  </div>

                </div>

                <div className='starline-result-table-data-row'>

                  <div className='starline-result-tabale-time-data'><h5>01:00 PM</h5></div>
                  <div className='starline-result-tabale-result-data'>

                    <h5 className='star-data'>

                      <div className='star'>***</div>
                      <span className='dash'><hr /></span>
                      <div className='star'>*</div>


                    </h5>

                  </div>

                </div>

                <div className='starline-result-table-data-row'>


                  <div className='starline-result-tabale-time-data'><h5>02:00 PM</h5></div>
                  <div className='starline-result-tabale-result-data'>

                    <h5 className='star-data'>

                      <div className='star'>***</div>
                      <span className='dash'><hr /></span>
                      <div className='star'>*</div>


                    </h5>

                  </div>

                </div>

                <div className='starline-result-table-data-row'>


                  <div className='starline-result-tabale-time-data'><h5>03:00 PM</h5></div>
                  <div className='starline-result-tabale-result-data'>

                    <h5 className='star-data'>

                      <div className='star'>***</div>
                      <span className='dash'><hr /></span>
                      <div className='star'>*</div>


                    </h5>

                  </div>

                </div> */}

              </div>

              <div className='table-result-title-bg'>

                <div className='starline-result-table-title-bg'></div>
                <div className='starline-result-table-title-bg'></div>

              </div>
              <div className='table-vertical-line'><hr /></div>

            </div>

            <div className='starline-result-table-bg'>

              <div className='starline-result-table-title-row'>

                <div className='starline-result-table-title-time'><h4>Time</h4></div>
                <div className='starline-result-table-title-result'><h4>Result</h4></div>

              </div>

              <div className='starline-result-data-content mt-2'>

                {
                  starlineApiData.slice(6, 12).map((data) =>

                    <div className='starline-result-table-data-row' key={data.id}>

                      <div className='starline-result-tabale-time-data'><h5>{data.Time}</h5></div>
                      <div className='starline-result-tabale-result-data'>

                        <h5 className='star-data'>

                          {data.Result ? data.Result : <div className='star'>***</div>}
                          <span className='dash'><hr /></span>
                          {data?.Result ? sumGetLastDigit(data?.Result) : <div className='star'>*</div>}

                        </h5>

                      </div>

                    </div>

                  )
                }

              </div>

              <div className='table-result-title-bg'>

                <div className='starline-result-table-title-bg'></div>
                <div className='starline-result-table-title-bg'></div>

              </div>
              <div className='table-vertical-line'><hr /></div>

            </div>

          </div>

          <div className='chartzone-btn' onClick={() => navigate('/starline-chart')}>

            <div className='chartzone-content'>
              <div className='shade'><img src={shade} /></div>
              <h5>Chart Zone</h5>
            </div>

          </div>

        </div>

      </div>

    </div>
  )
}

export default StarlineResult
